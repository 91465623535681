@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot');
  src:  url('../fonts/icomoon.eot') format('embedded-opentype'),
    url('../fonts/icomoon.ttf') format('truetype'),
    url('../fonts/icomoon.woff') format('woff'),
    url('../fonts/icomoon.svg?b1o14u#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/landing_fonts/icomoon.eot');
  src:  url('../fonts/landing_fonts/icomoon.eot') format('embedded-opentype'),
    url('../fonts/landing_fonts/icomoon.ttf') format('truetype'),
    url('../fonts/landing_fonts/icomoon.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/landing_fonts/content_icons.eot');
  src:  url('../fonts/landing_fonts/content_icons.eot') format('embedded-opentype'),
    url('../fonts/landing_fonts/content_icons.ttf') format('truetype'),
    url('../fonts/landing_fonts/content_icons.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-educator:before {
  content: "\e900";
}
.icon-learners:before {
  content: "\e901";
}
.icon-professional:before {
  content: "\e902";
}

.icon-experience:before {
  content: "\e900";
 
}
.icon-home:before {
  content: "\e901";
 
}
.icon-prev:before {
  content: "\e910";
}
.icon-fullscreen:before {
  content: "\e90f";
}
.icon-play_speed:before {
  content: "\e911";
}
.icon-sound:before {
  content: "\e913";
}
.icon-tech_procedure:before {
  content: "\e235";
}
.icon-add_content:before {
  content: "\e236";
}
.icon-master_terminology:before {
  content: "\e237";
}
.icon-study_patient:before {
  content: "\e238";
}

