$primary-color : #0088EE;
$hover-color :#5089fa ;
$secondary-color : #7521B9;
$heading_color:#04080F;
$purple: #2a1d47;

p{
    color: #36393F;
}
a, a:hover{
    cursor: pointer;
}
a{
    color: $primary-color;
}
.top_sec {
    background: #1c1c1c;
    position: relative;
    z-index: 0;
    margin-top: 100px;

    // &::after{
    //     background: url(../images/landing/shape_25.svg) no-repeat;
    //     content: "";
    //     background-size: contain;
    //     position: absolute;
    //     height: 235px;
    //     top: 0;
    //     left: -94px;
    //     width: 20%;
    //     z-index: 0;
    //     opacity: 0.05;
    // }
}

a.btn_common{
    color: #fff;
}

.btn_common, .btn_outline{
    min-height: 50px;
}

.fixed_nav{
    position: fixed;
    box-shadow: 2px 3px 10px rgb(0 0 0 / 8%);
    top: 0;
    height: auto;

    // .main_nav{
    //     padding-top: 10px;
    //     padding-bottom: 10px;
    // }
}
.nav_wrap{
    transition: 0.2s all ease;
    height: 100px;
    position: fixed;
    width: 100%;
    margin: auto;
    background: #fff;
    z-index: 9999;
    top: 0;
}

.prof{
    padding-top: 50px;

   

    .short_acd {
        margin-top: 0px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    h3{
        font-size: 14px;
        letter-spacing: 2px;
        color: $primary-color;
        text-transform: uppercase;
        font-weight: 500;
    }
}




/*********** Website New Video Section ****************/
.st_bg .first_banner_img {
    //box-shadow: 2px 3px 10px rgba(0,0,0,0.08);
    border-radius: 15px;
    max-width: 540px;
    margin: auto;
}

.mob_item{
    .mob_img{
    margin: auto;
    background: #EDF3FA;
    transition: .3s all ease;

    video{
        background: #EDF3FA;
        clip-path: inset(1px 1px);
        outline: none !important;
    }
    &.c_sim img{
        height: 230px;
       width: 230px;
       object-fit: contain;
       margin: auto;
    }

    // img{
    //    height: 200px;
    //    width: 200px;
    //    object-fit: contain;
    //    margin: auto;
    // }
}

h4{
    font-size: 20px;
    margin-bottom: 40px;
    margin-top: 20px;
    font-weight: 600;
}
}
.scrolling-words-container {
    display: flex;
    align-items: center;
    font-size: 3rem;
    font-weight: 600; 
  }
  
  .scrolling-words-container {
    display: flex;
    align-items: center;
    font-size: 3rem;
    font-weight: 700; 
  }
  
  .scrolling-words-box {
    height: 3.5rem;
    margin-right: auto;
    overflow: hidden;
    
    ul {
      margin: 0 0;
      padding: 0;
      animation: scrollUp 8s infinite;
      li {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 3.5rem;
        list-style: none; 
        color: #2caaff;
      }
    }
  }
  
  // Variables
  $item-count: 6;
  
  @keyframes scrollUp {
    @for $i from 1 through ($item-count - 1) {
      #{($i * 16.66667) - 10%}, #{$i * 16.66667%} {
        transform: translateY((-100% / $item-count) * $i);
      }
    }
  }



.web_app_in {
    height: 650px;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 2px 3px 80px -40px rgb(0 0 0 / 34%);
}
section.web_app_block {
    margin-top: 100px;
    padding: 80px 0;
    // background: #EDF3FA;
}

// .fix_parallax{
//     position: fixed;
//     top: 15%;
//     right: 0;
//     left: 0;
//     bottom: 0;
//     margin: auto;
//     z-index: 0;
// }
.video_part{
    min-height: 500px;
    position: relative;
    padding: 70px 0 70px;
    background: $purple;
    margin-bottom: 155px;
    margin-top: 50px;

    &:before{
        content: '';
    position: absolute;
    width: 109%;
    height: 200px;
    background: $purple;
    transform: rotate(3deg);
    left: 0;
    right: 0;
    top: -72px;
    z-index: -1;
    display: none;
    }

    &:after{
        content: '';
    position: absolute;
    width: 109%;
    height: 200px;
    background: $purple;
    transform: rotate(3deg);
    left: 0;
    right: 0;
    bottom: -97px;
    z-index: -1;
    display: none;
    }

    .video_in_txt {
        height: 100%;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: center;

        h4{
            font-size: 2.2em;
            font-weight: 700;
            max-width:700px;
            color: #fff;

            span{
                color: $primary-color;
            }
        }

        h6{
            margin-top: 15px;
            font-size: 18px;
            color: #fff;
            font-weight: 500;
        }
    }

    .video_in {
        height: 447px;
        padding: 30px;

    iframe{
        border-radius: 20px;
        box-shadow: 1px 2px 60px 0px rgb(186 133 255 / 40%);
    }
    }

    .btn_getintouch{
        text-align: center;
        margin-top: 20px;

        button{
            margin: auto;
        }
    }
}

.select_uni{
    text-align: center;

    p{
        font-size: 18px;
        font-weight: 500;
        margin-top: 50px;

        button{
            padding: 0;
        }
    }
}
.awards_wrap{
    width: 100%;
}
.awards_top{
    text-align: left;
    padding: 50px 0 0;

    .p_img {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 30px;
    }

    img{
        max-height: 200px;
        max-width: 100%;
    }
}
/*********** Website New Video Section ****************/

.main_nav{
    margin: auto;
    width: 100%;
    padding-top: 10px;
    transition: .2s all ease;

    .navbar-toggler{
        border:none;
        padding: 0;
        outline: none !important;
        box-shadow: none !important;
    }

    .nav-item{
        margin: 0 20px;
        cursor: pointer;
    }

    .navbar-nav{

        

       .nav-link{
            color: #202020;
            font-size: 16px;
            font-weight: 500;
            padding-left: 0;
            padding-right: 0;
            cursor: pointer;
        }

        .free_trial .nav-link {
            background: $primary-color;
            padding-right: 15px;
            padding-left: 15px;
            display: inline-block;
            color: #fff;
            border-radius: 3px;
        }

        .nav-link.active{

            color: $primary-color;
            position: relative;


            &::after{
                content: '';
                border-bottom:2px solid $primary-color;
                width: 100%;
                position: absolute;
                left: 0;
                right: 0;
                margin: auto;
                bottom: 3px;
            }
        }

    } 

}

.video_sec{
    position: relative;
    min-height: 500px;

    
.thumbnail_modl{
    position: absolute;
    z-index: 0;
}

    .landing_model{
        position: absolute;
        height: 100%;
        width: 100%;

        .a-canvas{
           display: block;
        }
    }

    #AFrameScene{
        height: 100%;
    }
}
.small_head{
    font-weight: 600;
    font-size: 15px;
    color: $primary-color;
    letter-spacing: 2px;
    margin-bottom: 15px;
    text-transform: uppercase;
}
.getintouch{
    .modal-dialog{
        max-width: 700px;
        margin-top: 150px;
    }

  .btn_mod_close{
    position: absolute;
    z-index: 99;
    right: 25px;
    top: 25px;
}

.cntct_wrap {
    padding: 20px;
    
    form{
        margin-top: 20px;

        .form-group{

            margin-bottom: 15px;

            .form-control{
                border-radius: 3px;
                width: 100%;
            }

            label{
                font-size: 13px;
            }
            .formRequiredField{
                color: red;
            }
            .countrySelect{
                input{
                    border: none;
                }
            }
        }
        .message_sec{

            textarea{
                min-height: 140px;
            }
        }
        
    }

    h2{
        font-size: 28px;
        font-weight: 700;
        color: $primary-color;
    }
}


.btn_areas {
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    button{
        min-width: 120px;
        margin-left: 10px;
        margin-bottom: 10px;
        margin-top: 20px;
    }
}
}
.video_sec {
    height: 100%;
    video {
        clip-path: inset(1px 1px);
    }
}
.bounce2 {
    animation: bounce2 2s ease infinite;
  }
  @keyframes bounce2 {
      0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
      40% {transform: translateY(-20px);}
      60% {transform: translateY(-10px);}
  }
.hero_slider{
    position: relative;
    z-index: 9;
    height: calc(100vh - 100px);

    .bg_immersify_video {
        position: absolute;
        left: 0;
        right: 0;
        overflow: hidden;
        width: 100%;
        height: 100%;
        z-index: -1;

        video {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    
    .hero_txt_block {
        margin: auto;
        padding: 0 15px;
        height: 100%;

        &:after{
            content: '';
            position: absolute;
            height: 100%;
            width: 100%;
            background: linear-gradient(to right, rgb(0 16 34 / 80%), transparent);
            z-index: -1;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
        }

        .hero_txt, .l_main_heading {
            height: 100%;
        }

        .hero_txt{
            .down_pointer{
                position: absolute;
                bottom: 75px;
                left: 0;
                right: 0;
                margin: auto;
                width: 50px;
                height: 50px;
                border-radius: 50%;
                border: 1px solid #fff;
                color: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 1.4em;
                cursor: pointer;
            }
        }
        
    
    }



    .l_main_heading {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        justify-content: center;

        h1{
            font-size: 3rem;
            font-weight: 700;
            max-width: 800px;
            margin-bottom: 0;
            color: #fff;
        }
        h2{
            font-size: 1.4em;
            font-weight: 400;
            color: #fff;
            margin-top: 15px;
            margin-bottom: 20px;
        }
    }

    .button_grp{
        display: flex;
        justify-content: flex-start;
        margin-right: auto;
        margin-top: 30px;

        button{
            min-width: 150px;
            margin: 0 10px 0 0;
            white-space: nowrap;
        }
        a{
            white-space: nowrap;
        }
    }
}
.a-loader-title{
    display: none !important;
}


.tip_model {
    position: absolute;
    background: #fff;
    box-shadow: 2px 12px 40px rgb(0 0 0 / 16%);
    padding: 15px 20px;
    border-radius: 10px;
    z-index: 99;
    right: -35px;
    top: 35px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &::after{
        width: 0;
        height: 0;
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
        border-top: 20px solid #fff;
        content: "";
        position: absolute;
        bottom: -16px;
        left: 17px;
        transform: rotate(11deg);
    }

    .tip_img{
        background: url(../images/landing/mouse.gif) no-repeat center center;
        height: 70px;
        width: 70px;
        background-size: contain;
    }
    .tip_img2{
        background: url(../images/landing/mobile_int.gif) no-repeat center center;
        height: 70px;
        width: 70px;
        background-size: contain;
    }
    h3{
        font-size: 16px;
        font-weight: 700;
        color: $primary-color;
    }
    h5{
        font-size: 14px;
        font-weight: 500;
    }
}


.bottom_navs{

    background: $primary-color;
    height: 180px;
    position: relative;
    width: 100%;
    bottom: 0;

    .container, .row, .row > div, .btm_nav{
        height: 100%;
    }

    .ins_navtab{
        position: relative;
        text-align: center;
    }

    .btm_nav {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        position: relative;

        .tab_mover{
            height: 200px;
            width: 290px;
            position: absolute;
            left: 0;
            content: "";
            bottom: 0;
            background: #f8faff;
            box-shadow: 0px -13px 20px rgb(0 0 0 / 5%);
            transition: .2s all ease;

            &.left50{
                left: 310px;
            }

            &.left100{
                left: 620px;
            }
        }
        
    }
    .btm_nav_links {
        margin-right: 20px;
        height: 100%;
        padding: 0 30px;
        min-width: 290px;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        position: relative;
        cursor: pointer;


        

        i{
            font-weight: 500;
            color: #fff;
            font-size: 2rem;
        }
        h3{
            color: #fff;
            font-weight: 500;
            font-size: 20px;
            margin-top: 15px;
        }
        p{
            color: #cce2ff;
            font-weight: 400;
            margin-bottom: 0;
            font-size: 14px;
        }
    }
    .btm_nav_links.active{
        bottom: 14px;
        height: 115%;


        i{
            font-weight: 500;
            color: $primary-color;
            font-size: 2rem;
        }
        h3{
            color:  $primary-color;;
            font-weight: 600;
            font-size: 20px;
            margin-top: 15px;
        }
        p{
            color: #0d2442;
            font-weight: 400;
        }
    }
}

section.what_prof {
    max-width: 1190px;
    margin: auto;
    padding-bottom: 80px;

    .container{
        margin-top: 60px;
    }

     
    .col-md-4{
        margin-bottom: 20px;
    }

    .prof_blk {
        display: flex;
        align-items: center;
        height: 100%;
        justify-content: flex-start;
        flex-direction: column;
        padding: 30px 20px;
        background: #fff;
        border-radius: 15px;
        box-shadow: 2px 3px 10px rgb(0 0 0 / 8%);
       
        .prof_ico{
            img{
                height: 80px;
                object-fit: contain;
            }
        }
    
        h4{
            font-size: 20px;
            font-weight: 600;
            color: #202020;
            margin-top: 15px;
        }
    
        h5{
            font-size: 16px;
            font-weight: 400;
            text-align: center;
            color: #4d4d4d;
            margin-top: 10px;
        }
    
        .ext_link{
            font-size: 13px;
            text-align: center;
            margin-top: 20px;
            min-height: 40px;

    
            img{
                height: 20px;
                margin-right: 3px;
            }
        }
    }
}


.highlighted_c{
    color: $primary-color;
    font-weight: 600;
    font-size: 18px;
}
.mt-lg{
    padding-top: 100px;
}
.mt-70{
    margin-top: 70px;
}
.mb-80{
    margin-bottom: 80px;
}
.landing_main_head {
    font-size: 2.2rem;
    font-weight: 700;
    color: #1D2127;
    position: relative;
    display: inline;


    .end_not{
        width: 12px;
        height: 12px;
        border-radius: 50%;
        display: inline-block;
        background: $secondary-color;
        position: relative;
        top: 1px;
        margin-left: 3px;
    }
}
.end_not{
    width: 15px;
    height: 15px;
    border-radius: 50%;
    display: inline-block;
    background: $secondary-color;
    position: relative;
    top: 1px;
    margin-left: 3px;
}
.landing_main_para{
    font-size: 16px;
    color: #36393F;
    margin-top: 10px;
}
.main_cont_wrap{
    background: #fff;
    position: relative;
    z-index: 9;
}
.percnt_ic{
   
        position: relative;
        bottom: 6px;
    
}
.numbers {
    line-height: 1em;
    text-align: center;
    margin: 40px auto;
    overflow: hidden;
  }
  .numbers__window {
    display: inline-block;
    overflow: hidden;
    width: .65em;
    height: 1em;
  }
  .numbers__window__digit {
    font: inherit;
    word-break: break-all;
    display: block;
    width: 0;
    padding: 0 50px 0 0;
    margin: 0 auto;
    overflow: inherit;
  }
  .num_anim{
    animation: counting 0.4s steps(10) forwards infinite;
  }
  .numbers__window__digit::before {
    content: attr(data-fake);
    display: inline-block;
    width: 100%;
    height: auto;
  }
  .numbers__window__digit--1 {
    animation-iteration-count: 3;
  }
  .numbers__window__digit--2 {
    animation-iteration-count: 3;
  }
  .numbers__window__digit--3 {
    animation-iteration-count: 9;
  }
  .numbers__window__digit--4 {
    animation-iteration-count: 12;
  }
  .numbers__window__digit--5 {
    animation-iteration-count: 15;
  }
  
  @keyframes counting {
    100% {
      transform: translate3d(0, -10em, 0);
    }
  }
.switch_blocks{

    .st_bg p{
        max-width: 500px;
    }
    
   .first_banner_txt{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;

    p{
        margin-top: 15px;
    }
    
   } 
}
#landingScrollTo{
    position: relative;
    top: -100px;
}

.box_head{
    height: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
}

.stats_section.str{
    margin-top: 150px;
}
.stats_section.rev{
    padding-top: 150px;
}

.stats_section{
    padding: 70px 0 0;
    margin-top: 50px;
    // .stats_wrap{
    //     padding-right: 50px;
    // }


    .stat_num{
        height: 90px;
    width: 130px;
    color: $primary-color;
    font-size: 3.5rem;
    position: absolute;
    top: -40px;
    right: 0;
    left: 0;
    border-radius: 15px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    }
    
    .bg_stat1{
        background: #2EB2AA;
    }
    .bg_stat2{
        background: #2E53B2;
    }
    .bg_stat3{
        background: #6B2EB2;
    }
    .bg_stat4{
        background: #B22E6D;
    }

    .stat_block {
        position: relative;
    padding: 0 20px 0 0px;
    text-align: center;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    border-right: 1px solid #ddd;
    max-height: 90px;
    height: 100%;
    
    &.l_child{
        border-right: none;
    }

        p{
            margin-top: 50px;
            font-weight: 500;
        }
    }
}
.nm_link{
    color:$primary-color;

    &:hover{
        color: $hover-color;
    }
}
.btn_group{
    display: flex;
    justify-content: space-between;
    max-width: 265px;
    flex-flow: row-reverse;
}
.stats_section.str:after{
    display: none;
    content: '';
    width: 100%;
    height: 520px;
    background: url(../images/landing/curve.svg) no-repeat -190px -170px;
    margin: auto;
    opacity: .40;
    position: absolute;
    background-size: cover;
    left: 0;
    right: 0;
    z-index: 99;
    pointer-events: none;
}

.sec_sep{
    width: 100%;
    height: 440px;
    background: url(../images/landing/curve_1.svg) no-repeat center center;
    margin: -120px auto;
    position: absolute;
    background-size: cover;
    left: 0;
    right: 0;
    z-index: 99;
    pointer-events: none;
    opacity: 30%;
    padding-bottom: 50px;
}
.sec_sep.inv_shape{
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

.edu_features:after {
    content: '';
    width: 100%;
    height: 520px;
    background: url(../images/landing/curve.svg) no-repeat -190px -170px;
    margin: auto;
    position: absolute;
    background-size: cover;
    left: 0;
    right: 0;
    z-index: 99;
    pointer-events: none;
}

.team_det {
    margin-top: 250px;
    margin-bottom: 100px;

    .team_block {
        background: #fff;
        padding: 20px;
        border-radius: 10px;
        box-shadow: 2px 3px 10px rgb(0 0 0 / 8%);
        height: 100%;

        img{
            margin: auto;
            display: block;
            height: 105px;
            object-fit: contain;
        }

        p{
            margin-top: 15px;
            font-weight: 500;
        }
    }
}

.p-rel{
    position: relative;
}

.testimonials{
    padding-top: 110px;
    padding-bottom: 110px;
    margin-top: 150px;
    background: $purple;
    width: 100%;
    position: relative;

    &:before{
        content: '';
    position: absolute;
    width: 109%;
    height: 110px;
    background:  $purple;
    transform: rotate(3deg);
    left: 0;
    right: 0;
    top: -50px;
    z-index: -1;
    display: none;
    }

    &:after{
        content: '';
    position: absolute;
    width: 109%;
    height: 200px;
    background:  $purple;
    transform: rotate(3deg);
    left: 0;
    right: 0;
    bottom: -97px;
    z-index: -1;
    display: none;
    }

    .testimonial_head{
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        h2{
            font-size: 2.2em;
            font-weight: 600;
            margin-bottom: 75px;
            color: #fff;
        }
    }

    .testimonial_sld {
        position: relative;
        width: 100%;
        z-index: 9;
        margin: auto;

    .slick-dots {
        bottom: -50px;
    }

    .slick-list{
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .slick-track{
        margin: auto;
        display: flex !important;
    }


    .slick-slide{
        text-align: center;
        padding: 0px 30px;
        height: inherit !important;

        & > div{
            height: 100%;
        }
    }

    .slick-arrow{
        position: absolute;
        height: 30px;
        width: 30px;
        z-index: 9;
        margin-top: 10px;

        &:before{
            background: url(../images/landing/arrow.svg) no-repeat center center;
            content: '';
            display: block;
            height: 30px;
            width: 30px;
            background-size: cover;
       
        }
    }
    .slick-prev{
        top: -25px;
        left: calc(100% - 90px);
        }

        .slick-next{
            top: -25px;
            right: 20px;
        
        &:before{
            transform: rotate(180deg);
        }
        }
    }
    
   

    .slick-dots li button:before{
        content: '';
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: #e1d3ff;
    }
    .slick-dots li {
        margin: 0 1px;
    }

    .testimonial_item{
        height: 100%;
    position: relative;
    border-radius: 15px;
    background: #ffffff00;
    text-align: left;
    box-shadow: 2px 3px 22px rgb(185 151 255 / 85%);
    transition: .2s all ease;

    &:hover{
        transform: scale(1.01);
    box-shadow: 2px 3px 20px 4px rgb(247 201 255);
    }

    &::after{
        content: '';
    position: absolute;
    background: url(../images/landing/testimonial_ico.svg) no-repeat center;
    background-size: 35px;
    z-index: 9;
    height: 65px;
    width: 65px;
    border-radius: 100%;
    top: -25px;
    left: -25px;
    background-color: #6d569e;
    }

        >div{
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        p{
            font-size: 16px;
            color: #ffffff;
            font-weight: 400;
            min-height: 300px;
            display: flex;
            align-items: flex-start;
            justify-content: center;
            padding: 30px 30px;
            margin: auto;
            line-height: 28px;
        }

        img{
            display: block;
            height: 84px;
            width: 84px;
            position:relative;
            top: -2px;
            left: -2px;

            object-fit: contain;
            padding: 1px;
        }
    }

    .testimonial_person {
        display: flex;
        max-width: 470px;
        justify-content: flex-start;
        align-items: center;
        padding-top: 30px;
        padding-bottom: 40px;
        border-top: 1px solid rgb(255 255 255 / 26%);
        position: relative;
        left: 0;
        right: 0;
        padding-left: 20px;
        padding-right: 20px;
        bottom: 0;
        height: 12rem
        
    }

    .testm_img{
        border-radius: 50%;
        background-color: #fff;
        overflow: hidden;
        height:80px;
        flex-shrink: 0;
        width: 80px;
        box-shadow: 2px 3px 10px rgba(0,0,0,0.06);
    }

    .testm_det{
        margin-left: 20px;

        h2{
            font-size: 15px;
            color: #fff;
            font-weight: 600;
        }
        h5{
            color: #fff;
            opacity: .8;
            font-weight: 400;
            font-size: 14px;
        }
    }
}


.faq {
    max-width: 1000px;
    margin: 150px auto 150px;

    .faq_questions{
        border-bottom: 2px solid #202020;
        margin-bottom: 40px;
        max-width: 1200px;
        margin-left: auto;
        margin-right: auto;
        position: relative;
    }

    .faq_que{
        font-size: 1.2em;
        padding-left: 0;
        font-weight: 600;
        padding-bottom: 10px;
        text-align: left;
        
        span.arrow_change{
            transform: rotate(90deg) translate(0px, -10px);
        }
    }

    .faq_que[aria-expanded="true"]{

        span.arrow_change{
            transform: rotate(-90deg) translate(0px, -10px);
        }
    }

    .faq_answ{
        padding-bottom: 15px;
        font-size: 16px;

        ul li{
            margin-bottom: 7px;
        }
    }
    span.arrow_change {
        position: absolute;
        right: 5px;
    font-size: 1.8em;
    top: -12px;
        height: 30px;
        bottom: 0px;
        transition: .3s all ease;
    }

    

    .box_head{
        max-height: 130px;
    align-items: center;
    justify-content: flex-start;
    }
}

.short_acd {
    margin-top: 45px;
}
.acd_head{
    font-size: 2rem;
    font-weight: 700;
    color: #202020;
    margin-bottom: 15px;
}


.academic_areas_final{
    .tab_img {
        text-align: center;
        padding-bottom: 30px;

        img{
            max-height: 350px;
            max-width: 100%;
        }
    }
}
.academic_areas {
     background: #edf3fa;
    // margin-top: 100px;
    min-height: 500px;
    position: relative;


    .student_ac_ar {
        border-top: 1px solid #ddd !important;
    }
    .landing_main_para{
        max-width: 700px;
    }

    .tab_img {
        text-align: center;

        img{
            height: auto;
            max-width: 100%;
            max-height: 350px;
        }
    }

    // &::before {
    //     content: '';
    //     background: url(../images/landing/slope_shape.svg) no-repeat center;
    //     min-height: 200px;
    //     position: absolute;
    //     width: 100%;
    //     left: 0;
    //     right: 0;
    //     bottom: -175px;
    //     z-index: 0;
    //     background-size: cover;
    // }

    .academic_sub {
        position: relative;

        // .tab-content{
        //     position: absolute;
        //     height: 100%;
        //     width: 100%;
        // }

        // .tab-content>.tab-pane{
        //     display: block !important;
        //     opacity: 0;
        //     position: absolute;
        //     height: 100%;
        //     width: 100%;
        //     transition: .2s all ease;
        //     pointer-events: none;
        // }
        .tab-content>.active {
            display: block;
            opacity: 1;
            pointer-events: all;
        }

        .nav-item{
            margin: 0;
            transition: .3s all ease;
        }
        .nav-tabs{
            position: relative;
            bottom: 55px;
            border-bottom: 1px solid #ddd !important;
        }

        .nav-tabs, .nav-link{

            border:none
        }
        .nav-link{
            width: 130px;
            margin-right: 20px;
            height: 55px;
            position: relative;
            color: #202020;
            font-weight: 500;
            outline: none !important;
        }
        .nav-link:focus-visible, .nav-link:focus{
            border-color: $primary-color !important;
        }
        .nav-link.active{
            background:none ;
            border-bottom: 3px solid $primary-color !important;

            span{
                position: relative;
                z-index: 99;
                font-weight: 600;
                color: $primary-color;
            }

            // &:before{
            //     content: '';
            //     background: url(../images/landing/cv_tab.svg) no-repeat center center;
            //     height: 52px;
            //     background-size: cover;
            //     width: 100%;
            //     position: absolute;
            //     top: 5px;
            //     left: 0;
            //     transition: .3s all ease;
            // }
        }
    }

    .ext_link{
        color: #202020;
            font-weight: 500;
            margin-top: 15px;

        img{
            margin-right: 5px;
        }

        
    }
}
.ext_link{
    i{
        color: $primary-color;
        font-size: 1.3em;
        position: relative;
        top: 3px;
        margin-right: 8px;
    }
}
.join_btn_insta{
    background: linear-gradient(to right, #624CC5, #DB2C74, #EA8631);
    -webkit-background-clip: text;
    font-weight: 600;
    -webkit-text-fill-color: transparent;
    border-image: linear-gradient(#624CC5, #DB2C74, #EA8631) 9;
    border-width: 2px;
    border-top: 0;
    border-left: 0;
    border-right:0;
    border-style: solid;
    margin-left: 10px;
}
.join_btn{
    border-bottom:2px solid $primary-color;
    color: $primary-color;
    font-weight: 600;
    margin-left: 10px;
    font-size: 15px;
    padding:0 ;
}


/********** Partners ************/

.p_head{
    font-size: 18px;
    font-weight: 600;
    color: #202020;
    text-transform: uppercase;
    letter-spacing: 2px;
    text-align: center;
    position: relative;
}
.partners{
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 100px;

    text-align: center;
    // &.media_fea .p_head::before{
    //     width: 45% !important;
    // }
    // &.media_fea .p_head::after{
    //     width: 45% !important;
    // }

    &.media_fea .p_img img{
        max-height: 130px;
    }
    .p_head{
        display: inline-block;
        margin-bottom: 60px;

        span{
            max-width: 230px;
            margin: auto;
            position: relative;
            display: block;
            background: #fff;
            z-index: 9;
        }

        &:before{
            width: 100%;
            border: 1px solid #CFD7E8;
            position: absolute;
            content: "";
            left: 185px;
            top: 10px;
        }
        &:after{
            width: 100%;
            border: 1px solid #CFD7E8;
            position: absolute;
            content: "";
            right: 185px;
            top: 10px;
        }
    }


        .p_img {
            margin-bottom: 50px;


            img{
                max-width: 100%;
                max-height: 100px;
                height: auto;
                object-fit: contain;
                transition: .2s all ease;

                    &:hover{
                        transform: scale(1.05, 1.05);
                    }
                }
            
        }
}

// .partners.media_fea{

//     .p_head{

//         &:before{
//             left: 365px;
//         }
//         &:after{
//             right: 365px;
//         }
//     }

// }

.download_app {
    max-width: 1100px;
    margin: 50px auto 0;
    background: #EAF1FF;
    padding: 30px;
    border-radius: 20px;
    display: flex;
    position: relative;
    overflow: hidden;
    min-height: 320px;

    .dwnld_img {
        position: absolute;
        right: 15px;
        bottom: -60px;
    

        img{
            padding: 30px;
        }
    }

    .dwld_txt{
        margin-top: 20px;
        margin-left: 20px;

        h2{
            font-size: 2.1em;
            color: #202020;
            font-weight: 700;
            margin-bottom: 20px;
        }

        .qr_block{
            display: flex;
            align-items: center;

            img{
                height: 150px;
            }

            .qr_txt{
                padding-left: 20px;
                img{height: 40px; margin-right: 10px;}

                h5{
                    font-size: 16px;
                    font-weight: 500;
                    margin-bottom: 15px;
                }
            }
        }
    }
}

.btn_whiteoutline {
    border: 1px solid #fff;
    padding: 12px 15px;
    color: #fff;
    border-radius: 4px;
    font-weight: 500;
    transition: .2s all ease;

    &:hover{
        background: #fff;
        color: $primary-color;
    }
}
.get_in_bg{
    margin-top: 100px;
    background: $primary-color;
    min-height: 150px;
    position: relative;
    display: flex;
    align-items: center;
    overflow: hidden;

    &::after{
        left: 0;
        top: -35px;
        position: absolute;
        content: "";
        height: 100%;
        width: 485px;
        background: url(../images/landing/shape_btm.svg) no-repeat center center;
    }
    &::before{
        right: 0;
        bottom: -35px;
        position: absolute;
        content: "";
        height: 100%;
        width: 485px;
        transform: rotate(180deg);
        background: url(../images/landing/shape_btm.svg) no-repeat center center;
    }

    .get_in_touch{
        max-width: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    z-index: 9;

    .get-50{
        width: 70%;
    }

        h3{
            font-size: 2rem;
            color: #fff;
            margin-bottom: 15px;
            font-weight: 700;
        }

        h5{
            font-size: 16px;
            color: #fff;
            opacity: .85;
            font-weight: 400;
        }
    }
}

.btn_white_out{
    height: 50px;
    background: #fff;
    color: $primary-color;
    font-weight: 600;
    font-size: 17px;
    width: 150px;
    border-radius: 3px;
    transition: .2s all ease;

    &:hover{
        background: #d5e3ff;
    }
}

footer{
    padding-top:50px ;
    padding-bottom: 30px;
    border-top: 1px solid #f1f1f1;

    .ft_center{
        display: flex;
       justify-content: center;
    }

    .ft_address{
        display: flex;
        align-items: flex-start;
        padding:0 10px;
        margin-top: 35px;
        i{
            color: $primary-color;
            font-weight: 600;
            font-size: 1.3em;
            margin-right: 10px;
            margin-top: 1px;
        }
        p{
            margin-bottom: .5rem;
        }
    }

    .ft_block{


        p{
            font-size: 15px;
            color: #202020;
        }

        h4{
            font-size: 18px;
            color: #202020;
            font-weight: 600;
        }

        ul{
            padding-left: 0;
            margin-top: 20px;

            li{
                list-style: none;
                margin-bottom: 8px;

                button{
                    color: #4d4d4d;
                    transition: .2s all ease;
                }

                &:hover button{
                    color: $primary-color;
                }
            }
        }
    }

    .ft_block.social_m{
        ul{
            li{
                float: left;
                margin-right: 15px;

                i{
                    font-size: 1.2em;
                }
            }
        }
    }
}

.edu_features{
    max-width: 1100px;
    margin: 180px auto 120px;

    .feature_wrap{
        display: flex;
        justify-content: space-between;
    }


    .edu_immersify {
        max-width: 200px;
        margin: auto;
    }

   

    .feature_blk {
        max-width: 350px;
        position: relative;

        &.ftb_1::after{
            bottom: -50px;
            right: -50px;
            background: #DDE8FF;
        }

        &.ftb_2::after{
            top: -50px;
            right: -50px;
            background: #F6E2FF;
        }

        &.ftb_3::after{
            bottom: 90px;
            left: -50px;
            background: #FFE2E2;
        }

        &.ftb_4::after{
            top: -50px;
            left: -50px;
            background: #EAFFE2;
        }

        &:after{

            z-index: 0;
            content: "";
            height: 150px;
            width: 200px;
            position: absolute;
            border-radius: 15px;
            z-index: 0;
        }

        .feature_inn{
            padding: 20px 25px;
            background: #fff;
            box-shadow: 2px 3px 10px rgba(0,0,0,0.08);
            border-radius: 15px;
            position: relative;
            z-index: 9;

            .feat_inside{
                margin-top: 25px;

                h5{
                    font-size: 14px;
                    font-weight: 600;
                    color: #000;
                }

                ul{
                    margin-top: 0;
                }
            }

            h4{
                font-size: 20px;
                font-weight: 700;
                color: #202020;
            }

            ul{
                padding-left: 20px;
                margin-top: 15px;

                li{
                    margin-bottom: 10px;
                    font-size: 15px;
                }
            }
        }
    }
}

.customers{
    max-width: 1000px;
    margin: 120px auto 120px;

    .colm {
        padding: 10px 15px;
        text-align: center;
        width: 180px;
        display: flex;
        align-items: center;
    }
    .customers_list {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }

    .p_img{
        margin: auto;
        text-align: center;
        transition: .2s all ease;
        
        a{
            display: inline-block;
       

                &:hover{
                    transform: scale(1.03, 1.03);
                }
                img {
                    max-width: 100%;
                    height: auto;
                    max-height: 90px;
                 }
    }
} 
}
.stats_section{
    
    .box_head{
        justify-content: flex-start;
        align-items: center;
        max-width: 800px;
        text-align: center;
        margin:0 auto 100px;
        
    }
}


.book_demo{
    margin-top: 100px;
    margin-bottom: 100px;

    .btn_common{
        max-width: 245px;
    }

    .demo_txt{
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
    }

    .demo_img{
        
        img{
            height: auto;
            max-width: 100%;
        }
    }
}

.first_banner_img  img{
    height: auto;
    max-width: 100%;
}
.first_banner_img {
    height: 100%;
}


/********** Privacy policy *********/
.privacy_p_content{
    border-top: 1px solid #ebebeb;
    margin-top: 100px;
    padding-top: 50px;
    padding-bottom: 50px;

    .prv_block{

        margin: 30px 0;
    }

    h2{
        font-size: 20px;
        font-weight: 600;
        color: #202020;
    }
    h3{
        font-size: 18px;
        font-weight: 600;
        color: #202020;
    }
    h4{
        font-size: 16px;
        font-weight: 600;
        color: #202020;
    }
}
.privacy_p{

    margin-top: 150px;

    .in_page_text, .in_page_img{
        height: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    .in_page_img img{
        max-height: 320px;
        max-width: 100%;
    }

}
.main_head{

    h2{
    font-size: 45px;
    color: #202020;
    font-weight: 700;

    .end_not{
        width: 15px;
        height: 15px;
        border-radius: 50%;
        display: inline-block;
        background: $secondary-color;
        position: relative;
        top: 1px;
        margin-left: 3px;
    }
    }

   h5{
    font-size: 18px;
    color: #4d4d4d;
    font-weight: 500;
   }
}
/********** Privacy policy *********/

/************* About us *************/
.media_features_lg{
    margin-top: 100px;
    background: #F3F7FB;
    padding: 70px 0;

    .media_ht_st{
    height: 100%;
    max-width: 780px;
    margin-left: auto;
    }
}

.media_ft_txt{

    p{
        margin-top: 20px;
        line-height: 32px;
        color: #36393F;
    }
}

.aboutus{

    margin-top: 100px;
    .hero_slider{
        height: auto;
    }
    
    .l_main_heading{
        align-items: flex-start;
        justify-content: center;
        height: 100%;
    }
    .hero_slider .l_main_heading h1{
        color: $heading_color;
    }
    .hero_slider .l_main_heading h2{
        color: $heading_color;

    }
}
.about_img{
    padding: 30px 10px;
    text-align: center;
    
    img{
        max-height: 465px;
        max-width: 100%;
        object-fit: contain;
    }
}

.media_images{

    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .p_img{
        text-align: center;

    img{
        height: 100px;
        object-fit: contain;
       
    }
}
}

.ar_dw{
    text-align: center;
    margin: 50px 0 100px;
    min-height: 228px;

    svg{
        opacity: .30;
    }

    .path {
        stroke-dasharray: 840;
        stroke-dashoffset:840;
        animation: dash 3s forwards ease-in-out;
      }

      .path2 {
        stroke-dasharray: 840;
        stroke-dashoffset:840;
        animation: dash 5s forwards ease-in-out;
        animation-delay: 2.5s;
      }
      
      @keyframes dash {
        from {
          stroke-dashoffset: 840;
        }
        to {
          stroke-dashoffset: 0;
        }
      }
}
.people_img{
    text-align: center;
    margin-bottom: 30px;

    img{
        max-width: 100%;
        height: auto;
    }
}
.people_story {
    max-width: 500px;

    h5{
        font-weight: 600;
    }

    p{
        font-size: 16px;
        line-height: 30px;
        margin-top: 10px;
    }

    h4{
        font-size: 16px;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 2px;
        position: relative;
        margin-bottom: 30px;

        &:after{

            content: "";
            width: 100px;
            border: 1px solid #1D2127;
            transform: rotate(0deg);
            position: absolute;
            top: 8px;
        }
    }
    h4.first_nm:after{
        
            left: 175px;
        
    }
    h4.second_nm:after{
        left: 245px;
    }
}

.growing{
    max-width: 775px;
    margin:150px auto 100px;

    button{
        color: $primary-color;
    }

    .p_grw{
        text-align: center;
        margin-top: 25px;
    }

    .grw_head{

        p{
            margin-top: 7px;
            line-height: 28px;
        }

        h2{
            font-size: 4em;
            font-weight: 700;
        }
    }
}


/************* About us *************/


/************* Related Links ***********/
.findoutmore {

    margin-top: 80px;
    .f_more_head{
        h4{
            font-size: 20px;
            font-weight: 600;
            margin-bottom: 15px;
        }
        border-bottom: 1px solid #E0E0E0;
    }

    .slick-slide {
        text-align: center;
        margin: 30px 0;
        height: 100%;
        padding: 10px;
    }

    .f_icon_top{
        height: 140px;
        background: #F4F8FD;
        display: flex;
        align-items: center;
        justify-content: center;

        i{
            font-size: 3em;
            color: $primary-color;
        }
    }

    .f_more_txt{
        text-align: left;
        padding: 20px 30px;
        min-height: 136px;

        h5{
            font-size: 18px;
            font-weight: 600;
        }
    }

    .f_more_item{
        cursor: pointer;
        background: #fff;
        box-shadow: 2px 3px 10px rgba(0,0,0,0.06);
        max-width: 400px;
        margin: auto;
        height: 100%;
        border-radius: 15px;
        overflow: hidden;
        transition: .2s all ease;

        &:hover{
            transform: scale(1.03);
        }
    }
    .slick-arrow:before {
        content: '';
        background: url(../images/landing/slider_arrow.svg) no-repeat center center;
        display: block;
        position: absolute;
        height: 50px;
        width: 50px;
        top: -45px;
        opacity: 1;
        background-size: contain;
    }

    .slick-disabled:before{
        opacity: 0.35;
    }
    .slick-prev{
        left: -55px;
        top: 57%;
    }
    .slick-prev:before{
        transform: rotate(180deg);
    }
    .slick-next{
        right: -30px;
        top: 57%;
    }
}
/************* Related Links ***********/


/********** Dentistry *********/
.dentistry_main{
    .hero_slider .l_main_heading{
        height: 100%;
        min-height: 670px;
    }
}
.dentistry_modules{
    background: #F4F8FD;
    padding: 80px 0;
}

.case_blocks{
    margin-top: 70px;

    .col-lg-3{
        margin-bottom: 20px;
    }
}
.use_cnt_block {
    padding: 30px;
    background: #F4F8FD;
    border-radius: 15px;
    text-align: center;
    height: 100%;
    transition: .2s all ease;

    &:hover{
        background: #0077D6;
        color: #fff !important;
    }
    &:hover h4, &:hover p{
        color: #fff !important;
    }
    &:hover i{
        color: #fff;
    }

    i{
        font-size: 3.2em;
        color: $primary-color;
    }

    h4{
        font-weight: 600;
        font-size: 20px;
        color:$heading_color;
        margin-top: 15px;
    }

    p{
        text-align: left;
    }
}

.unlock_pot_wrap {
    background: $purple;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 60px;
    margin-bottom: 80px;
    margin-top: 80px;

    .unlock_txt{
        width: 45%;
        h2{
            font-size: 2em;
            font-weight: 700;
            color: #fff;
            margin-bottom: 30px;
        }

        .btn_outline{
            border-color: #fff;
            color: #fff;
            font-weight: 500;

            &:hover{
                background: #fff;
                color: $purple;
            }
        }

    }
    
    .unlock_img{

        width: 55%;
        text-align: right;

        img{
            height: 260px;
            padding: 15px;
            max-width: 100%;
        }
    }
}

.content_rely{

    margin-top: 150px;
    margin-bottom: 100px;
    padding: 0 15px;

    .heading_wrap{
        max-width: 950px;
        margin: auto;
    }

    .content_people {
        position: relative;
    }

    .card {
        position: relative;
    width: 100%;
    height: 350px;
    margin: auto;
    float: left;
    perspective: 500px;
    border: none;
    margin-bottom: 20px;
    background: transparent;
      }
      
      .content {
        position: absolute;
        width: 100%;
        height: 100%;
      
        transition: transform 1s;
        transform-style: preserve-3d;
      }
      
      .card:hover .content {
        transform: rotateY( 180deg ) ;
        transition: transform 0.5s;
      }
      .front{

        padding: 15px 25px;

        img{
            height: 160px;
            max-width: 100%;
            object-fit: contain;
            border:5px solid $primary-color;
            border-radius: 50%;
        }

        h4{
            margin-top: 20px;
            font-size: 18px;
            font-weight: 600;
        }
        h6{
            font-size: 14px;
            color: #36393F;
        }
      }
      .front,
      .back {
        position: absolute;
    height: 100%;
    width: 100%;
    text-align: center;
    border-radius: 15px;
    backface-visibility: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    // box-shadow: 2px 3px 10px rgba(0,0,0,0.08);
    background: #edf3fa
      }
      
      .back {
        background: $primary-color;
        transform: rotateY( 180deg );
        overflow-y: auto;
        padding: 25px;
        text-align: left;
        color: #fff;
        justify-content: flex-start;

        p{
            color: #fff;
            font-size: 14px;
        }
      }
}

.topic_listings{

    padding-top: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .content_links {
            .video_item:hover {
                background: #fff;
            }
        }
    .content_links.premium_item {
            .video_item:hover {
                background: linear-gradient(#FAEFFF, #ECF2FF);
            }
        }
}
/********** Dentistry *********/

/************** Professionals ***********/
.mt_pro_1{
    margin-top: 150px;
}
.mt_pro_2{
    margin-top: 250px;
}
.button_sec{
    max-width: 245px;
    margin:50px auto;
}
.m_head{
    text-align: center;
    max-width: 750px;
    margin: auto;
}
.mt_pro{
    margin-top: 80px;
}
.prof_q_box{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 20px;
    background: #edf3fa;
    border-radius: 15px;
    padding: 30px;
    height: 100%;

    img{
        height: 75px;
        object-fit: contain;
    }

    h4{
        font-weight: 600;
        font-size: 20px;
        color:$heading_color;
        margin-top: 15px;
    }
    p{
        text-align: left;
    }
}
/************** Professionals ***********/
/********** Responsive **************/

@media(max-width:320px){
    .top_sec .hero_slider .scrolling-words-box ul li, .top_sec .hero_slider .l_main_heading h1{
        font-size: 1.5rem;
    }
}

@media(max-width:767px){
    .awards_top .p_img{
        padding: 15px;
    }

    .hero_slider {
        height: calc(100vh - 100px) !important;
    }

    .down_pointer{
        bottom: 25px !important;
    }
    .stats_section.str{
        margin-top: 50px;
    }
   
    .stats_section .stat_block{
        border-right: none;
        border-bottom: 1px solid #ddd;
        padding-bottom: 15px;
        max-height: 140px;
    }

    .content_rely {
        margin-top: 100px;
    }

    .dynamic-txts li, .video_part .video_in_txt h4, .landing_main_head{
        font-size: 1.7em;
    }
    .stats_section.edr{
        margin-top: 50px;
    }
    .video_part{

        padding: 50px 0 120px;
        .video_in{
            height: 310px;
        }
        .video_in_txt{
            flex-direction: column;
            padding-bottom: 20px;
        }
    }

    .ar_dw svg{

        width: 310px !important;
    }

    .media_features_lg{
        margin-top: 30px;
    }
    .findoutmore .slick-slide{
        margin: 0;
    }

    .growing{
        max-width: 775px;
        margin:70px auto 150px;

        .grw_head{
            h2{
                font-size: 3em;
                font-weight: 700;
            }
            .p_grw{
                text-align: left;
            }
        }
        }
    

    .unlock_pot_wrap {
        flex-wrap: wrap-reverse;
        padding: 10px 40px 40px;

        .unlock_txt, .unlock_img{
            width: 100%;
        }
        .unlock_img img{
            padding: 0;
        }
    }

    .testimonials .testimonial_sld{
        padding: 0;
    }
    .testimonials .testimonial_sld .slick-slide {
        padding: 0px 25px;
    }
    .testimonials .testimonial_head h2{
        font-size: 2.2em;
    }

    .tip_model{
        right: 0;
    top: 170px;
    padding: 5px 15px 5px 0px;
        &::after{
            bottom: 67px;
            left: 50px;
            transform: rotate(306deg);
        }
    }

    .navbar{
        padding: 2px 0;
    }


    .top_sec .fixed_nav{
        height: auto;
    }

    .navbar-brand{

        img{
            height: 35px;
        }
    }


    .switch_blocks .first_banner_txt{
        padding-right: 0;
    }

    .short_acd{
        margin-top: 0;
    }
    .book_demo .demo_img img{
        height: auto;
        max-width: 100%;
    }

    .edu_features{

        .feature_wrap{
            flex-direction: column;
            align-items: center;
        }
        .feature_blk{
            margin-bottom: 15px;
            &::after{
                display: none;
            }
        }

    }

    .download_app {

        min-height: auto;

        .dwnld_img{

            display: none;
        }
        .qr_block > img{
            display: none;
        }
        .qr_block a, .qr_block img{
            margin-bottom: 5px;
        }
        .dwld_txt .qr_block .qr_txt{
            padding-left: 0;
            padding-top: 5px;
        }
        .dwld_txt {
            margin-top: 0px;
            margin-left: 5px;
        }
    }
    
    footer .ft_center {
        justify-content: flex-start;
        margin-top: 25px;
    }
    footer .ft_address{
        padding-left: 0;
    }
    .get_in_bg{

        &::before{
            bottom: -200px;
        }

        &.get_in_bg::after {
            top: -200px;
        }
        
        .get_in_touch{
            padding-bottom: 50px;
            position: relative;
            z-index: 99;
            padding-top: 50px;
            flex-direction: column;
            align-items: flex-start;

            .get-50 {
                width: 100%;
            }

            h3{
                font-size: 2.2rem;
            }
            .btn_whiteoutline {
                margin-top: 15px;
            }
        }
    }

    .hero_slider .col-md-12 .video_sec {
        min-height: 140px;
    }

   

    .hero_slider .button_grp{
        flex-direction: column;

        button, a{
            margin-bottom: 10px;
            font-size: 14px;
            margin-right: 0;
        }
    }
    .academic_areas .academic_sub .nav-link{
        width: 103px;
        font-size: 14px;
        padding: 0 0;
    }

    .first_banner_img img, .tab_img img{
        height: 250px;
    }
    
   
    .faq {

        .faq_que {
            font-size: 20px;
            text-align: left;
            padding-right: 25px;
        }
        span.arrow_change{
            top: 0;
        }
    }

    .book_demo {
        margin-top: 50px;

        .demo_img{
            padding-bottom: 30px;
        }
    }
    .partners{
        overflow: hidden;
    }
    .testimonials {
        min-height: 680px;

        &::after{
            height: 680px;
        }
        
        .testm_det h2{
            font-size: 18px;
        }
        .testimonial_item img{
            height: 75px;
            width: 75px;
        }
    }
}

@media(min-width:1540px){
    .hero_txt_block{
        max-width: 90%;
    }
}
@media(min-width:1100px){
    .hero_txt_block{
        max-width: 90%;
    }
}

// @media only screen and (max-width: 1279px) and (min-width: 991px)  {
//     .hero_slider .l_main_heading h1 {
//         font-size: 1.8em;
//     }
// }

@media(min-width: 1440px){
    .topic_listings{

        .col-xxl-3 {
            flex: 0 0 auto;
            width: 33.3333%;
        }
    }
} 
@media(max-width:991px){
    
    .nav_wrap{
        height: auto;
    }

    .hero_slider {
        height: calc(81vh - 100px);
        
        // .hero_txt_block{
        //     padding:  0 35px;
        // }

        // .l_main_heading{
        //     padding-top: 120px;
        // }

        .hero_txt_block:after {
            background: linear-gradient(to top, rgba(0, 16, 34, 0.8),  rgb(0 0 0 / 25%));
        }
    }
    

    .awards_top{
        text-align: center;
    }

    .faq{
        margin: 50px auto 50px;
    }

    .hero_slider{

        .scrolling-words-box{
            margin: auto;
        }
        .scrolling-words-container{
            width: 100%;
        }
        .scrolling-words-box ul li, .l_main_heading h1{
            font-size: 1.7rem;
            text-align: center;
            align-items: center;
            justify-content: center;
        }
        .l_main_heading {
            align-items: center;
        }

        .l_main_heading h2{
            display: inline-block;
            width: 100%;
            text-align: center;
            font-size: 1.1em;
        }
    }

    .testimonials .testimonial_person{
        flex-direction: column;

        .testm_img{
            margin-bottom: 25px;
        }
    }

    .prof_q_box{
        height: auto;
    }

   .aboutus{
    .hero_slider .video_sec{
        height: auto;
    }
   }

    .in_page_img{
        margin-top: 30px;
    }

    .faq .faq_que{
        font-size: 18px;
        padding-right: 20px;
    }
    .top_sec{
        min-height: auto;
        margin-top: 70px;
    }

    .hero_slider{
        margin-top: 45px;
        padding-bottom: 40px;
    }

    section.bottom_navs {

        position: relative;
        overflow: hidden;
        height: 80px;

        .btm_nav_links.active{
            background:#f8faff ;
        }

        .btm_nav .tab_mover{
            display: none;
        }
        .btm_nav_links{
            min-width: 33.33333%;
            margin-right: 0px;
        }
        .btm_nav_links p{
            display: none;
        }
        .ins_navtab{
            display: flex;
            flex-direction: column;
        }
        .btm_nav_links h3,  .btm_nav_links.active h3{
            margin-top: 4px;
            margin-left: 6px;
            margin-bottom: 0;
            font-size: 14px;
        }
        .btm_nav_links.active{
            bottom: 0;
        }
        .btm_nav_links i, .btm_nav_links.active i{
            font-size: 1.2rem;
        }
    }

    .testimonials .testimonial_item p {
        font-size: 18px;
        padding: 30px;
    }

    .edu_features
    {
        .feature_blk{

        &::after{
            display: none;
        }
        max-width: 280px;
        }
    }

    .faq .box_head{
        margin-bottom: 20px;
        max-height: unset;
        height: auto;
    }
    .mt-lg{
        padding-top: 30px;
    }
    .mt-70{
        margin-top: 30px;
    }
    .landing_main_head {
        font-size: 1.7em;
        padding: 0;
    }

    .stats_section .stats_wrap{
        padding-right: 0;
    }

    .download_app{
        margin: 50px 30px 0;
    }

    .partners{
        position: relative;
        overflow: hidden;
    }

    .stats_section.rev{
        padding-top: 50px;
    }

    .box_head{
        margin-bottom: 150px;
    }

    .hero_slider {

        .video_sec{
            height: 240px;
        }
        .col-md-12 .l_main_heading{
            min-height:0;
            text-align: center;
            margin-top: 20px;
            height: auto !important;
        }

        .button_grp{
            align-items: center;
            justify-content: center;
            margin-left: auto;
        }
    }



    // .nav_wrap{
    //     position: relative;
    // }

    .top_sec .fixed_nav .main_nav{
        padding: 5px 15px;
        max-width: 100%;

       .navbar-collapse{
        padding-top: 15px;
       
       }

       .nav-item{
        margin-bottom: 10px;

        .nav-link{
            display: inline-block;
        }
       }
    }
}

@media(max-width:1441px){
    .bottom_navs{
        height: 145px;
    }
    .bottom_navs .btm_nav .tab_mover {
        height: 160px;
    }
    // .hero_slider .l_main_heading{
    //     min-height: 520px;
    // }
}
@media  (min-height: 1081px) and (max-height: 3000px) {
    .bottom_navs{
        position: relative;
    }
    .top_sec{
        min-height: auto !important;
    }
}