p {
  color: #36393F;
}

a, a:hover {
  cursor: pointer;
}

a {
  color: #0088EE;
}

.top_sec {
  background: #1c1c1c;
  position: relative;
  z-index: 0;
  margin-top: 100px;
}

a.btn_common {
  color: #fff;
}

.btn_common, .btn_outline {
  min-height: 50px;
}

.fixed_nav {
  position: fixed;
  box-shadow: 2px 3px 10px rgba(0, 0, 0, 0.08);
  top: 0;
  height: auto;
}

.nav_wrap {
  transition: 0.2s all ease;
  height: 100px;
  position: fixed;
  width: 100%;
  margin: auto;
  background: #fff;
  z-index: 9999;
  top: 0;
}

.prof {
  padding-top: 50px;
}
.prof .short_acd {
  margin-top: 0px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.prof h3 {
  font-size: 14px;
  letter-spacing: 2px;
  color: #0088EE;
  text-transform: uppercase;
  font-weight: 500;
}

/*********** Website New Video Section ****************/
.st_bg .first_banner_img {
  border-radius: 15px;
  max-width: 540px;
  margin: auto;
}

.mob_item .mob_img {
  margin: auto;
  background: #EDF3FA;
  transition: 0.3s all ease;
}
.mob_item .mob_img video {
  background: #EDF3FA;
  clip-path: inset(1px 1px);
  outline: none !important;
}
.mob_item .mob_img.c_sim img {
  height: 230px;
  width: 230px;
  object-fit: contain;
  margin: auto;
}
.mob_item h4 {
  font-size: 20px;
  margin-bottom: 40px;
  margin-top: 20px;
  font-weight: 600;
}

.scrolling-words-container {
  display: flex;
  align-items: center;
  font-size: 3rem;
  font-weight: 600;
}

.scrolling-words-container {
  display: flex;
  align-items: center;
  font-size: 3rem;
  font-weight: 700;
}

.scrolling-words-box {
  height: 3.5rem;
  margin-right: auto;
  overflow: hidden;
}
.scrolling-words-box ul {
  margin: 0 0;
  padding: 0;
  animation: scrollUp 8s infinite;
}
.scrolling-words-box ul li {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 3.5rem;
  list-style: none;
  color: #2caaff;
}

@keyframes scrollUp {
  6.66667%, 16.66667% {
    transform: translateY(-16.6666666667%);
  }
  23.33334%, 33.33334% {
    transform: translateY(-33.3333333333%);
  }
  40.00001%, 50.00001% {
    transform: translateY(-50%);
  }
  56.66668%, 66.66668% {
    transform: translateY(-66.6666666667%);
  }
  73.33335%, 83.33335% {
    transform: translateY(-83.3333333333%);
  }
}
.web_app_in {
  height: 650px;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 2px 3px 80px -40px rgba(0, 0, 0, 0.34);
}

section.web_app_block {
  margin-top: 100px;
  padding: 80px 0;
}

.video_part {
  min-height: 500px;
  position: relative;
  padding: 70px 0 70px;
  background: #2a1d47;
  margin-bottom: 155px;
  margin-top: 50px;
}
.video_part:before {
  content: "";
  position: absolute;
  width: 109%;
  height: 200px;
  background: #2a1d47;
  transform: rotate(3deg);
  left: 0;
  right: 0;
  top: -72px;
  z-index: -1;
  display: none;
}
.video_part:after {
  content: "";
  position: absolute;
  width: 109%;
  height: 200px;
  background: #2a1d47;
  transform: rotate(3deg);
  left: 0;
  right: 0;
  bottom: -97px;
  z-index: -1;
  display: none;
}
.video_part .video_in_txt {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: center;
}
.video_part .video_in_txt h4 {
  font-size: 2.2em;
  font-weight: 700;
  max-width: 700px;
  color: #fff;
}
.video_part .video_in_txt h4 span {
  color: #0088EE;
}
.video_part .video_in_txt h6 {
  margin-top: 15px;
  font-size: 18px;
  color: #fff;
  font-weight: 500;
}
.video_part .video_in {
  height: 447px;
  padding: 30px;
}
.video_part .video_in iframe {
  border-radius: 20px;
  box-shadow: 1px 2px 60px 0px rgba(186, 133, 255, 0.4);
}
.video_part .btn_getintouch {
  text-align: center;
  margin-top: 20px;
}
.video_part .btn_getintouch button {
  margin: auto;
}

.select_uni {
  text-align: center;
}
.select_uni p {
  font-size: 18px;
  font-weight: 500;
  margin-top: 50px;
}
.select_uni p button {
  padding: 0;
}

.awards_wrap {
  width: 100%;
}

.awards_top {
  text-align: left;
  padding: 50px 0 0;
}
.awards_top .p_img {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
}
.awards_top img {
  max-height: 200px;
  max-width: 100%;
}

/*********** Website New Video Section ****************/
.main_nav {
  margin: auto;
  width: 100%;
  padding-top: 10px;
  transition: 0.2s all ease;
}
.main_nav .navbar-toggler {
  border: none;
  padding: 0;
  outline: none !important;
  box-shadow: none !important;
}
.main_nav .nav-item {
  margin: 0 20px;
  cursor: pointer;
}
.main_nav .navbar-nav .nav-link {
  color: #202020;
  font-size: 16px;
  font-weight: 500;
  padding-left: 0;
  padding-right: 0;
  cursor: pointer;
}
.main_nav .navbar-nav .free_trial .nav-link {
  background: #0088EE;
  padding-right: 15px;
  padding-left: 15px;
  display: inline-block;
  color: #fff;
  border-radius: 3px;
}
.main_nav .navbar-nav .nav-link.active {
  color: #0088EE;
  position: relative;
}
.main_nav .navbar-nav .nav-link.active::after {
  content: "";
  border-bottom: 2px solid #0088EE;
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 3px;
}

.video_sec {
  position: relative;
  min-height: 500px;
}
.video_sec .thumbnail_modl {
  position: absolute;
  z-index: 0;
}
.video_sec .landing_model {
  position: absolute;
  height: 100%;
  width: 100%;
}
.video_sec .landing_model .a-canvas {
  display: block;
}
.video_sec #AFrameScene {
  height: 100%;
}

.small_head {
  font-weight: 600;
  font-size: 15px;
  color: #0088EE;
  letter-spacing: 2px;
  margin-bottom: 15px;
  text-transform: uppercase;
}

.getintouch .modal-dialog {
  max-width: 700px;
  margin-top: 150px;
}
.getintouch .btn_mod_close {
  position: absolute;
  z-index: 99;
  right: 25px;
  top: 25px;
}
.getintouch .cntct_wrap {
  padding: 20px;
}
.getintouch .cntct_wrap form {
  margin-top: 20px;
}
.getintouch .cntct_wrap form .form-group {
  margin-bottom: 15px;
}
.getintouch .cntct_wrap form .form-group .form-control {
  border-radius: 3px;
  width: 100%;
}
.getintouch .cntct_wrap form .form-group label {
  font-size: 13px;
}
.getintouch .cntct_wrap form .form-group .formRequiredField {
  color: red;
}
.getintouch .cntct_wrap form .form-group .countrySelect input {
  border: none;
}
.getintouch .cntct_wrap form .message_sec textarea {
  min-height: 140px;
}
.getintouch .cntct_wrap h2 {
  font-size: 28px;
  font-weight: 700;
  color: #0088EE;
}
.getintouch .btn_areas {
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.getintouch .btn_areas button {
  min-width: 120px;
  margin-left: 10px;
  margin-bottom: 10px;
  margin-top: 20px;
}

.video_sec {
  height: 100%;
}
.video_sec video {
  clip-path: inset(1px 1px);
}

.bounce2 {
  animation: bounce2 2s ease infinite;
}

@keyframes bounce2 {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-10px);
  }
}
.hero_slider {
  position: relative;
  z-index: 9;
  height: calc(100vh - 100px);
}
.hero_slider .bg_immersify_video {
  position: absolute;
  left: 0;
  right: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.hero_slider .bg_immersify_video video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.hero_slider .hero_txt_block {
  margin: auto;
  padding: 0 15px;
  height: 100%;
}
.hero_slider .hero_txt_block:after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background: linear-gradient(to right, rgba(0, 16, 34, 0.8), transparent);
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
.hero_slider .hero_txt_block .hero_txt, .hero_slider .hero_txt_block .l_main_heading {
  height: 100%;
}
.hero_slider .hero_txt_block .hero_txt .down_pointer {
  position: absolute;
  bottom: 75px;
  left: 0;
  right: 0;
  margin: auto;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid #fff;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.4em;
  cursor: pointer;
}
.hero_slider .l_main_heading {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.hero_slider .l_main_heading h1 {
  font-size: 3rem;
  font-weight: 700;
  max-width: 800px;
  margin-bottom: 0;
  color: #fff;
}
.hero_slider .l_main_heading h2 {
  font-size: 1.4em;
  font-weight: 400;
  color: #fff;
  margin-top: 15px;
  margin-bottom: 20px;
}
.hero_slider .button_grp {
  display: flex;
  justify-content: flex-start;
  margin-right: auto;
  margin-top: 30px;
}
.hero_slider .button_grp button {
  min-width: 150px;
  margin: 0 10px 0 0;
  white-space: nowrap;
}
.hero_slider .button_grp a {
  white-space: nowrap;
}

.a-loader-title {
  display: none !important;
}

.tip_model {
  position: absolute;
  background: #fff;
  box-shadow: 2px 12px 40px rgba(0, 0, 0, 0.16);
  padding: 15px 20px;
  border-radius: 10px;
  z-index: 99;
  right: -35px;
  top: 35px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.tip_model::after {
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 20px solid #fff;
  content: "";
  position: absolute;
  bottom: -16px;
  left: 17px;
  transform: rotate(11deg);
}
.tip_model .tip_img {
  background: url(../images/landing/mouse.gif) no-repeat center center;
  height: 70px;
  width: 70px;
  background-size: contain;
}
.tip_model .tip_img2 {
  background: url(../images/landing/mobile_int.gif) no-repeat center center;
  height: 70px;
  width: 70px;
  background-size: contain;
}
.tip_model h3 {
  font-size: 16px;
  font-weight: 700;
  color: #0088EE;
}
.tip_model h5 {
  font-size: 14px;
  font-weight: 500;
}

.bottom_navs {
  background: #0088EE;
  height: 180px;
  position: relative;
  width: 100%;
  bottom: 0;
}
.bottom_navs .container, .bottom_navs .row, .bottom_navs .row > div, .bottom_navs .btm_nav {
  height: 100%;
}
.bottom_navs .ins_navtab {
  position: relative;
  text-align: center;
}
.bottom_navs .btm_nav {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
}
.bottom_navs .btm_nav .tab_mover {
  height: 200px;
  width: 290px;
  position: absolute;
  left: 0;
  content: "";
  bottom: 0;
  background: #f8faff;
  box-shadow: 0px -13px 20px rgba(0, 0, 0, 0.05);
  transition: 0.2s all ease;
}
.bottom_navs .btm_nav .tab_mover.left50 {
  left: 310px;
}
.bottom_navs .btm_nav .tab_mover.left100 {
  left: 620px;
}
.bottom_navs .btm_nav_links {
  margin-right: 20px;
  height: 100%;
  padding: 0 30px;
  min-width: 290px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: relative;
  cursor: pointer;
}
.bottom_navs .btm_nav_links i {
  font-weight: 500;
  color: #fff;
  font-size: 2rem;
}
.bottom_navs .btm_nav_links h3 {
  color: #fff;
  font-weight: 500;
  font-size: 20px;
  margin-top: 15px;
}
.bottom_navs .btm_nav_links p {
  color: #cce2ff;
  font-weight: 400;
  margin-bottom: 0;
  font-size: 14px;
}
.bottom_navs .btm_nav_links.active {
  bottom: 14px;
  height: 115%;
}
.bottom_navs .btm_nav_links.active i {
  font-weight: 500;
  color: #0088EE;
  font-size: 2rem;
}
.bottom_navs .btm_nav_links.active h3 {
  color: #0088EE;
  font-weight: 600;
  font-size: 20px;
  margin-top: 15px;
}
.bottom_navs .btm_nav_links.active p {
  color: #0d2442;
  font-weight: 400;
}

section.what_prof {
  max-width: 1190px;
  margin: auto;
  padding-bottom: 80px;
}
section.what_prof .container {
  margin-top: 60px;
}
section.what_prof .col-md-4 {
  margin-bottom: 20px;
}
section.what_prof .prof_blk {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: flex-start;
  flex-direction: column;
  padding: 30px 20px;
  background: #fff;
  border-radius: 15px;
  box-shadow: 2px 3px 10px rgba(0, 0, 0, 0.08);
}
section.what_prof .prof_blk .prof_ico img {
  height: 80px;
  object-fit: contain;
}
section.what_prof .prof_blk h4 {
  font-size: 20px;
  font-weight: 600;
  color: #202020;
  margin-top: 15px;
}
section.what_prof .prof_blk h5 {
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  color: #4d4d4d;
  margin-top: 10px;
}
section.what_prof .prof_blk .ext_link {
  font-size: 13px;
  text-align: center;
  margin-top: 20px;
  min-height: 40px;
}
section.what_prof .prof_blk .ext_link img {
  height: 20px;
  margin-right: 3px;
}

.highlighted_c {
  color: #0088EE;
  font-weight: 600;
  font-size: 18px;
}

.mt-lg {
  padding-top: 100px;
}

.mt-70 {
  margin-top: 70px;
}

.mb-80 {
  margin-bottom: 80px;
}

.landing_main_head {
  font-size: 2.2rem;
  font-weight: 700;
  color: #1D2127;
  position: relative;
  display: inline;
}
.landing_main_head .end_not {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  display: inline-block;
  background: #7521B9;
  position: relative;
  top: 1px;
  margin-left: 3px;
}

.end_not {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  display: inline-block;
  background: #7521B9;
  position: relative;
  top: 1px;
  margin-left: 3px;
}

.landing_main_para {
  font-size: 16px;
  color: #36393F;
  margin-top: 10px;
}

.main_cont_wrap {
  background: #fff;
  position: relative;
  z-index: 9;
}

.percnt_ic {
  position: relative;
  bottom: 6px;
}

.numbers {
  line-height: 1em;
  text-align: center;
  margin: 40px auto;
  overflow: hidden;
}

.numbers__window {
  display: inline-block;
  overflow: hidden;
  width: 0.65em;
  height: 1em;
}

.numbers__window__digit {
  font: inherit;
  word-break: break-all;
  display: block;
  width: 0;
  padding: 0 50px 0 0;
  margin: 0 auto;
  overflow: inherit;
}

.num_anim {
  animation: counting 0.4s steps(10) forwards infinite;
}

.numbers__window__digit::before {
  content: attr(data-fake);
  display: inline-block;
  width: 100%;
  height: auto;
}

.numbers__window__digit--1 {
  animation-iteration-count: 3;
}

.numbers__window__digit--2 {
  animation-iteration-count: 3;
}

.numbers__window__digit--3 {
  animation-iteration-count: 9;
}

.numbers__window__digit--4 {
  animation-iteration-count: 12;
}

.numbers__window__digit--5 {
  animation-iteration-count: 15;
}

@keyframes counting {
  100% {
    transform: translate3d(0, -10em, 0);
  }
}
.switch_blocks .st_bg p {
  max-width: 500px;
}
.switch_blocks .first_banner_txt {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.switch_blocks .first_banner_txt p {
  margin-top: 15px;
}

#landingScrollTo {
  position: relative;
  top: -100px;
}

.box_head {
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.stats_section.str {
  margin-top: 150px;
}

.stats_section.rev {
  padding-top: 150px;
}

.stats_section {
  padding: 70px 0 0;
  margin-top: 50px;
}
.stats_section .stat_num {
  height: 90px;
  width: 130px;
  color: #0088EE;
  font-size: 3.5rem;
  position: absolute;
  top: -40px;
  right: 0;
  left: 0;
  border-radius: 15px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
}
.stats_section .bg_stat1 {
  background: #2EB2AA;
}
.stats_section .bg_stat2 {
  background: #2E53B2;
}
.stats_section .bg_stat3 {
  background: #6B2EB2;
}
.stats_section .bg_stat4 {
  background: #B22E6D;
}
.stats_section .stat_block {
  position: relative;
  padding: 0 20px 0 0px;
  text-align: center;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  border-right: 1px solid #ddd;
  max-height: 90px;
  height: 100%;
}
.stats_section .stat_block.l_child {
  border-right: none;
}
.stats_section .stat_block p {
  margin-top: 50px;
  font-weight: 500;
}

.nm_link {
  color: #0088EE;
}
.nm_link:hover {
  color: #5089fa;
}

.btn_group {
  display: flex;
  justify-content: space-between;
  max-width: 265px;
  flex-flow: row-reverse;
}

.stats_section.str:after {
  display: none;
  content: "";
  width: 100%;
  height: 520px;
  background: url(../images/landing/curve.svg) no-repeat -190px -170px;
  margin: auto;
  opacity: 0.4;
  position: absolute;
  background-size: cover;
  left: 0;
  right: 0;
  z-index: 99;
  pointer-events: none;
}

.sec_sep {
  width: 100%;
  height: 440px;
  background: url(../images/landing/curve_1.svg) no-repeat center center;
  margin: -120px auto;
  position: absolute;
  background-size: cover;
  left: 0;
  right: 0;
  z-index: 99;
  pointer-events: none;
  opacity: 30%;
  padding-bottom: 50px;
}

.sec_sep.inv_shape {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.edu_features:after {
  content: "";
  width: 100%;
  height: 520px;
  background: url(../images/landing/curve.svg) no-repeat -190px -170px;
  margin: auto;
  position: absolute;
  background-size: cover;
  left: 0;
  right: 0;
  z-index: 99;
  pointer-events: none;
}

.team_det {
  margin-top: 250px;
  margin-bottom: 100px;
}
.team_det .team_block {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 2px 3px 10px rgba(0, 0, 0, 0.08);
  height: 100%;
}
.team_det .team_block img {
  margin: auto;
  display: block;
  height: 105px;
  object-fit: contain;
}
.team_det .team_block p {
  margin-top: 15px;
  font-weight: 500;
}

.p-rel {
  position: relative;
}

.testimonials {
  padding-top: 110px;
  padding-bottom: 110px;
  margin-top: 150px;
  background: #2a1d47;
  width: 100%;
  position: relative;
}
.testimonials:before {
  content: "";
  position: absolute;
  width: 109%;
  height: 110px;
  background: #2a1d47;
  transform: rotate(3deg);
  left: 0;
  right: 0;
  top: -50px;
  z-index: -1;
  display: none;
}
.testimonials:after {
  content: "";
  position: absolute;
  width: 109%;
  height: 200px;
  background: #2a1d47;
  transform: rotate(3deg);
  left: 0;
  right: 0;
  bottom: -97px;
  z-index: -1;
  display: none;
}
.testimonials .testimonial_head {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.testimonials .testimonial_head h2 {
  font-size: 2.2em;
  font-weight: 600;
  margin-bottom: 75px;
  color: #fff;
}
.testimonials .testimonial_sld {
  position: relative;
  width: 100%;
  z-index: 9;
  margin: auto;
}
.testimonials .testimonial_sld .slick-dots {
  bottom: -50px;
}
.testimonials .testimonial_sld .slick-list {
  padding-top: 30px;
  padding-bottom: 30px;
}
.testimonials .testimonial_sld .slick-track {
  margin: auto;
  display: flex !important;
}
.testimonials .testimonial_sld .slick-slide {
  text-align: center;
  padding: 0px 30px;
  height: inherit !important;
}
.testimonials .testimonial_sld .slick-slide > div {
  height: 100%;
}
.testimonials .testimonial_sld .slick-arrow {
  position: absolute;
  height: 30px;
  width: 30px;
  z-index: 9;
  margin-top: 10px;
}
.testimonials .testimonial_sld .slick-arrow:before {
  background: url(../images/landing/arrow.svg) no-repeat center center;
  content: "";
  display: block;
  height: 30px;
  width: 30px;
  background-size: cover;
}
.testimonials .testimonial_sld .slick-prev {
  top: -25px;
  left: calc(100% - 90px);
}
.testimonials .testimonial_sld .slick-next {
  top: -25px;
  right: 20px;
}
.testimonials .testimonial_sld .slick-next:before {
  transform: rotate(180deg);
}
.testimonials .slick-dots li button:before {
  content: "";
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #e1d3ff;
}
.testimonials .slick-dots li {
  margin: 0 1px;
}
.testimonials .testimonial_item {
  height: 100%;
  position: relative;
  border-radius: 15px;
  background: rgba(255, 255, 255, 0);
  text-align: left;
  box-shadow: 2px 3px 22px rgba(185, 151, 255, 0.85);
  transition: 0.2s all ease;
}
.testimonials .testimonial_item:hover {
  transform: scale(1.01);
  box-shadow: 2px 3px 20px 4px rgb(247, 201, 255);
}
.testimonials .testimonial_item::after {
  content: "";
  position: absolute;
  background: url(../images/landing/testimonial_ico.svg) no-repeat center;
  background-size: 35px;
  z-index: 9;
  height: 65px;
  width: 65px;
  border-radius: 100%;
  top: -25px;
  left: -25px;
  background-color: #6d569e;
}
.testimonials .testimonial_item > div {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.testimonials .testimonial_item p {
  font-size: 16px;
  color: #ffffff;
  font-weight: 400;
  min-height: 300px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 30px 30px;
  margin: auto;
  line-height: 28px;
}
.testimonials .testimonial_item img {
  display: block;
  height: 84px;
  width: 84px;
  position: relative;
  top: -2px;
  left: -2px;
  object-fit: contain;
  padding: 1px;
}
.testimonials .testimonial_person {
  display: flex;
  max-width: 470px;
  justify-content: flex-start;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 40px;
  border-top: 1px solid rgba(255, 255, 255, 0.26);
  position: relative;
  left: 0;
  right: 0;
  padding-left: 20px;
  padding-right: 20px;
  bottom: 0;
  height: 12rem;
}
.testimonials .testm_img {
  border-radius: 50%;
  background-color: #fff;
  overflow: hidden;
  height: 80px;
  flex-shrink: 0;
  width: 80px;
  box-shadow: 2px 3px 10px rgba(0, 0, 0, 0.06);
}
.testimonials .testm_det {
  margin-left: 20px;
}
.testimonials .testm_det h2 {
  font-size: 15px;
  color: #fff;
  font-weight: 600;
}
.testimonials .testm_det h5 {
  color: #fff;
  opacity: 0.8;
  font-weight: 400;
  font-size: 14px;
}

.faq {
  max-width: 1000px;
  margin: 150px auto 150px;
}
.faq .faq_questions {
  border-bottom: 2px solid #202020;
  margin-bottom: 40px;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}
.faq .faq_que {
  font-size: 1.2em;
  padding-left: 0;
  font-weight: 600;
  padding-bottom: 10px;
  text-align: left;
}
.faq .faq_que span.arrow_change {
  transform: rotate(90deg) translate(0px, -10px);
}
.faq .faq_que[aria-expanded=true] span.arrow_change {
  transform: rotate(-90deg) translate(0px, -10px);
}
.faq .faq_answ {
  padding-bottom: 15px;
  font-size: 16px;
}
.faq .faq_answ ul li {
  margin-bottom: 7px;
}
.faq span.arrow_change {
  position: absolute;
  right: 5px;
  font-size: 1.8em;
  top: -12px;
  height: 30px;
  bottom: 0px;
  transition: 0.3s all ease;
}
.faq .box_head {
  max-height: 130px;
  align-items: center;
  justify-content: flex-start;
}

.short_acd {
  margin-top: 45px;
}

.acd_head {
  font-size: 2rem;
  font-weight: 700;
  color: #202020;
  margin-bottom: 15px;
}

.academic_areas_final .tab_img {
  text-align: center;
  padding-bottom: 30px;
}
.academic_areas_final .tab_img img {
  max-height: 350px;
  max-width: 100%;
}

.academic_areas {
  background: #edf3fa;
  min-height: 500px;
  position: relative;
}
.academic_areas .student_ac_ar {
  border-top: 1px solid #ddd !important;
}
.academic_areas .landing_main_para {
  max-width: 700px;
}
.academic_areas .tab_img {
  text-align: center;
}
.academic_areas .tab_img img {
  height: auto;
  max-width: 100%;
  max-height: 350px;
}
.academic_areas .academic_sub {
  position: relative;
}
.academic_areas .academic_sub .tab-content > .active {
  display: block;
  opacity: 1;
  pointer-events: all;
}
.academic_areas .academic_sub .nav-item {
  margin: 0;
  transition: 0.3s all ease;
}
.academic_areas .academic_sub .nav-tabs {
  position: relative;
  bottom: 55px;
  border-bottom: 1px solid #ddd !important;
}
.academic_areas .academic_sub .nav-tabs, .academic_areas .academic_sub .nav-link {
  border: none;
}
.academic_areas .academic_sub .nav-link {
  width: 130px;
  margin-right: 20px;
  height: 55px;
  position: relative;
  color: #202020;
  font-weight: 500;
  outline: none !important;
}
.academic_areas .academic_sub .nav-link:focus-visible, .academic_areas .academic_sub .nav-link:focus {
  border-color: #0088EE !important;
}
.academic_areas .academic_sub .nav-link.active {
  background: none;
  border-bottom: 3px solid #0088EE !important;
}
.academic_areas .academic_sub .nav-link.active span {
  position: relative;
  z-index: 99;
  font-weight: 600;
  color: #0088EE;
}
.academic_areas .ext_link {
  color: #202020;
  font-weight: 500;
  margin-top: 15px;
}
.academic_areas .ext_link img {
  margin-right: 5px;
}

.ext_link i {
  color: #0088EE;
  font-size: 1.3em;
  position: relative;
  top: 3px;
  margin-right: 8px;
}

.join_btn_insta {
  background: linear-gradient(to right, #624CC5, #DB2C74, #EA8631);
  -webkit-background-clip: text;
  font-weight: 600;
  -webkit-text-fill-color: transparent;
  border-image: linear-gradient(#624CC5, #DB2C74, #EA8631) 9;
  border-width: 2px;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-style: solid;
  margin-left: 10px;
}

.join_btn {
  border-bottom: 2px solid #0088EE;
  color: #0088EE;
  font-weight: 600;
  margin-left: 10px;
  font-size: 15px;
  padding: 0;
}

/********** Partners ************/
.p_head {
  font-size: 18px;
  font-weight: 600;
  color: #202020;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-align: center;
  position: relative;
}

.partners {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 100px;
  text-align: center;
}
.partners.media_fea .p_img img {
  max-height: 130px;
}
.partners .p_head {
  display: inline-block;
  margin-bottom: 60px;
}
.partners .p_head span {
  max-width: 230px;
  margin: auto;
  position: relative;
  display: block;
  background: #fff;
  z-index: 9;
}
.partners .p_head:before {
  width: 100%;
  border: 1px solid #CFD7E8;
  position: absolute;
  content: "";
  left: 185px;
  top: 10px;
}
.partners .p_head:after {
  width: 100%;
  border: 1px solid #CFD7E8;
  position: absolute;
  content: "";
  right: 185px;
  top: 10px;
}
.partners .p_img {
  margin-bottom: 50px;
}
.partners .p_img img {
  max-width: 100%;
  max-height: 100px;
  height: auto;
  object-fit: contain;
  transition: 0.2s all ease;
}
.partners .p_img img:hover {
  transform: scale(1.05, 1.05);
}

.download_app {
  max-width: 1100px;
  margin: 50px auto 0;
  background: #EAF1FF;
  padding: 30px;
  border-radius: 20px;
  display: flex;
  position: relative;
  overflow: hidden;
  min-height: 320px;
}
.download_app .dwnld_img {
  position: absolute;
  right: 15px;
  bottom: -60px;
}
.download_app .dwnld_img img {
  padding: 30px;
}
.download_app .dwld_txt {
  margin-top: 20px;
  margin-left: 20px;
}
.download_app .dwld_txt h2 {
  font-size: 2.1em;
  color: #202020;
  font-weight: 700;
  margin-bottom: 20px;
}
.download_app .dwld_txt .qr_block {
  display: flex;
  align-items: center;
}
.download_app .dwld_txt .qr_block img {
  height: 150px;
}
.download_app .dwld_txt .qr_block .qr_txt {
  padding-left: 20px;
}
.download_app .dwld_txt .qr_block .qr_txt img {
  height: 40px;
  margin-right: 10px;
}
.download_app .dwld_txt .qr_block .qr_txt h5 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 15px;
}

.btn_whiteoutline {
  border: 1px solid #fff;
  padding: 12px 15px;
  color: #fff;
  border-radius: 4px;
  font-weight: 500;
  transition: 0.2s all ease;
}
.btn_whiteoutline:hover {
  background: #fff;
  color: #0088EE;
}

.get_in_bg {
  margin-top: 100px;
  background: #0088EE;
  min-height: 150px;
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
}
.get_in_bg::after {
  left: 0;
  top: -35px;
  position: absolute;
  content: "";
  height: 100%;
  width: 485px;
  background: url(../images/landing/shape_btm.svg) no-repeat center center;
}
.get_in_bg::before {
  right: 0;
  bottom: -35px;
  position: absolute;
  content: "";
  height: 100%;
  width: 485px;
  transform: rotate(180deg);
  background: url(../images/landing/shape_btm.svg) no-repeat center center;
}
.get_in_bg .get_in_touch {
  max-width: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  z-index: 9;
}
.get_in_bg .get_in_touch .get-50 {
  width: 70%;
}
.get_in_bg .get_in_touch h3 {
  font-size: 2rem;
  color: #fff;
  margin-bottom: 15px;
  font-weight: 700;
}
.get_in_bg .get_in_touch h5 {
  font-size: 16px;
  color: #fff;
  opacity: 0.85;
  font-weight: 400;
}

.btn_white_out {
  height: 50px;
  background: #fff;
  color: #0088EE;
  font-weight: 600;
  font-size: 17px;
  width: 150px;
  border-radius: 3px;
  transition: 0.2s all ease;
}
.btn_white_out:hover {
  background: #d5e3ff;
}

footer {
  padding-top: 50px;
  padding-bottom: 30px;
  border-top: 1px solid #f1f1f1;
}
footer .ft_center {
  display: flex;
  justify-content: center;
}
footer .ft_address {
  display: flex;
  align-items: flex-start;
  padding: 0 10px;
  margin-top: 35px;
}
footer .ft_address i {
  color: #0088EE;
  font-weight: 600;
  font-size: 1.3em;
  margin-right: 10px;
  margin-top: 1px;
}
footer .ft_address p {
  margin-bottom: 0.5rem;
}
footer .ft_block p {
  font-size: 15px;
  color: #202020;
}
footer .ft_block h4 {
  font-size: 18px;
  color: #202020;
  font-weight: 600;
}
footer .ft_block ul {
  padding-left: 0;
  margin-top: 20px;
}
footer .ft_block ul li {
  list-style: none;
  margin-bottom: 8px;
}
footer .ft_block ul li button {
  color: #4d4d4d;
  transition: 0.2s all ease;
}
footer .ft_block ul li:hover button {
  color: #0088EE;
}
footer .ft_block.social_m ul li {
  float: left;
  margin-right: 15px;
}
footer .ft_block.social_m ul li i {
  font-size: 1.2em;
}

.edu_features {
  max-width: 1100px;
  margin: 180px auto 120px;
}
.edu_features .feature_wrap {
  display: flex;
  justify-content: space-between;
}
.edu_features .edu_immersify {
  max-width: 200px;
  margin: auto;
}
.edu_features .feature_blk {
  max-width: 350px;
  position: relative;
}
.edu_features .feature_blk.ftb_1::after {
  bottom: -50px;
  right: -50px;
  background: #DDE8FF;
}
.edu_features .feature_blk.ftb_2::after {
  top: -50px;
  right: -50px;
  background: #F6E2FF;
}
.edu_features .feature_blk.ftb_3::after {
  bottom: 90px;
  left: -50px;
  background: #FFE2E2;
}
.edu_features .feature_blk.ftb_4::after {
  top: -50px;
  left: -50px;
  background: #EAFFE2;
}
.edu_features .feature_blk:after {
  z-index: 0;
  content: "";
  height: 150px;
  width: 200px;
  position: absolute;
  border-radius: 15px;
  z-index: 0;
}
.edu_features .feature_blk .feature_inn {
  padding: 20px 25px;
  background: #fff;
  box-shadow: 2px 3px 10px rgba(0, 0, 0, 0.08);
  border-radius: 15px;
  position: relative;
  z-index: 9;
}
.edu_features .feature_blk .feature_inn .feat_inside {
  margin-top: 25px;
}
.edu_features .feature_blk .feature_inn .feat_inside h5 {
  font-size: 14px;
  font-weight: 600;
  color: #000;
}
.edu_features .feature_blk .feature_inn .feat_inside ul {
  margin-top: 0;
}
.edu_features .feature_blk .feature_inn h4 {
  font-size: 20px;
  font-weight: 700;
  color: #202020;
}
.edu_features .feature_blk .feature_inn ul {
  padding-left: 20px;
  margin-top: 15px;
}
.edu_features .feature_blk .feature_inn ul li {
  margin-bottom: 10px;
  font-size: 15px;
}

.customers {
  max-width: 1000px;
  margin: 120px auto 120px;
}
.customers .colm {
  padding: 10px 15px;
  text-align: center;
  width: 180px;
  display: flex;
  align-items: center;
}
.customers .customers_list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.customers .p_img {
  margin: auto;
  text-align: center;
  transition: 0.2s all ease;
}
.customers .p_img a {
  display: inline-block;
}
.customers .p_img a:hover {
  transform: scale(1.03, 1.03);
}
.customers .p_img a img {
  max-width: 100%;
  height: auto;
  max-height: 90px;
}

.stats_section .box_head {
  justify-content: flex-start;
  align-items: center;
  max-width: 800px;
  text-align: center;
  margin: 0 auto 100px;
}

.book_demo {
  margin-top: 100px;
  margin-bottom: 100px;
}
.book_demo .btn_common {
  max-width: 245px;
}
.book_demo .demo_txt {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.book_demo .demo_img img {
  height: auto;
  max-width: 100%;
}

.first_banner_img img {
  height: auto;
  max-width: 100%;
}

.first_banner_img {
  height: 100%;
}

/********** Privacy policy *********/
.privacy_p_content {
  border-top: 1px solid #ebebeb;
  margin-top: 100px;
  padding-top: 50px;
  padding-bottom: 50px;
}
.privacy_p_content .prv_block {
  margin: 30px 0;
}
.privacy_p_content h2 {
  font-size: 20px;
  font-weight: 600;
  color: #202020;
}
.privacy_p_content h3 {
  font-size: 18px;
  font-weight: 600;
  color: #202020;
}
.privacy_p_content h4 {
  font-size: 16px;
  font-weight: 600;
  color: #202020;
}

.privacy_p {
  margin-top: 150px;
}
.privacy_p .in_page_text, .privacy_p .in_page_img {
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.privacy_p .in_page_img img {
  max-height: 320px;
  max-width: 100%;
}

.main_head h2 {
  font-size: 45px;
  color: #202020;
  font-weight: 700;
}
.main_head h2 .end_not {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  display: inline-block;
  background: #7521B9;
  position: relative;
  top: 1px;
  margin-left: 3px;
}
.main_head h5 {
  font-size: 18px;
  color: #4d4d4d;
  font-weight: 500;
}

/********** Privacy policy *********/
/************* About us *************/
.media_features_lg {
  margin-top: 100px;
  background: #F3F7FB;
  padding: 70px 0;
}
.media_features_lg .media_ht_st {
  height: 100%;
  max-width: 780px;
  margin-left: auto;
}

.media_ft_txt p {
  margin-top: 20px;
  line-height: 32px;
  color: #36393F;
}

.aboutus {
  margin-top: 100px;
}
.aboutus .hero_slider {
  height: auto;
}
.aboutus .l_main_heading {
  align-items: flex-start;
  justify-content: center;
  height: 100%;
}
.aboutus .hero_slider .l_main_heading h1 {
  color: #04080F;
}
.aboutus .hero_slider .l_main_heading h2 {
  color: #04080F;
}

.about_img {
  padding: 30px 10px;
  text-align: center;
}
.about_img img {
  max-height: 465px;
  max-width: 100%;
  object-fit: contain;
}

.media_images {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.media_images .p_img {
  text-align: center;
}
.media_images .p_img img {
  height: 100px;
  object-fit: contain;
}

.ar_dw {
  text-align: center;
  margin: 50px 0 100px;
  min-height: 228px;
}
.ar_dw svg {
  opacity: 0.3;
}
.ar_dw .path {
  stroke-dasharray: 840;
  stroke-dashoffset: 840;
  animation: dash 3s forwards ease-in-out;
}
.ar_dw .path2 {
  stroke-dasharray: 840;
  stroke-dashoffset: 840;
  animation: dash 5s forwards ease-in-out;
  animation-delay: 2.5s;
}
@keyframes dash {
  from {
    stroke-dashoffset: 840;
  }
  to {
    stroke-dashoffset: 0;
  }
}

.people_img {
  text-align: center;
  margin-bottom: 30px;
}
.people_img img {
  max-width: 100%;
  height: auto;
}

.people_story {
  max-width: 500px;
}
.people_story h5 {
  font-weight: 600;
}
.people_story p {
  font-size: 16px;
  line-height: 30px;
  margin-top: 10px;
}
.people_story h4 {
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 2px;
  position: relative;
  margin-bottom: 30px;
}
.people_story h4:after {
  content: "";
  width: 100px;
  border: 1px solid #1D2127;
  transform: rotate(0deg);
  position: absolute;
  top: 8px;
}
.people_story h4.first_nm:after {
  left: 175px;
}
.people_story h4.second_nm:after {
  left: 245px;
}

.growing {
  max-width: 775px;
  margin: 150px auto 100px;
}
.growing button {
  color: #0088EE;
}
.growing .p_grw {
  text-align: center;
  margin-top: 25px;
}
.growing .grw_head p {
  margin-top: 7px;
  line-height: 28px;
}
.growing .grw_head h2 {
  font-size: 4em;
  font-weight: 700;
}

/************* About us *************/
/************* Related Links ***********/
.findoutmore {
  margin-top: 80px;
}
.findoutmore .f_more_head {
  border-bottom: 1px solid #E0E0E0;
}
.findoutmore .f_more_head h4 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 15px;
}
.findoutmore .slick-slide {
  text-align: center;
  margin: 30px 0;
  height: 100%;
  padding: 10px;
}
.findoutmore .f_icon_top {
  height: 140px;
  background: #F4F8FD;
  display: flex;
  align-items: center;
  justify-content: center;
}
.findoutmore .f_icon_top i {
  font-size: 3em;
  color: #0088EE;
}
.findoutmore .f_more_txt {
  text-align: left;
  padding: 20px 30px;
  min-height: 136px;
}
.findoutmore .f_more_txt h5 {
  font-size: 18px;
  font-weight: 600;
}
.findoutmore .f_more_item {
  cursor: pointer;
  background: #fff;
  box-shadow: 2px 3px 10px rgba(0, 0, 0, 0.06);
  max-width: 400px;
  margin: auto;
  height: 100%;
  border-radius: 15px;
  overflow: hidden;
  transition: 0.2s all ease;
}
.findoutmore .f_more_item:hover {
  transform: scale(1.03);
}
.findoutmore .slick-arrow:before {
  content: "";
  background: url(../images/landing/slider_arrow.svg) no-repeat center center;
  display: block;
  position: absolute;
  height: 50px;
  width: 50px;
  top: -45px;
  opacity: 1;
  background-size: contain;
}
.findoutmore .slick-disabled:before {
  opacity: 0.35;
}
.findoutmore .slick-prev {
  left: -55px;
  top: 57%;
}
.findoutmore .slick-prev:before {
  transform: rotate(180deg);
}
.findoutmore .slick-next {
  right: -30px;
  top: 57%;
}

/************* Related Links ***********/
/********** Dentistry *********/
.dentistry_main .hero_slider .l_main_heading {
  height: 100%;
  min-height: 670px;
}

.dentistry_modules {
  background: #F4F8FD;
  padding: 80px 0;
}

.case_blocks {
  margin-top: 70px;
}
.case_blocks .col-lg-3 {
  margin-bottom: 20px;
}

.use_cnt_block {
  padding: 30px;
  background: #F4F8FD;
  border-radius: 15px;
  text-align: center;
  height: 100%;
  transition: 0.2s all ease;
}
.use_cnt_block:hover {
  background: #0077D6;
  color: #fff !important;
}
.use_cnt_block:hover h4, .use_cnt_block:hover p {
  color: #fff !important;
}
.use_cnt_block:hover i {
  color: #fff;
}
.use_cnt_block i {
  font-size: 3.2em;
  color: #0088EE;
}
.use_cnt_block h4 {
  font-weight: 600;
  font-size: 20px;
  color: #04080F;
  margin-top: 15px;
}
.use_cnt_block p {
  text-align: left;
}

.unlock_pot_wrap {
  background: #2a1d47;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 60px;
  margin-bottom: 80px;
  margin-top: 80px;
}
.unlock_pot_wrap .unlock_txt {
  width: 45%;
}
.unlock_pot_wrap .unlock_txt h2 {
  font-size: 2em;
  font-weight: 700;
  color: #fff;
  margin-bottom: 30px;
}
.unlock_pot_wrap .unlock_txt .btn_outline {
  border-color: #fff;
  color: #fff;
  font-weight: 500;
}
.unlock_pot_wrap .unlock_txt .btn_outline:hover {
  background: #fff;
  color: #2a1d47;
}
.unlock_pot_wrap .unlock_img {
  width: 55%;
  text-align: right;
}
.unlock_pot_wrap .unlock_img img {
  height: 260px;
  padding: 15px;
  max-width: 100%;
}

.content_rely {
  margin-top: 150px;
  margin-bottom: 100px;
  padding: 0 15px;
}
.content_rely .heading_wrap {
  max-width: 950px;
  margin: auto;
}
.content_rely .content_people {
  position: relative;
}
.content_rely .card {
  position: relative;
  width: 100%;
  height: 350px;
  margin: auto;
  float: left;
  perspective: 500px;
  border: none;
  margin-bottom: 20px;
  background: transparent;
}
.content_rely .content {
  position: absolute;
  width: 100%;
  height: 100%;
  transition: transform 1s;
  transform-style: preserve-3d;
}
.content_rely .card:hover .content {
  transform: rotateY(180deg);
  transition: transform 0.5s;
}
.content_rely .front {
  padding: 15px 25px;
}
.content_rely .front img {
  height: 160px;
  max-width: 100%;
  object-fit: contain;
  border: 5px solid #0088EE;
  border-radius: 50%;
}
.content_rely .front h4 {
  margin-top: 20px;
  font-size: 18px;
  font-weight: 600;
}
.content_rely .front h6 {
  font-size: 14px;
  color: #36393F;
}
.content_rely .front,
.content_rely .back {
  position: absolute;
  height: 100%;
  width: 100%;
  text-align: center;
  border-radius: 15px;
  backface-visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #edf3fa;
}
.content_rely .back {
  background: #0088EE;
  transform: rotateY(180deg);
  overflow-y: auto;
  padding: 25px;
  text-align: left;
  color: #fff;
  justify-content: flex-start;
}
.content_rely .back p {
  color: #fff;
  font-size: 14px;
}

.topic_listings {
  padding-top: 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.topic_listings .content_links .video_item:hover {
  background: #fff;
}
.topic_listings .content_links.premium_item .video_item:hover {
  background: linear-gradient(#FAEFFF, #ECF2FF);
}

/********** Dentistry *********/
/************** Professionals ***********/
.mt_pro_1 {
  margin-top: 150px;
}

.mt_pro_2 {
  margin-top: 250px;
}

.button_sec {
  max-width: 245px;
  margin: 50px auto;
}

.m_head {
  text-align: center;
  max-width: 750px;
  margin: auto;
}

.mt_pro {
  margin-top: 80px;
}

.prof_q_box {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 20px;
  background: #edf3fa;
  border-radius: 15px;
  padding: 30px;
  height: 100%;
}
.prof_q_box img {
  height: 75px;
  object-fit: contain;
}
.prof_q_box h4 {
  font-weight: 600;
  font-size: 20px;
  color: #04080F;
  margin-top: 15px;
}
.prof_q_box p {
  text-align: left;
}

/************** Professionals ***********/
/********** Responsive **************/
@media (max-width: 320px) {
  .top_sec .hero_slider .scrolling-words-box ul li, .top_sec .hero_slider .l_main_heading h1 {
    font-size: 1.5rem;
  }
}
@media (max-width: 767px) {
  .awards_top .p_img {
    padding: 15px;
  }
  .hero_slider {
    height: calc(100vh - 100px) !important;
  }
  .down_pointer {
    bottom: 25px !important;
  }
  .stats_section.str {
    margin-top: 50px;
  }
  .stats_section .stat_block {
    border-right: none;
    border-bottom: 1px solid #ddd;
    padding-bottom: 15px;
    max-height: 140px;
  }
  .content_rely {
    margin-top: 100px;
  }
  .dynamic-txts li, .video_part .video_in_txt h4, .landing_main_head {
    font-size: 1.7em;
  }
  .stats_section.edr {
    margin-top: 50px;
  }
  .video_part {
    padding: 50px 0 120px;
  }
  .video_part .video_in {
    height: 310px;
  }
  .video_part .video_in_txt {
    flex-direction: column;
    padding-bottom: 20px;
  }
  .ar_dw svg {
    width: 310px !important;
  }
  .media_features_lg {
    margin-top: 30px;
  }
  .findoutmore .slick-slide {
    margin: 0;
  }
  .growing {
    max-width: 775px;
    margin: 70px auto 150px;
  }
  .growing .grw_head h2 {
    font-size: 3em;
    font-weight: 700;
  }
  .growing .grw_head .p_grw {
    text-align: left;
  }
  .unlock_pot_wrap {
    flex-wrap: wrap-reverse;
    padding: 10px 40px 40px;
  }
  .unlock_pot_wrap .unlock_txt, .unlock_pot_wrap .unlock_img {
    width: 100%;
  }
  .unlock_pot_wrap .unlock_img img {
    padding: 0;
  }
  .testimonials .testimonial_sld {
    padding: 0;
  }
  .testimonials .testimonial_sld .slick-slide {
    padding: 0px 25px;
  }
  .testimonials .testimonial_head h2 {
    font-size: 2.2em;
  }
  .tip_model {
    right: 0;
    top: 170px;
    padding: 5px 15px 5px 0px;
  }
  .tip_model::after {
    bottom: 67px;
    left: 50px;
    transform: rotate(306deg);
  }
  .navbar {
    padding: 2px 0;
  }
  .top_sec .fixed_nav {
    height: auto;
  }
  .navbar-brand img {
    height: 35px;
  }
  .switch_blocks .first_banner_txt {
    padding-right: 0;
  }
  .short_acd {
    margin-top: 0;
  }
  .book_demo .demo_img img {
    height: auto;
    max-width: 100%;
  }
  .edu_features .feature_wrap {
    flex-direction: column;
    align-items: center;
  }
  .edu_features .feature_blk {
    margin-bottom: 15px;
  }
  .edu_features .feature_blk::after {
    display: none;
  }
  .download_app {
    min-height: auto;
  }
  .download_app .dwnld_img {
    display: none;
  }
  .download_app .qr_block > img {
    display: none;
  }
  .download_app .qr_block a, .download_app .qr_block img {
    margin-bottom: 5px;
  }
  .download_app .dwld_txt .qr_block .qr_txt {
    padding-left: 0;
    padding-top: 5px;
  }
  .download_app .dwld_txt {
    margin-top: 0px;
    margin-left: 5px;
  }
  footer .ft_center {
    justify-content: flex-start;
    margin-top: 25px;
  }
  footer .ft_address {
    padding-left: 0;
  }
  .get_in_bg::before {
    bottom: -200px;
  }
  .get_in_bg.get_in_bg::after {
    top: -200px;
  }
  .get_in_bg .get_in_touch {
    padding-bottom: 50px;
    position: relative;
    z-index: 99;
    padding-top: 50px;
    flex-direction: column;
    align-items: flex-start;
  }
  .get_in_bg .get_in_touch .get-50 {
    width: 100%;
  }
  .get_in_bg .get_in_touch h3 {
    font-size: 2.2rem;
  }
  .get_in_bg .get_in_touch .btn_whiteoutline {
    margin-top: 15px;
  }
  .hero_slider .col-md-12 .video_sec {
    min-height: 140px;
  }
  .hero_slider .button_grp {
    flex-direction: column;
  }
  .hero_slider .button_grp button, .hero_slider .button_grp a {
    margin-bottom: 10px;
    font-size: 14px;
    margin-right: 0;
  }
  .academic_areas .academic_sub .nav-link {
    width: 103px;
    font-size: 14px;
    padding: 0 0;
  }
  .first_banner_img img, .tab_img img {
    height: 250px;
  }
  .faq .faq_que {
    font-size: 20px;
    text-align: left;
    padding-right: 25px;
  }
  .faq span.arrow_change {
    top: 0;
  }
  .book_demo {
    margin-top: 50px;
  }
  .book_demo .demo_img {
    padding-bottom: 30px;
  }
  .partners {
    overflow: hidden;
  }
  .testimonials {
    min-height: 680px;
  }
  .testimonials::after {
    height: 680px;
  }
  .testimonials .testm_det h2 {
    font-size: 18px;
  }
  .testimonials .testimonial_item img {
    height: 75px;
    width: 75px;
  }
}
@media (min-width: 1540px) {
  .hero_txt_block {
    max-width: 90%;
  }
}
@media (min-width: 1100px) {
  .hero_txt_block {
    max-width: 90%;
  }
}
@media (min-width: 1440px) {
  .topic_listings .col-xxl-3 {
    flex: 0 0 auto;
    width: 33.3333%;
  }
}
@media (max-width: 991px) {
  .nav_wrap {
    height: auto;
  }
  .hero_slider {
    height: calc(81vh - 100px);
  }
  .hero_slider .hero_txt_block:after {
    background: linear-gradient(to top, rgba(0, 16, 34, 0.8), rgba(0, 0, 0, 0.25));
  }
  .awards_top {
    text-align: center;
  }
  .faq {
    margin: 50px auto 50px;
  }
  .hero_slider .scrolling-words-box {
    margin: auto;
  }
  .hero_slider .scrolling-words-container {
    width: 100%;
  }
  .hero_slider .scrolling-words-box ul li, .hero_slider .l_main_heading h1 {
    font-size: 1.7rem;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
  .hero_slider .l_main_heading {
    align-items: center;
  }
  .hero_slider .l_main_heading h2 {
    display: inline-block;
    width: 100%;
    text-align: center;
    font-size: 1.1em;
  }
  .testimonials .testimonial_person {
    flex-direction: column;
  }
  .testimonials .testimonial_person .testm_img {
    margin-bottom: 25px;
  }
  .prof_q_box {
    height: auto;
  }
  .aboutus .hero_slider .video_sec {
    height: auto;
  }
  .in_page_img {
    margin-top: 30px;
  }
  .faq .faq_que {
    font-size: 18px;
    padding-right: 20px;
  }
  .top_sec {
    min-height: auto;
    margin-top: 70px;
  }
  .hero_slider {
    margin-top: 45px;
    padding-bottom: 40px;
  }
  section.bottom_navs {
    position: relative;
    overflow: hidden;
    height: 80px;
  }
  section.bottom_navs .btm_nav_links.active {
    background: #f8faff;
  }
  section.bottom_navs .btm_nav .tab_mover {
    display: none;
  }
  section.bottom_navs .btm_nav_links {
    min-width: 33.33333%;
    margin-right: 0px;
  }
  section.bottom_navs .btm_nav_links p {
    display: none;
  }
  section.bottom_navs .ins_navtab {
    display: flex;
    flex-direction: column;
  }
  section.bottom_navs .btm_nav_links h3, section.bottom_navs .btm_nav_links.active h3 {
    margin-top: 4px;
    margin-left: 6px;
    margin-bottom: 0;
    font-size: 14px;
  }
  section.bottom_navs .btm_nav_links.active {
    bottom: 0;
  }
  section.bottom_navs .btm_nav_links i, section.bottom_navs .btm_nav_links.active i {
    font-size: 1.2rem;
  }
  .testimonials .testimonial_item p {
    font-size: 18px;
    padding: 30px;
  }
  .edu_features .feature_blk {
    max-width: 280px;
  }
  .edu_features .feature_blk::after {
    display: none;
  }
  .faq .box_head {
    margin-bottom: 20px;
    max-height: unset;
    height: auto;
  }
  .mt-lg {
    padding-top: 30px;
  }
  .mt-70 {
    margin-top: 30px;
  }
  .landing_main_head {
    font-size: 1.7em;
    padding: 0;
  }
  .stats_section .stats_wrap {
    padding-right: 0;
  }
  .download_app {
    margin: 50px 30px 0;
  }
  .partners {
    position: relative;
    overflow: hidden;
  }
  .stats_section.rev {
    padding-top: 50px;
  }
  .box_head {
    margin-bottom: 150px;
  }
  .hero_slider .video_sec {
    height: 240px;
  }
  .hero_slider .col-md-12 .l_main_heading {
    min-height: 0;
    text-align: center;
    margin-top: 20px;
    height: auto !important;
  }
  .hero_slider .button_grp {
    align-items: center;
    justify-content: center;
    margin-left: auto;
  }
  .top_sec .fixed_nav .main_nav {
    padding: 5px 15px;
    max-width: 100%;
  }
  .top_sec .fixed_nav .main_nav .navbar-collapse {
    padding-top: 15px;
  }
  .top_sec .fixed_nav .main_nav .nav-item {
    margin-bottom: 10px;
  }
  .top_sec .fixed_nav .main_nav .nav-item .nav-link {
    display: inline-block;
  }
}
@media (max-width: 1441px) {
  .bottom_navs {
    height: 145px;
  }
  .bottom_navs .btm_nav .tab_mover {
    height: 160px;
  }
}
@media (min-height: 1081px) and (max-height: 3000px) {
  .bottom_navs {
    position: relative;
  }
  .top_sec {
    min-height: auto !important;
  }
}

