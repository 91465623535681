.header {
    width: 100%;
    padding: 2%;
    background-color: burlywood;
    color: white;
    text-align: center;
}

.display-board {
    width: 100%;
    background-color: rgb(555, 200, 789);
    padding: 5%;
}

.number {
    color: red;
    font-size: 75px;
    text-align: center;
}

.mrgnbtm {
    margin-top: 20px;
}

.Sidebar {
    height: 100%;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: #97a0ff;
    transition: 0.5s ease;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.HomeRow {
    display: flex;
    flex-direction: row;
}

.visually-hidden {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
}
