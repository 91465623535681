@font-face {
    font-family: 'Montserrat';
    src:  url("../fonts/montserrat/Montserrat-Black.ttf") format('truetype');
    font-weight: 900;
  }
@font-face {
    font-family: 'Montserrat';
    src:  url("../fonts/montserrat/Montserrat-Bold.ttf") format('truetype');
    font-weight: 700;
  }

@font-face {
    font-family: 'Montserrat';
    src:  url("../fonts/montserrat/Montserrat-Regular.ttf") format('truetype');
    font-weight: 400;
  }
@font-face {
    font-family: 'Montserrat';
    src:  url("../fonts/montserrat/Montserrat-ExtraBold.ttf") format('truetype');
    font-weight: 800;
  }
@font-face {
    font-family: 'Montserrat';
    src:  url("../fonts/montserrat/Montserrat-ExtraLight.ttf") format('truetype');
    font-weight: 200;
  }
@font-face {
    font-family: 'Montserrat';
    src:  url("../fonts/montserrat/Montserrat-Light.ttf") format('truetype');
    font-weight: 300;
  }
@font-face {
    font-family: 'Montserrat';
    src:  url("../fonts/montserrat/Montserrat-Medium.ttf") format('truetype');
    font-weight: 500;
  }
@font-face {
    font-family: 'Montserrat';
    src:  url("../fonts/montserrat/Montserrat-SemiBold.ttf") format('truetype');
    font-weight: 600;
  }
@font-face {
    font-family: 'Montserrat';
    src:  url("../fonts/montserrat/Montserrat-Thin.ttf") format('truetype');
    font-weight: 100;
  }