/**** Immersify Web Platform Common Styles******/
@use "fonts_import.scss";

/********* Common **********/
body {
  font-family: "Montserrat", sans-serif;
}

$primary-color: #0077d6;
$hover-color: #5089fa;
$secondary-color: #9a39ea;

/**** Breadcrumb *****/
.breadcrumb_des {
  padding: 5px 15px;
  border-bottom: 1px solid #ebebeb;
  display: flex;
  position: fixed;
  width: 100%;
  z-index: 999;
  align-items: center;
  background: #f7f8fa;

  .back_btn {
    font-size: 1.7em;
    color: #7a7a7a;
    border: none;
    background: none;
    display: inline-block;
  }

  ul {
    margin-bottom: 0;
    padding-left: 5px;

    li {
      list-style: none;
      font-size: 14px;
      margin: 0 7px;
      float: left;

      &::after {
        content: "\f105";
        font: normal normal normal 14px/1 FontAwesome;
        margin-left: 15px;
      }

      &:last-child {
        button {
          font-weight: 600;
          color: #161616;
        }
      }

      &:last-child::after {
        display: none;
      }

      button {
        color: #767676;
        text-align: left;
      }
    }
  }
}

/**** Breadcrumb *****/

.form-check {
  margin-bottom: 5px;

  .form-check-input:checked {
    background-color: $primary-color;
    border-color: $primary-color;
  }

  .form-check-input {
    border-radius: 2px;
    height: 1.3rem;
    width: 1.3rem;
    box-shadow: none;
  }

  label {
    margin-top: 4px;
    margin-left: 5px;
    font-size: 14px;
    color: #919191;
  }
}

.form-group {
  .form-control {
    height: 47px;
    color: #919191;
    font-size: 14px;

    &.name:valid,
    &.email:valid,
    &.pwd:valid {
      border-color: $primary-color;
    }
  }

  .form-control:focus {
    border-color: $primary-color;
    outline: none !important;
    box-shadow: none;
  }

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #919191;
    opacity: 1; /* Firefox */
    font-size: 14px;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #919191;
    font-size: 14px;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #919191;
    font-size: 14px;
  }
}

.dropdown {
  .dropdown-toggle {
    font-size: 14px;
    color: #aeaeae;
  }
}

.btn_underline {
  font-size: 16px;
  color: $primary-color;
  border-bottom: 1px solid $primary-color;

  &:hover {
    color: $hover-color;
    border-color: $hover-color;
  }
}

.btn_grey {
  background: #cbcbcb;
  border: none;
  height: 45px;
  padding: 10px 25px;
  border-radius: 3px;
  color: #fff;
  font-weight: 470;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  background-position: center;
  transition: background 0.8s;
}

.btn_readmore {
  display: inline-block;
  padding: 0;
  color: $primary-color;
}

.btn_common {
  background: $primary-color;
  border: none;
  min-height: 45px;
  padding: 10px 25px;
  border-radius: 3px;
  color: #fff;
  font-weight: 470;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  background-position: center;
  transition: background 0.8s;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &:hover {
    background: $hover-color radial-gradient(circle, transparent 1%, $hover-color 1%) center/14700%;
  }

  &:active {
    background-color: #77a3fa;
    background-size: 100%;
    transition: background 0s;
  }
}

.right-head {
  position: relative;
  z-index: 9;
}

@keyframes pulse-black {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(1, 76, 187, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(82, 1, 187, 0);
  }

  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(1, 76, 187, 0);
  }
}

.pulse {
  animation: pulse-black 2s infinite;
}

.ripple {
  margin: auto;
  background-color: rgba(10, 10, 10, 0);
  width: 130px;
  height: 45px;
  border-radius: 3px;
  display: block;
  animation: ripple 3s linear infinite;
  position: absolute;
  right: 0px;
}

.ripple::before,
.ripple::after {
  content: "";
  animation: inherit;
  animation-delay: 1s;
  position: absolute;
  right: 10px;
  width: 110px;
  height: 45px;
  border-radius: 3px;
  display: block;
  top: 0px;
}

.ripple::after {
  animation-delay: 2.2s;
}

@keyframes ripple {
  0% {
    box-shadow: 0 0 0 0.35rem rgba(151, 193, 255, 0.3);
  }
  100% {
    box-shadow: 0 0 0 3rem rgba(255, 255, 255, 0);
  }
}

.glow-on-hover {
  overflow: visible !important;
}

.glow-on-hover::after {
  content: "";
  width: 30px;
  height: 30px;
  border-radius: 100%;
  border: 6px solid #00ffcb;
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: ring 1.5s infinite;
}

@keyframes ring {
  0% {
    width: 30px;
    height: 30px;
    opacity: 1;
  }
  100% {
    width: 300px;
    height: 300px;
    opacity: 0;
  }
}

.btn_success {
  background: #28ad4e;
  border: none;
  height: 45px;
  padding: 10px 25px;
  border-radius: 3px;
  color: #fff;
  font-weight: 470;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  background-position: center;
  transition: background 0.8s;

  &::after {
    content: "\f00c";
    color: #ffffff;
    font: normal normal normal 18px/1 FontAwesome;
    margin-left: 5px;
  }

  &:hover {
    background: #3ac762 radial-gradient(circle, transparent 1%, #3ac762 1%) center/14700%;
  }

  &:active {
    background-color: #31b155;
    background-size: 100%;
    transition: background 0s;
  }
}

button,
a {
  transition: 0.2s all ease;
  text-decoration: none;
}

.main_area {
  padding-left: 250px;
  padding-top: 96px;
  min-height: 100vh;
  background-color: #f7f8fa;
  transition: 0.4s all ease;
}

.main_area_clean {
  min-height: 100vh;
  background-color: #f7f8fa;
  transition: 0.4s all ease;

  padding-top: 97px;
}

button {
  border: none;
  background: none;
}

.main_topics {
  padding-top: 75px;
  padding-left: 30px;
  padding-right: 30px;
}

.animation_player:-moz-full-screen {
  .full_screen_det {
    position: absolute;
    height: 200px;
    width: 100%;
    bottom: 0;
    z-index: 9;
    transition: 0.2s all ease;
  }

  .controller_sec {
    height: 200px;
    padding-top: 100px;
  }

  .time_slider {
    bottom: 100px;
  }

  &:hover::after {
    visibility: hidden;
    opacity: 0;
  }

  &:hover .controller_sec {
    display: none;
  }

  &:hover .text_exp {
    bottom: 0;
  }

  .controller_sec:hover + .text_exp {
    bottom: 100px !important;
  }

  .full_screen_det:hover + .controller_sec {
    display: flex !important;
  }

  .controller_sec:hover {
    display: flex !important;
    background: linear-gradient(to top, #222222, transparent);
  }
}

.animation_player:-webkit-full-screen {
  .full_screen_det {
    position: absolute;
    height: 200px;
    width: 100%;
    bottom: 0;
    z-index: 9;
    transition: 0.2s all ease;
  }

  .controller_sec {
    height: 200px;
    padding-top: 100px;
  }

  .time_slider {
    bottom: 100px;
  }

  &:hover::after {
    visibility: hidden;
    opacity: 0;
  }

  &:hover .controller_sec {
    display: none;
  }

  &:hover .text_exp {
    bottom: 0;
  }

  .controller_sec:hover + .text_exp {
    bottom: 100px !important;
  }

  .full_screen_det:hover + .controller_sec {
    display: flex !important;
  }

  .controller_sec:hover {
    display: flex !important;
    background: linear-gradient(to top, #222222, transparent);
  }
}

.animation_player:-ms-fullscreen {
  .full_screen_det {
    position: absolute;
    height: 200px;
    width: 100%;
    bottom: 0;
    z-index: 9;
    transition: 0.2s all ease;
  }

  .controller_sec {
    height: 200px;
    padding-top: 100px;
  }

  .time_slider {
    bottom: 100px;
  }

  &:hover::after {
    visibility: hidden;
    opacity: 0;
  }

  &:hover .controller_sec {
    display: none;
  }

  &:hover .text_exp {
    bottom: 0;
  }

  .controller_sec:hover + .text_exp {
    bottom: 100px !important;
  }

  .full_screen_det:hover + .controller_sec {
    display: flex !important;
  }

  .controller_sec:hover {
    display: flex !important;
    background: linear-gradient(to top, #222222, transparent);
  }

  width: 100%; /* needed to center contents in IE */
}

.animation_player:fullscreen {
  .full_screen_det {
    position: absolute;
    height: 200px;
    width: 100%;
    bottom: 0;
    z-index: 9;
    transition: 0.2s all ease;
  }

  .controller_sec {
    height: 200px;
    padding-top: 100px;
  }

  .time_slider {
    bottom: 100px;
  }

  &:hover::after {
    visibility: hidden;
    opacity: 0;
  }

  &:hover .controller_sec {
    display: none;
  }

  &:hover .text_exp {
    bottom: 0;
  }

  .controller_sec:hover + .text_exp {
    bottom: 100px !important;
  }

  .full_screen_det:hover + .controller_sec {
    display: flex !important;
  }

  .controller_sec:hover {
    display: flex !important;
    background: linear-gradient(to top, #222222, transparent);
  }
}

.modal-backdrop {
  z-index: 9999 !important;
}

.modal {
  z-index: 99999 !important;
}

.hide_top_bar {
  padding-top: 0 !important;

  .top_header {
    height: 0;
    padding: 0;
    opacity: 0;
  }
}

/********* Common **********/

/********* Profile **********/

.frgt_pd img {
  height: 140px;
  padding: 10px;
}

.frgt_pd {
  text-align: center;
  padding-bottom: 20px;

  h4 {
    font-size: 18px;
    max-width: 565px;
    padding: 20px 0 10px;
    margin: auto;
    font-weight: 500;
    color: #202020;
    display: block;
  }
}

.confirmation_popup {
  .bottom_btns {
    display: flex;
    justify-content: flex-end;
    padding: 10px;

    button {
      width: 130px;
      margin-left: 10px;
    }
  }

  .confirm_head {
    width: 100%;
    position: absolute;
    height: 7px;
    background: $primary-color;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;

    .cfm_cnt {
      height: 45px;
      width: 45px;
      display: block;
      border-radius: 50%;
      background: $primary-color;
      position: absolute;
      margin: auto;
      left: 0;
      right: 0;
      top: -20px;
      text-align: center;
      padding-top: 8px;
      font-size: 1.2em;
      color: #fff;
    }
  }

  .confirm_block {
    padding: 50px 0 40px 0;

    h4 {
      font-size: 18px;
      color: #202020;
      font-weight: 600;
      text-align: center;
    }
  }

  .modal-dialog {
    margin-top: 180px;
  }

  .modal-header {
    border: none;
  }

  .btn_mod_close {
    right: 0;
  }
}

.cancel_subs {
  position: absolute;
  right: 15px;
  transition: 0.2s all ease;

  i {
    transition: 0.5s all ease;
  }

  .btn-cancel:hover i {
    transform: rotate(30deg);
  }

  .dropdown-toggle::after {
    display: none;
  }

  .dropdown {
    i {
      font-size: 1.5em;
      color: #fff;
    }
  }

  .dropdown-menu {
    font-size: 13px;
    border: none;
    box-shadow: 2px 3px 10px rgb(0 0 0 / 16%);
  }
}


.subsc_sec {
  .bill_explained {
    display: flex;
    align-items: center;

    .bill_yearly {
      margin-left: 15px;
      background: #fff;
      height: 30px;
      display: flex;
      align-items: center;
      padding: 0 10px;
      border-radius: 2px;

      p {
        color: #533acf;
        font-weight: 600;
        font-size: 12px;
      }
    }
  }

  .alert {
    border-radius: 0;
  }

  .alert-success {
    color: #006234;
    background-color: #c5f3d1;
    border-color: #53c988;
    margin-bottom: 0;
  }

  .subscription_det {
    position: relative;
    overflow: hidden;
    background: linear-gradient(to bottom, #084bb7, #a728ea);
    padding: 20px;

    p {
      color: #fff;
      margin-top: 15px;
    }

    hr {
      border-color: #fff;
      opacity: 0.3;
    }

    .subsc_top {
      display: flex;
      align-items: center;

      h4 {
        font-size: 18px;
        font-weight: 600;
        color: #fff;
        text-transform: uppercase;
        margin-bottom: 0;
        margin-left: 10px;
        letter-spacing: 1px;
      }
    }
  }

  .price_val {
    h5 {
      color: #fff;
      font-weight: 600;
      font-size: 2rem;

      span {
        font-size: 1.2rem !important;
        font-weight: 500;
      }
    }

    h6 {
      color: #fff;
      font-weight: 400;
      font-size: 14px;
      margin-bottom: 30px;
    }
  }

  .subs_btm {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .btn_switch {
      color: #fff;
      font-weight: 600;
      margin: 0;
      position: relative;
      transition: 0.2s all ease;

      &:hover,
      &:hover::after {
        color: #f5b9ff;
        border-color: #f5b9ff;
      }

      &::after {
        transition: 0.2s all ease;
        position: absolute;
        content: "";
        border-bottom: 2px solid #fff;
        width: 94%;
        bottom: -2px;
        top: 0;
        left: 7px;
      }
    }

    .price_val {
      h5 {
        font-size: 1.3rem;
        margin-bottom: 0;

        span {
          font-size: 1rem !important;
          font-weight: 500;
        }
      }

      p {
        color: #fff;
        font-weight: 400;
        font-size: 12px;
        opacity: 0.8;
        margin-top: 3px;
        margin-bottom: 0px;
      }
    }
  }
}

.right_profile {
  box-shadow: 2px 3px 10px rgba(0, 0, 0, 0.08);
  background-color: #fff;
  padding: 30px;
  border-radius: 5px;

  .form-group {
    margin-bottom: 15px;
  }

  h3 {
    font-size: 20px;
    font-weight: 700;
    color: #202020;
    margin-bottom: 20px;
  }
  .profile_edit_sec.qr_c h3
  { margin-bottom: 3px;}
  p{
    color: #919191;
  }
}

.subscriptions-page {

  .subscription-tabs {

    .subscription-tab {
      border: none;
      border-bottom: 2px solid lightgray;
      color: gray;

      &.active {
        border-bottom: 2px solid $primary-color;
        color: $primary-color;
        font-weight: 600;
      }
    }

  }
  .product_title{
    display: flex;
    align-items: center;
    .product-card-name{
      margin-left: 10px;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 2px;
      text-transform: uppercase;
    }
  }

  .product-card-header {
    display: flex;
    justify-content: space-between;

    .product-card-price{
      font-size: 20px;
      font-weight: 800;
      color: #2A1D47;
    }
}

  .product-cards {
    display: grid;
    grid-template-columns: 1fr 1fr;

    padding: 1em;
    gap: 1em;

    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }


    .product-card {
      border: 1px solid #D6DEE7;
      border-radius: 8px;
      padding: .8rem;

      flex-grow: 1;

      .product-card-feature p{
        font-size: 16px;
        font-weight: 500;
      }

      .product-card-body{
        padding-left: 30px;
        padding-top: 5px;
      }

      .product-card-daily-activities{
        
        h4{
          font-size: 14px;
          font-weight: 600;
          background: #7521B9;
          padding: 10px 15px;
          border-radius: 26px;
          color: #fff;
          display: inline-block;
      }
      

      .product-card-inner {
        display: flex;
        flex-direction: column;
        font-weight: 600;

        .product-card-header {
          display: flex;
          align-content: center;
          align-items: center;
          text-align: center;
          margin-bottom: .3rem;


          .product-card-name {

            font-size: 1.2rem;
            text-transform: uppercase;
            letter-spacing: .2rem;
            margin-left: .5rem;
          }

          .product-card-active {
            font-size: small;
            background-color: gold;
            padding: .2rem;
            margin-left: .2rem;
            border-radius: 5px;
            text-align: center;
            align-self: start;
          }

          .product-card-price {
            margin-left: auto;
          }
        }

      }
    }
  }
}
  .subscription-actions {
    display: flex;
    gap: 1em;
    justify-content: right;
    margin: 10px 15px;
    align-items: center;
    text-align: center;

    

    .subscriptions-actions-wrong-platform-message {
      border: solid 1px red;
      width: 50%;

      border-radius: 1rem;
      padding: 1rem;
      margin-right: auto;
    }

  }


}
.profile_edit_sec {
  .profile_img {
    text-align: center;
    margin: 30px auto;
    position: relative;
    background: #eaf1ff;
    border-radius: 50%;
    padding: 20px;
    height: 120px;
    width: 120px;

    img {
      height: 80px;
      object-fit: contain;
    }

    

    .edit_pic {
      position: absolute;
      bottom: 0;
      right: 5px;
      background: $primary-color;
      border-radius: 50%;
      color: #fff;
      width: 30px;
      height: 30px;
      text-align: center;
      padding-left: 9px;
      padding-top: 3px;
    }
  }
}

.profile_page {
  margin-top: 15px;
  max-width: 1200px;
}

.left_settings {
  margin-bottom: 15px;
  background: #fff;
  border: 1px solid #e2e2e2;
  border-radius: 7px;

  ul {
    padding: 15px 20px 15px 15px;
    margin-bottom: 0;

    li {
      list-style: none;
      margin-bottom: 15px;
      position: relative;

      &.active::after {
        content: "\f105";
        font: normal normal normal 22px/1 FontAwesome;
        position: absolute;
        font-weight: 600;
        margin-top: 2px;
        right: 0;
        color: $primary-color;
      }

      &:hover button {
        color: $primary-color;
      }

      &.active {
        button {
          color: $primary-color;
          font-weight: 600;
        }
      }

      button {
        color: #717171;
        font-weight: 400;
        transition: 0.2s all ease;
        text-align: left;

        i {
          font-weight: 600;
          width: 18px;
          text-align: center;
          height: 18px;
          margin-right: 7px;
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

/********* Profile **********/

/*** Top header ****/
.top_header {
  width: auto;
  height: 97px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  box-shadow: 2px 3px 10px rgb(0 0 0 / 8%);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 9999;
  transition: 0.4s all ease;
  padding-left: 30px;
  padding-top: 15px;
  padding-bottom: 15px;

  h2 {
    float: left;
    font-size: 18px;
    font-weight: 600;
    color: $primary-color;
    margin-bottom: 0;
    margin-top: 20px;
  }

  .search_n_profile {
    display: block;
    width: 100%;
  }

  .user_img {
    height: 53px;
    width: 53px;
    border-radius: 50%;
    padding: 9px;
    background: #eaf1ff;

    img {
      height: 35px;
      object-fit: contain;
    }
  }

  .account_area {
    ul li {
      padding: 5px 0;
    }

    .dropdown-toggle {
      display: flex;
      align-items: center;

      &::after {
        border-top: 0.5em solid;
        border-right: 0.45em solid transparent;
        border-bottom: 0;
        border-left: 0.45em solid transparent;
        color: #afafaf;
      }

      .usr_name {
        font-size: 16px;
        font-weight: 500;
        color: #202020;
        margin-right: 7px;
      }
    }

    .dropdown-menu {
      border: none;
      padding: 10px;
      box-shadow: 2px 3px 10px rgb(0 0 0 / 16%);
    }
  }

  .account_area a,
  .account_area button {
    padding-right: 30px;
    display: flex;
    align-items: center;
    justify-content: normal;
    color: #3d3d3d;
    font-weight: 500;
    font-size: 16px;

    &:hover {
      background: none;
      color: $primary-color;
    }

    i {
      margin-right: 10px;
      height: 15px;
      width: 15px;
    }

    .usr_name {
      padding-right: 5px;
      font-weight: 500;
    }
  }
}

.search_result {
  position: absolute;
  background: #fff;
  display: block;
  width: 100%;
  z-index: 999;
  box-shadow: 2px 3px 10px rgb(0 0 0 / 16%);

  ul {
    padding: 15px 15px 0 15px;

    li {
      list-style: none;
      padding: 10px 0;
      border-bottom: 1px solid #ebebeb;
      transition: 0.2s all ease;

      &:last-child {
        border: none;
      }

      &:hover {
        background-color: #f5f5f5;
      }

      button {
        width: 100%;
        display: flex;

        .actvt_blk {
          text-align: left;
          padding-left: 10px;

          h3 {
            font-size: 15px;
            font-weight: 600;
            color: #202020;
            margin-bottom: 0;
          }

          h4 {
            font-size: 12px;
            font-weight: 500;
            color: #7a7a7a;
          }
        }
      }

      img {
        height: 35px;
      }
    }
  }
}

.account_area {
  float: right;
}

.search_box {
  width: 60%;
  position: relative;
  float: left;
  bottom: 10px;

  .form-control {
    height: 48px;
    box-shadow: none !important;
    outline: none;
    color: #3e3e3e;
    font-size: 16px;
  }

  button.search_sub {
    position: absolute;
    top: 0px;
    background: none;
    right: 0px;
    width: 60px;
    border: none;
    outline: none;
    height: 50px;

    i {
      color: #3e3e3e;
      font-size: 23px;
    }
  }

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #3e3e3e;
    opacity: 1; /* Firefox */
    font-size: 16px;
    font-weight: 600;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #3e3e3e;
    font-size: 16px;
    font-weight: 600;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #3e3e3e;
    font-weight: 600;
    font-size: 16px;
  }
}

.short_cut_links {
  margin-top: 5px;
  z-index: 99;
  position: absolute;
  bottom: 12px;

  ul {
    padding-left: 0px;
    margin-bottom: 0;

    li {
      list-style: none;
      float: left;
      margin-right: 10px;

      button {
        color: #161616;
        font-weight: 500;
        border: 1px solid #e7e9ec;
        font-size: 12px;
        border-radius: 20px;
        background-color: #f2f7fd;
        padding: 5px 10px;

        &:hover {
          background-color: $primary-color;
          color: #fff;
          border-color: $primary-color;
        }
      }
    }
  }
}

/*** Top header ****/

/***** Loader CSS ****/
.diamond_1 {
  height: 32px;
  width: 32px;
  top: 80px;
  left: 92px;
  position: absolute;
  background: url(../images/diamond.svg) no-repeat center;
  background-size: contain;
}

.diamond_2 {
  height: 32px;
  width: 32px;
  top: 103px;
  left: 135px;
  position: absolute;
  background: url(../images/diamond.svg) no-repeat center;
  background-size: contain;
}

.sparkles {
  z-index: 2;
  position: absolute;
}

.one {
  right: -114px;
  top: -15px;
  position: absolute;
  font-size: 40px;
  color: transparent;
  text-shadow: 0 0 white, -115px -75px white;
  animation: shine 0.5s linear infinite;

  img {
    height: 30px;
  }
}

.two {
  top: 22px;
  position: absolute;
  color: transparent;
  font-size: 25px;
  left: 127px;
  text-shadow: 0 0 white, 55px -115px white;
  animation: shine 0.5s linear infinite 0.25s;

  img {
    height: 30px;
  }
}

@keyframes shine {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.dot-one {
  animation: dot-one 2s infinite linear;
}

.dot-two {
  animation: dot-two 2s infinite linear;
}

.dot-three {
  animation: dot-three 2s infinite linear;
}

@keyframes dot-one {
  0% {
    opacity: 0;
  }
  15% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes dot-two {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes dot-three {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@media (max-width: 700px) {
  .loading__letter {
    font-size: 50px;
  }
}

@media (max-width: 340px) {
  .loading__letter {
    font-size: 40px;
  }
}

.content_loader {
  background: #383838;
  background-size: 400% 400%;
  // animation: gradient 15s ease infinite;
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  opacity: 1;
  z-index: 9;

  .loading_txts {
    position: absolute;
    z-index: 99;
    color: #fff;
    bottom: 20%;
    font-size: 18px;
    font-weight: 500;

    span {
      font-size: 18px;
    }
  }

  .start_anim {
    height: 70px;
    width: 70px;
    background-color: transparent !important;
    background-size: 50px !important;
    bottom: 0px;
    position: relative;
  }

  .progress {
    max-width: 150px;
    top: 60px;
    margin: auto;
    background: rgba(255, 255, 255, 0.2);

    .bar {
      background: linear-gradient(-45deg, #a728ea, #3140c7, #a240cb, #249eff);
      animation: gradient 5s ease infinite;
      background-size: 400% 400%;
    }

    @keyframes gradient {
      0% {
        background-position: 0% 50%;
      }
      50% {
        background-position: 100% 50%;
      }
      100% {
        background-position: 0% 50%;
      }
    }
  }
}

/***** Loader CSS ****/

/*******  Spnosor ***********/

.title_block_m {
  .sponsor_tag {
    margin-top: 0;
  }
}

.sponsor_tag {
  position: relative;
  height: 22px;
  margin-top: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;

  img {
    height: 24px !important;
    object-fit: contain;
    margin-left: 7px;
    margin-right: 4px;
  }

  h5 {
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 0;
  }
}

/*******  Spnosor ***********/

/************** Verify Email ************/
.verify_email {
  .modal-dialog {
    margin-top: 100px;
  }

  .modal-header {
    border: none;

    .btn_mod_close {
      position: absolute;
      right: 20px;
      top: 25px;
      z-index: 99;
    }
  }

  .modal-body {
    text-align: center;

    .fgt_header {
      margin-top: 65px;
      padding: 30px;

      h5 {
        font-size: 24px;
        font-weight: 600;
      }

      span {
        color: $primary-color;
        font-weight: 600;
      }

      p {
        color: #686868;
        margin-bottom: 25px;
      }

      h6 {
        font-size: 14px;
        color: #878787;
        font-weight: 400;
        margin-bottom: 25px;
      }
    }
  }
}

.verify_m {
  .verify_sec {
    max-width: 450px;
    margin-top: 20%;

    h2 {
      text-align: center;
    }

    .verify_txt_m {
      font-size: 16px;
      margin-bottom: 10px;
      font-weight: 400;
      color: #747474;
      text-align: center;
    }

    .verify_em_t2 {
      font-size: 18px;
      font-weight: 500;
      color: #333333;
      text-align: center;
    }

    .em_img_block {
      position: relative;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 20px;

      img {
        height: 300px;
      }
    }
  }
}

/************** Verify Email ************/

/****** Signup - Login *********/

.reports_sec {
  display: flex;
  width: 100%;
  min-height: 500px;

  iframe{
    width: 100%;
  }
}

.start_anim {
  background-image: url(../images/loader3.gif) !important;
  background-size: 40px !important;
  background-position: 0 center !important;
  background-repeat: no-repeat !important;
  background-color: #d3e2ff !important;
  font-size: 0;
  cursor: not-allowed;
  pointer-events: none;
}

.start_anim2 {
  background-image: url(../images/logo_anim.gif) !important;
  background-size: 40px !important;
  background-position: center center !important;
  background-repeat: no-repeat !important;
  background-color: #d3e2ff !important;
  font-size: 0;
  cursor: not-allowed;
  pointer-events: none;
}

.left_block {
  padding: 0;
}

.signup_image {
  min-height: 100vh;
  height: 100%;
  background: url(../images/bg_login.png) no-repeat center center;
  background-size: cover;
  position: relative;
}

.logo_wrap {
  display: inline-block;
  width: 100%;
  position: relative;
  text-align: center;
  padding: 40px 0 0;

  img:hover {
    cursor: pointer;
  }
}

.shape_block {
  position: relative;
  padding-top: 50px;

  .shape_1 {
    float: right;
    display: block;
    margin-right: 147px;

    img {
      height: 15em;
    }
  }

  .shape_2 {
    right: 355px;
    position: absolute;
    margin-top: 215px;

    img {
      height: 7em;
    }
  }
}

.login_text_block {
  position: absolute;
  bottom: 80px;
  left: 80px;

  h1 {
    font-size: 3rem;
    font-weight: 800;
    letter-spacing: 3px;
    color: #3d3d3d;
  }
}

.signup_form_area {
  max-width: 600px;
  margin: 10% auto 0;
  padding: 0 30px;
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: center;

  h3 {
    font-size: 18px;
    color: #7d7d7d;
    font-weight: 400;
    margin-bottom: 0;
  }

  h2 {
    font-size: 28px;
    font-weight: 700;
    color: $primary-color;
  }

  .form-group {
    margin-top: 30px;

    .form-field {
      margin-bottom: 15px;
      position: relative;
    }
  }
}

span.error_txt {
  color: #f94545;
  font-size: 13px;
  font-weight: 500;
  padding-bottom: 1px;
}

.login_check {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.check_area {
  margin-top: 10px;
}

.btn_area {
  margin-top: 30px;
}

.other_signup {
  margin-top: 40px;

  .sign_in_header {
    text-align: center;
    position: relative;
    max-width: 250px;
    margin: auto;

    &:before {
      content: "";
      position: absolute;
      border: 1px solid #ebebeb;
      width: 60px;
      left: 0;
      bottom: 7px;
    }

    &:after {
      content: "";
      position: absolute;
      border: 1px solid #ebebeb;
      width: 60px;
      right: 0;
      bottom: 7px;
    }

    h5 {
      font-size: 14px;
      font-weight: 400;
      color: #8f8f8f;
    }
  }

  .signup_options {
    text-align: center;
    margin-top: 30px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    .kep-login-facebook {
      padding: 0 15px;
      height: 53px;
      border-radius: 4px !important;
      margin-top: 4px;
      width: 300px;
      text-transform: initial;
      font-weight: 400;
      font-family: "Montserrat", sans-serif !important;
      font-size: 16px;
    }

    .btn_login {
      text-decoration: none;
      border-radius: 4px;
      height: 52px;
      width: 300px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin: 8px;

      &.google {
        border: 2px solid #e6e6e6;
        color: #000000;
        background: #ffffff;

        img {
          transition: 0.2s all ease;
          position: relative;
          bottom: 0px;
          height: 25px;
          margin-right: 8px;
        }

        &:hover {
          background-color: #f5f5f5;
        }
      }

      &.apple {
        border: 2px solid #202020;
        color: #fff;
        background: #202020;

        img {
          transition: 0.2s all ease;
          position: relative;
          bottom: 0px;
          height: 25px;
          margin-right: 8px;
        }

        &:hover {
          background-color: #383838;
        }
      }
    }
  }

  h4 {
    margin-top: 50px;
    font-size: 16px;
    text-align: center;

    a {
      color: $primary-color;

      &:hover {
        color: #5484e6;
      }
    }
  }
}

.border_color {
  border-color: $primary-color;
}

.forgot_password {
  .modal-header {
    border: none;
  }

  .modal-body {
    max-width: 400px;
    margin: auto;
    width: 100%;
  }

  .fgt_header {
    display: block;
    text-align: center;
    width: 100%;

    h5 {
      font-size: 24px;
      color: #202020;
      margin-top: 15px;
      font-weight: 700;
    }

    p {
      color: #8a8a8a;
      font-size: 16px;
    }
  }

  .btn_mod_close {
    background: none !important;
    outline: none !important;
    border: none;
    position: absolute;
    right: 30px;
    top: 30px;
  }

  .modal-dialog {
    max-width: 450px;
    margin-top: 150px;
  }

  .modal-content {
    border: none;
    padding: 30px 0;
  }
}

.switch_signup {
  background: none;
  color: $primary-color;
  border: none;
  font-weight: 500;
  cursor: pointer;
}

/****** Signup - Login *********/

/***** Error Page ****/
.mx-50 {
  max-width: 140px;
  margin: 15px auto;
}

.error_block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 250px;

  h3 {
    text-align: center;
    font-size: 26px;
    font-weight: bold;
    color: #202020;
    margin-top: 30px;
  }

  h2 {
    text-align: center;
    font-size: 26px;
    font-weight: bold;
    color: #28ad4e;
    margin-top: 20px;
  }

  p {
    font-size: 16px;

    color: #7c7c7c;
    text-align: center;
  }

  .error_txt {
    text-align: center;
  }
}

/***** Error Page ****/

/******** Left Menu *********/
.menu_collapse {
  position: fixed;
  left: 22px;
  height: 30px;
  width: 40px;
  top: 40px;
  z-index: 99;

  &:focus-visible {
    outline: none;
  }

  i {
    font-size: 20px;
    color: #333333;
  }
}

.left_menu {
  background: #fff;
  border-right: 1px solid #e6e6e6;
  min-height: 100vh;
  height: 100%;
  position: fixed;
  width: 250px;
  left: 0;
  top: 0;
  transition: 0.4s all ease;
}

.prem_usr {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 68px;
  margin-left: auto;
  position: relative;
  height: 18px;
  bottom: 5px;
  font-size: 9px;
  color: #fff;
  font-weight: 600;
  letter-spacing: 1px;
  border-radius: 30px;
  background: linear-gradient(to right, $primary-color, $secondary-color);
}

.menu_shrink {
  padding-left: 75px;

  .left_menu {
    width: 75px;
  }

  .top_header {
    left: 75px;
  }

  .menu_sec ul li a {
    opacity: 0;
  }

  .logo_sec span {
    opacity: 0;
  }

  .logo_sec img {
    opacity: 0;
  }
}

.common_acc {
  max-width: 155px;
  margin-top: 17px;
}

.av_in_app {
  .coming_soon {
    background: linear-gradient(to top, #3063cc, #6497ff);
  }

  p {
    margin-bottom: 20px !important;
  }
}

.logo_sec {
  padding: 15px 25px;
  width: 100%;
  text-align: right;
  position: relative;

  &:hover {
    cursor: pointer;
  }

  img {
    max-height: 70px;
  }
}

.menu_sec {
  margin-top: 30px;

  ul {
    padding-left: 13px;

    li {
      list-style: none;
      margin-bottom: 10px;
      padding: 15px 20px;
      border-top-left-radius: 30px;
      border-bottom-left-radius: 30px;
      position: relative;

      .icon-premium:before {
        content: "";
        background: url(../images/icons/premium.svg) no-repeat center;
        height: 25px;
        display: inline-block;
        width: 25px;
        position: relative;
        top: 4px;
        filter: grayscale(1);
        opacity: 0.7;
        transition: 0.2s all ease;
      }

      .link_cl {
        color: #7d7c7c;
        font-weight: 500;

        a {
          color: #7d7c7c;
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          right: 0;
          top: 0;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          padding-left: 53px;
        }

        i {
          padding-right: 18px;
          font-size: 20px;
          transition: 0.2s all ease;
        }

        &:hover a,
        &:hover i,
        &:hover .icon-premium:before {
          color: $primary-color;
          filter: none;
        }
      }
    }

    li.active {
      background-color: #e8efff;

      i {
        color: $primary-color;
      }

      .icon-premium:before {
        filter: none;
        opacity: 1;
      }

      a {
        color: $primary-color;
      }
    }
  }
}

/******** Left Menu *********/

/******** MCQ Modal ********/
.progz {
  position: absolute;
  width: 100%;
  height: 4px;
  left: 0;
  bottom: 0;
  background: #ccddff;
  z-index: 0;

  .progz_slide {
    background: #5f93ff;
    height: 100%;
  }
}

.mcq_end {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 0;

  h4 {
    margin-top: 30px;
    font-weight: 600;
  }

  .score_txt {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #edf2fb;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;

    h3 {
      font-size: 20px;

      span {
        font-size: 40px;
        font-weight: 700;
      }
    }
  }

  .show_mcq {
    padding-top: 30px;
  }
}

.quiz_modal {
  .btn_mod_close {
    position: absolute;
    right: 25px;
    top: 25px;
  }

  .modal-dialog {
    margin-top: 100px;
    max-width: 700px;
  }

  .fgt_header {
    width: 100%;
    position: relative;
  }

  .modal-header {
    background: #e8eff9;
    padding: 30px;
    border: none;
    position: relative;

    h5 {
      font-size: 20px;
      font-weight: 600;
      color: $primary-color;
      margin-bottom: 4px;
    }

    h6 {
      font-size: 13px;
      font-weight: 500;
      color: #7c8da4;
    }

    .mcq_progress {
      width: 93%;
      margin-top: 15px;
      position: relative;
      display: inline-block;
      margin-left: 3px;

      .progress {
        background: #fff;
        margin: 0;
        width: 100%;
      }
    }

    .quiz_num {
      position: absolute;
      right: 0px;
      top: 51px;
      font-size: 17px;
      font-weight: 600;
      letter-spacing: 1px;
    }
  }

  .modal-body {
    background: #f8f9fc;
  }

  .mcq_data {
    padding: 20px;

    h4 {
      font-size: 18px;
      color: #202020;
      font-weight: 700;
    }

    ul {
      padding: 0;
      margin-top: 40px;

      li {
        list-style: none;

        button {
          width: 100%;
          min-height: 75px;
          background: #fff;
          box-shadow: 2px 3px 10px rgba(0, 0, 0, 0.08);
          border-radius: 5px;
          display: flex;
          align-items: center;
          list-style: none;
          padding: 15px 20px;
          margin: 15px 0;
          transition: 0.2s all ease;
          position: relative;

          p {
            margin-bottom: 0;
            text-align: left;
            line-height: 22px;
            padding-right: 20px;
            font-weight: 600;
          }

          &:hover {
            background: #d7e1ff;
          }
        }
      }

      .right_ans button {
        background-color: #bdffdf !important;

        &:after {
          content: "\f00c";
          font: normal normal normal 14px/1 FontAwesome;
          font-size: 1.5em;
          position: absolute;
          right: 20px;
          color: #38af75;
        }
      }

      .wrong_ans button {
        background-color: #ffcfcf !important;

        &:after {
          content: "\f00d";
          font: normal normal normal 14px/1 FontAwesome;
          font-size: 1.5em;
          position: absolute;
          right: 20px;
          color: #dd6d6d;
        }
      }
    }
  }

  .mcq_submit {
    margin: 20px;
    padding-top: 30px;
    border-top: 1px solid #ddd;
    text-align: right;

    .btn_common {
      max-width: 130px;
    }
  }
}

/******** MCQ Modal ********/

/******** Premium Content Modal ********/

.prm_content_popup {
  .modal-content {
    overflow: hidden;
  }

  .modal-dialog {
    margin-top: 100px;
    max-width: 820px;
  }

  .btn_mod_close {
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 9;
  }

  .modal-body {
    padding: 0;
  }

  .prm_wrap {
    display: flex;
    align-items: flex-start;
    height: auto;
  }

  .prm_left {
    width: 70%;
    padding: 35px;

    p {
      line-height: 22px;
    }
  }

  .prm_left_header {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    position: relative;

    img {
      height: 60px;
    }

    .title_block_m {
      margin-left: 15px;
    }

    h2 {
      font-size: 20px;
      font-weight: 700;
      color: #202020;
    }
  }

  .exp_video_sec {
    display: flex;
    align-items: center;
    padding: 25px;
    background: #f0f8ff;
    justify-content: space-between;

    .exp_vid {
      background: url(../images/mobile_frame.png) no-repeat left center;
      height: 340px;
      border-radius: 22px;
      box-shadow: 16px 16px 35px rgb(0 0 0 / 35%);
      background-size: cover;
      width: 165px;

      img {
        height: 360px;
        object-fit: contain;
        position: relative;
        padding: 30px 0px;
        left: 11px;
      }
    }

    .exp_txt {
      text-align: center;
      max-width: 230px;
      margin-left: 5px;

      h2 {
        background-color: $primary-color;
        padding: 4px 7px;
        margin: 15px 0;
        text-align: center;
        font-weight: 500;
        font-size: 11px;
        color: #fff;
        display: inline-block;
        letter-spacing: 2px;
        text-transform: uppercase;
      }

      h3 {
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 20px;
        color: #202020;
      }
    }
  }

  .prm_right {
    height: 100%;
    display: flex;
    width: 30%;
    right: 0;
    position: absolute;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: linear-gradient(to bottom, #d7e3ff, #f2e6f9);

    h2,
    .play_link {
      font-size: 12px;
      font-weight: 700;
      text-transform: uppercase;
      text-align: center;
      letter-spacing: 1px;
      color: $primary-color;
      margin-bottom: 20px;
      display: block;
    }

    .qr_code {
      .qr_block {
        background: #fff;
        padding: 5px;
        border: 1px solid #484747;
        text-align: center;
        height: 150px;
        width: 150px;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    h5 {
      font-size: 12px;
      text-align: left;
      max-width: 150px;
      margin-top: 5px;
    }

    .play_store {
      display: inline-flex;
      flex-direction: column;
      margin-top: 30px;

      a {
        margin-bottom: 10px;

        img {
          height: 43px;
          object-fit: contain;
        }
      }
    }
  }
}

.btn_premium {
  height: 40px;
  width: 165px;
  display: inline-flex;
  border-radius: 30px;
  background: linear-gradient(to bottom, #084bb7, #a728ea);
  color: #fff;
  font-weight: 600;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin: auto;
  transition: 0.4s all ease;

  &:hover {
    background: linear-gradient(to bottom, #a728ea, #084bb7);
    color: #fff;
  }
}

/******** Premium Content Modal ********/

/*********** Standard LEssons ****/
@keyframes hideBlock {
  to {
    visibility: hidden;
  }
}

@keyframes hideControls {
  to {
    opacity: 0;
  }
}

.pan_info {
  position: absolute;
  right: 15px;
  bottom: 15px;
  width: 25px;
  text-align: center;
  border-radius: 50%;
  padding: 1px;
  height: 25px;
  background: #ffffff;
  border: 1px solid #ddd;

  &:hover .tooltip_pan {
    visibility: visible !important;
  }

  .tooltip_pan {
    position: absolute;
    background: #fff;
    box-shadow: 6px 9px 25px rgb(0 0 0 / 16%);
    font-size: 14px;
    width: 300px;
    height: 50px;
    font-weight: 500;
    bottom: 40px;
    right: 15px;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.2s all ease-in-out;
    -moz-animation: hideBlock 0s ease-in 5s forwards;
    /* Firefox */
    -webkit-animation: hideBlock 0s ease-in 5s forwards;
    /* Safari and Chrome */
    -o-animation: hideBlock 0s ease-in 5s forwards;
    /* Opera */
    animation: hideBlock 0s ease-in 5s forwards;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;

    &:after {
      width: 0px;
      height: 0px;
      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-top: 27px solid #fff;
      content: "";
      position: absolute;
      right: -3px;
      bottom: -15px;
      transform: rotate(336deg);
    }

    img {
      height: 20px;
      width: 20px;
      margin: 0 10px 0 0 !important;
    }

    p {
      margin: 0;
    }
  }
}

.btn_outline {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
  border: 1px solid $primary-color;
  color: $primary-color;
  border-radius: 3px;
  font-weight: 500;
  height: 45px;
  transition: 0.2s all ease;

  &:hover {
    background-color: $primary-color;
    color: #fff;
  }
}

.show_mcq {
  display: flex;
  width: 100%;
  justify-content: center;

  button {
    margin: 0 7px;
    width: 120px;
  }
}

.std_lessons::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.std_lessons::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

.std_lessons::-webkit-scrollbar-thumb {
  background-color: #7e899f;
}

.img_box {
  width: 100%;
}

.std_lessons {
  display: flex;
  align-items: flex-start;
  width: 100%;
  background: #fff;
  flex-direction: column;
  margin: 20px 0 20px 0;
  height: 600px;
  // overflow-y: auto;

  .std_img_block {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 20px;
    width: 100%;

    .anim_video {
      position: relative;

      button {
        position: absolute;
        z-index: 9;
        top: 15px;
        right: 15px;
      }
    }

    .anim_video > div {
      height: 300px;
    }

    img {
      margin: auto;
      max-height: 300px;
      object-fit: contain;
    }
  }

  .std_text_block {
    padding: 0px 20px 20px;
    width: 100%;
    overflow-y: auto;

    h4 {
      margin-bottom: 15px;
      font-size: 20px;
      color: #202020;
      font-weight: 700;
    }
  }
}

.std_wrap {
  position: relative;

  .slick-next,
  .slick-prev {
    top: unset;
    bottom: -65px;
    height: 50px;
    width: 45px;
  }

  .slick-next:before {
    content: "\f105";
  }

  .slick-prev:before {
    content: "\f104";
  }

  .slick-prev {
    left: 0;
    right: 135px;
    margin: auto;
  }

  .slick-next {
    left: 125px;
    right: 0;
    margin: auto;
  }

  .slick-next:before,
  .slick-prev:before {
    height: 50px;
    width: 50px;
    background: #fff;
    border-radius: 2px;
    color: $primary-color;
    box-shadow: 2px 3px 10px rgba(0, 0, 0, 0.08);
    display: block;
    text-align: center;
    font: normal normal normal 45px/1 FontAwesome;
  }

  .slider_num {
    display: flex;
    align-items: center;
    justify-content: center;

    h3 {
      font-size: 19px;
      font-weight: bold;
      margin: 5px;
      text-align: center;
    }
  }

  .std_lesson_progress {
    position: relative;

    .progress {
      margin: auto;
      width: 100%;
      bottom: 20px;
      height: 6px;
      border-radius: 0;
      overflow: visible;
      transition: 0.2s all ease;

      &:hover {
        height: 9px;
      }

      &:hover .bar::after {
        opacity: 1;
      }

      .bar {
        z-index: 999;
        transition: 0.2s all ease;
        pointer-events: none;

        &:after {
          position: absolute;
          height: 18px;
          width: 18px;
          background: #3063cc;
          border-radius: 50%;
          content: "";
          right: -2px;
          bottom: -4px;
          z-index: 9;
          opacity: 0;
          transition: 0.2s all ease;
        }
      }
    }
  }
}

.activity_header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .left-head {
    display: flex;
    align-items: center;
    padding-right: 5px;
  }

  .right-head {
    .btn_common,
    .btn_grey {
      position: relative;
      overflow: hidden;

      i {
        padding-left: 5px;
      }

      .quiz_label {
        white-space: nowrap;
      }
    }
  }

  img {
    height: 60px;
  }

  h2 {
    font-size: 24px;
    color: #202020;
    font-weight: bold;
    margin-left: 15px;
  }
}

.skel_box {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
}

.skeleton-box {
  display: inline-flex;
  position: relative;
  overflow: hidden;
  background-color: #dfdfdf;
  margin-bottom: 10px;
}

.bx1 {
  width: 25em;
  height: 17em;
}

.bx2 {
  width: 45em;
  height: 1.5em;
}

.bx3 {
  width: 35em;
  height: 1em;
}

.skeleton-box::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(
                  90deg,
                  rgba(255, 255, 255, 0) 0,
                  rgba(255, 255, 255, 0.2) 20%,
                  rgba(255, 255, 255, 0.5) 60%,
                  rgba(255, 255, 255, 0)
  );
  -webkit-animation: shimmer 2s infinite;
  animation: shimmer 2s infinite;
  content: "";
}

@-webkit-keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

/*********** Standard LEssons ****/

/************ Offer Section*********/

.offer_popup {
  .modal-content {
    background: #eaf0fa;
    padding: 20px;
    margin-top: 150px;
    border-radius: 0;
  }

  .btn_mod_close {
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 99;
  }

  .modal-header {
    padding: 0;
    border: none;
  }

  .offer_block {
    .offer_head {
      h3 {
        font-size: 20px;
        position: relative;
        margin-top: 10px;
        color: #161616;

        &::after {
          position: absolute;
          width: 54px;
          height: 5px;
          border-radius: 10px;
          content: "";
          left: 0;
          top: -10px;
          background: $secondary-color;
        }
      }
    }

    .offer_sec {
      margin-top: 20px;

      .off_nj {
        font-size: 60px;
        font-weight: 700;
        color: #161616;
        padding-left: 70px;
      }

      h1 {
        font-size: 60px;
        font-weight: 700;
        color: #161616;

        .slash_rt {
          font-size: 50px;
          transform: rotate(16deg);
          position: absolute;
          top: 78px;
          margin-left: 7px;
        }
      }

      h5 {
        color: #161616;
        font-size: 16px;
      }
    }

    .offer_details {
      display: flex;
      margin-top: 50px;

      .off_opt {
        width: 50%;
        position: relative;

        .bill_yearly {
          background: white;
          padding: 3px 5px;

          h6 {
            font-size: 0.64rem;
            font-weight: 600;
            margin-bottom: 0;
            text-align: center;

            span {
              margin-left: 3px;
              text-decoration: line-through;
            }
          }
        }

        .per_mo {
          font-size: 14px;
          display: inline-block;
          margin-bottom: 0;
          padding-left: 5px;
          margin-top: 4px;
        }

        h5 {
          font-size: 16px;
          color: #161616;
          text-transform: uppercase;
          letter-spacing: 2px;
        }

        h2 {
          font-size: 28px;
          font-weight: 700;
          color: $secondary-color;
          position: relative;
          display: flex;
          align-items: center;

          span {
            font-size: 16px;
            font-weight: 500;
            text-decoration: line-through;
            padding-left: 15px;
          }
        }

        &:nth-child(2) {
          padding-left: 28px;
          border-left: 1px solid #161616;
        }
      }
    }

    .off_btn_blk {
      margin-top: 50px;
      display: block;

      .btn_exit {
        margin: auto;
        display: block;
        margin-bottom: 10px;
        color: #979797;
        font-weight: 300;
      }

      .btn_grab {
        display: inline-flex;
        width: 100%;
        border: 1px solid #161616;
        height: 60px;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-weight: 500;
        transition: 0.2s all ease;

        &:hover {
          background: $secondary-color;
          border-color: $secondary-color;
          color: #fff;
        }
      }
    }
  }
}

/************ Offer Section*********/

/************ Time Slider for Animated **************/

.time_slider {
  display: flex;
  justify-content: center;

  input[type="range"] {
    font-size: 1.15rem;
    width: 12.5em;
  }

  input[type="range"] {
    color: $primary-color;
    --thumb-height: 1.125em;
    --track-height: 0.125em;
    --track-color: rgba(0, 0, 0, 0.2);
    --brightness-hover: 120%;
    --brightness-down: 100%;
    --clip-edges: 0.125em;
  }

  /* === range commons === */
  input[type="range"] {
    position: relative;
    background: #fff0;
    overflow: hidden;
  }

  input[type="range"]:active {
    cursor: grabbing;
  }

  input[type="range"]:disabled {
    filter: grayscale(1);
    opacity: 0.3;
    cursor: not-allowed;
  }

  /* === WebKit specific styles === */
  input[type="range"],
  input[type="range"]::-webkit-slider-runnable-track,
  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    transition: all ease 100ms;
    height: var(--thumb-height);
  }

  input[type="range"]::-webkit-slider-runnable-track,
  input[type="range"]::-webkit-slider-thumb {
    position: relative;
  }

  input[type="range"]::-webkit-slider-thumb {
    --thumb-radius: calc((var(--thumb-height) * 0.2) - 1px);
    --clip-top: calc((var(--thumb-height) - var(--track-height)) * 0.5 - 0.5px);
    --clip-bottom: calc(var(--thumb-height) - var(--clip-top));
    --clip-further: calc(100% + 1px);
    --box-fill: calc(-100vmax - var(--thumb-width, var(--thumb-height))) 0 0 100vmax currentColor;

    width: var(--thumb-width, var(--thumb-height));
    background: linear-gradient(currentColor 0 0) scroll no-repeat left center / 50% calc(var(--track-height) + 1px);
    background-color: currentColor;
    box-shadow: var(--box-fill);
    border-radius: var(--thumb-width, var(--thumb-height));

    filter: brightness(100%);
    clip-path: polygon(
                    100% -1px,
                    var(--clip-edges) -1px,
                    0 var(--clip-top),
                    -100vmax var(--clip-top),
                    -100vmax var(--clip-bottom),
                    0 var(--clip-bottom),
                    var(--clip-edges) 100%,
                    var(--clip-further) var(--clip-further)
    );
  }

  input[type="range"]:hover::-webkit-slider-thumb {
    filter: brightness(var(--brightness-hover));
    cursor: grab;
  }

  input[type="range"]:active::-webkit-slider-thumb {
    filter: brightness(var(--brightness-down));
    cursor: grabbing;
  }

  input[type="range"]::-webkit-slider-runnable-track {
    background: linear-gradient(var(--track-color) 0 0) scroll no-repeat center / 100% calc(var(--track-height) + 1px);
  }

  input[type="range"]:disabled::-webkit-slider-thumb {
    cursor: not-allowed;
  }

  /* === Firefox specific styles === */
  input[type="range"],
  input[type="range"]::-moz-range-track,
  input[type="range"]::-moz-range-thumb {
    appearance: none;
    transition: all ease 100ms;
    height: var(--thumb-height);
  }

  input[type="range"]::-moz-range-track,
  input[type="range"]::-moz-range-thumb,
  input[type="range"]::-moz-range-progress {
    background: #fff0;
  }

  input[type="range"]::-moz-range-thumb {
    background: currentColor;
    border: 0;
    width: var(--thumb-width, var(--thumb-height));
    border-radius: var(--thumb-width, var(--thumb-height));
    cursor: grab;
  }

  input[type="range"]:active::-moz-range-thumb {
    cursor: grabbing;
  }

  input[type="range"]::-moz-range-track {
    width: 100%;
    background: var(--track-color);
  }

  input[type="range"]::-moz-range-progress {
    appearance: none;
    background: currentColor;
    transition-delay: 30ms;
  }

  input[type="range"]::-moz-range-track,
  input[type="range"]::-moz-range-progress {
    height: calc(var(--track-height) + 1px);
    border-radius: var(--track-height);
  }

  input[type="range"]::-moz-range-thumb,
  input[type="range"]::-moz-range-progress {
    filter: brightness(100%);
  }

  input[type="range"]:hover::-moz-range-thumb,
  input[type="range"]:hover::-moz-range-progress {
    filter: brightness(var(--brightness-hover));
  }

  input[type="range"]:active::-moz-range-thumb,
  input[type="range"]:active::-moz-range-progress {
    filter: brightness(var(--brightness-down));
  }

  input[type="range"]:disabled::-moz-range-thumb {
    cursor: not-allowed;
  }
}

/************ Time Slider for Animated **************/

/************ Premium Section ******/

.premium_blocks {
  max-width: 900px;
  margin: 30px auto 0;

  .p_block.premium_set {
    background: linear-gradient(to bottom, $primary-color, $secondary-color);

    .p_mode {
      color: #fff;
    }
  }

  h4 {
    color: #fff;

    span {
      color: #fff;
    }
  }

  .p_block_contents ul li::before {
    color: #fff;
  }

  .p_block_contents ul li p {
    color: #fff;
  }

  .p_block_contents ul li.web_mob::after {
    background: url(../images/web_mob_white.svg) no-repeat center center;
  }

  .p_block_contents ul li.mob::after {
    background: url(../images/mobile_icon_white.svg) no-repeat center center;
  }

  .btn_common_white {
    background: #fff;
    width: 90%;
    height: 47px;
    border-radius: 3px;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 600;
    color: #923de8;
    transition: 0.3s all ease;
    opacity: 0.9;

    &:hover {
      opacity: 1;
    }
  }

  .btm_button {
    h6 {
      font-size: 12px;
      color: #fff;
      font-weight: 300 !important;
      opacity: 0.85;
      margin-bottom: 12px;
    }
  }
}

.p_block.free {
  background: #fff;
  margin-bottom: 15px;

  .p_mode {
    color: #6a6a6a;
  }

  h4 {
    color: #202020;

    span {
      color: #a1a1a1;
    }
  }

  .p_block_contents ul li::before {
    color: #a9a9a9;
  }

  .p_block_contents ul li p {
    color: #202020;
  }

  .p_block_contents ul li.web_mob::after {
    background: url(../images/web_mob_grey.svg) no-repeat center center;
  }

  .p_block_contents ul li.mob::after {
    background: url(../images/mobile_icon.svg) no-repeat center center;
  }
}

.p_block {
  padding: 20px 30px 30px 30px;
  box-shadow: 2px 3px 10px rgb(0 0 0 / 8%);
  border-radius: 5px;
  height: 500px;
  position: relative;
  overflow: hidden;

  .offer_label {
    position: absolute;
    top: 12px;
    right: -35px;
    background: #ff3535;
    padding: 4px 30px;
    font-size: 13px;
    color: #fff;
    transform: rotate(45deg);
    font-weight: 700;
  }

  .p_block_top {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .p_mode {
      text-transform: uppercase;
      font-size: 13px;
      letter-spacing: 2px;
      font-weight: 600;
    }

    h4 {
      font-size: 35px;
      font-weight: bold;

      span {
        font-size: 17px;
        font-weight: 600;
        margin-right: 5px;
      }
    }
  }

  .p_block_contents {
    margin-top: 30px;

    ul {
      padding-left: 0;

      .web_mob::after {
        content: "";
        position: absolute;
        height: 20px;
        width: 40px;
        right: 0;
        top: 0;
      }

      .mob::after {
        content: "";
        position: absolute;
        height: 20px;
        width: 40px;
        right: 0;
        top: 0;
      }

      li {
        padding-left: 25px;
        margin-bottom: 25px;
        list-style: none;
        position: relative;

        &::before {
          content: "\f058";
          font: normal normal normal 18px/1 FontAwesome;
          position: absolute;
          top: 3px;
          left: 0;
        }

        p {
          margin-bottom: 0;
          max-width: 300px;
          font-weight: 500;
        }
      }
    }
  }

  .btm_button {
    text-align: center;
    position: absolute;
    bottom: 20px;
    margin: auto;
    width: 100%;
    height: auto;
    left: 0;
    right: 0;

    span {
      text-transform: uppercase;
      letter-spacing: 3px;
      font-weight: 600;
      color: #6a6a6a;
      font-size: 14px;
      margin-bottom: 10px;
    }
  }
}

.pay_route {
  max-width: 300px;
  margin: 5px auto;
  display: flex;
  align-items: center;
  justify-content: space-around;

  span {
    font-weight: 600;
    color: #202020;
  }

  input[type="checkbox"] {
    height: 0;
    width: 0;
    visibility: hidden;
  }

  label {
    cursor: pointer;
    text-indent: -9999px;
    width: 50px;
    height: 26px;
    background: $primary-color;
    display: block;
    border-radius: 100px;
    bottom: 12px;
    position: relative;
  }

  label:after {
    content: "";
    position: absolute;
    top: 3px;
    left: 4px;
    width: 20px;
    height: 20px;
    background: #fff;
    border-radius: 90px;
    transition: 0.3s;
  }

  input:checked + label {
    background: $primary-color;
  }

  input:checked + label:after {
    left: calc(100% - 5px);
    transform: translateX(-100%);
  }

  label:active:after {
    width: 25px;
  }
}

.premium_header {
  text-align: center;
  margin-top: 45px;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    font-size: 34px;
    font-weight: bold;
    color: #202020;
    margin-left: 10px;
  }
}

/************ Premium Section ******/

/********** Main Content ******/

.module_wrap {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.module_btn {
  background: #fff;
  height: 200px;
  width: 240px;
  font-size: 16px;
  font-weight: bold;
  margin: 15px;
  color: $primary-color;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 2px 3px 10px rgb(0 0 0 / 8%);
}

.content_block {
  padding: 30px;

  .content_heading {
    font-size: 20px;
    font-weight: 700;
  }
}

.video_slider {
  margin-top: 30px;

  .slick-arrow:before {
    color: #bababa;
  }

  .slick-arrow:hover::before {
    color: #fff !important;
  }

  .slick-next,
  .slick-prev {
    top: -20px;
    width: 40px;
    height: 40px;
    position: absolute;
    left: unset;
    background: #ffffff;
    border-radius: 4px;

    &:hover {
      background: $primary-color;
    }

    &:hover .slick-next:before,
    &:hover [dir="rtl"] .slick-prev:before {
      color: #fff;
    }
  }

  .slick-prev {
    right: 80px;
  }

  .slick-next {
    right: 35px;
  }

  .slick-next:before,
  [dir="rtl"] .slick-prev:before {
    content: "\f105";
    font: normal normal normal 14px/1 FontAwesome;
    font-size: 30px;
  }

  .slick-prev:before {
    content: "\f104";
    font: normal normal normal 14px/1 FontAwesome;
    font-size: 30px;
    color: #bababa;
  }

  .video_item {
    height: 250px;
  }
}

.launch_soon h5 {
  font-size: 11px;
  margin: 0;
  padding: 7px 10px;
  color: #fff;
  font-weight: 600;
}

.launch_soon {
  position: absolute;
  bottom: -40px;
  text-align: center;
  background: linear-gradient(to bottom, #ff7272, #e91e63);
  left: 0;
  right: 0;
  margin: auto;
  max-width: 100%;
  transition: 0.2s all ease;
}

.cmng_sn {
  opacity: 0.6;
}

.cmng_sn:focus .launch_soon {
  bottom: 0px !important;
}

.video_item {
  background: #fff;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 2px 3px 10px rgb(0 0 0 / 8%);
  transition: 0.2s all ease;

  &:hover {
    transform: scale(1.03);
    background: #f4faff;
    opacity: 1;
  }
}

.content_links {
  h3 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 0;
  }

  h4 {
    font-size: 18px;
    margin-top: 30px;
    margin-bottom: 20px;
  }

  .video_item {
    min-height: 240px;
    height: 100%;

    &:hover {
      cursor: pointer;
    }
  }

  .link_type {
    color: #202020;
    padding: 2px 5px;
    margin: 0px 10px 5px 0px;
    display: inline-flex;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    position: relative;

    &.lesson {
      background-color: #ffea9e;
    }

    &.exp {
      background-color: #cbdcff;
    }

    &.topic {
      background-color: #f1aaff;
    }

    &.animated_less {
      background-color: #aaffdc;
    }
  }

  .act_icon {
    max-height: 70px;
    margin: 10px auto;
    width: 70px;

    img {
      object-fit: contain;
    }
  }

  .act_title {
    padding: 0px 20px;
    text-align: center;
    font-weight: 700;
    color: #202020;
    font-size: 16px;
    margin-top: 20px;
    margin-bottom: 0;
  }
}

.premium_icon::after {
  content: "";
  background: url(../images/diamond.svg) no-repeat center center;
  height: 30px;
  width: 30px;
  display: block;
  position: absolute;
  top: -10px;
  left: 40px;
  z-index: 9;
  background-color: transparent;
}

.premium_item .video_item::after {
  content: "";
  background: url(../images/premium_icon.png) no-repeat center center;
  height: 30px;
  width: 30px;
  display: block;
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 9;
  background-color: transparent;
}

.premium_item {
  position: relative;

  .video_item {
    background: linear-gradient(#faefff, #ecf2ff);
    position: relative;

    .prm_icon {
      position: absolute;
      right: 10px;
      top: 10px;
    }
  }
}

.main_topics {
  .content_links {
    height: 100%;

    .video_item {
      position: relative;
      padding: 15px 20px 25px 20px;

      p {
        text-align: left;
        padding: 10px 10px 0 10px;
        font-size: 14px;
        margin-bottom: 0;
      }

      .link_type h6 {
        margin: 0 5px 0 0px;
        font-size: 12px;
        padding: 2px;
        text-align: center;
        width: 20px;
        font-weight: bold;
        border-radius: 3px;
      }

      .link_type.lesson h6 {
        background-color: #ffbd43;
      }

      .link_type.exp h6 {
        background-color: #79a5ff;
      }

      .link_type.topic h6 {
        background-color: #e979ff;
      }
    }

    .premium_topic {
      position: absolute;
      top: 15px;
      right: 15px;
    }
  }
}

.topic-image {
  padding-right: 15px;
  display: inline-block;

  img {
    height: 80px;
    object-fit: contain;
  }
}

.topic_tags {
  span {
    padding: 2px 7px;
    font-size: 14px;
    font-weight: 500;
    color: #202020;
    border-radius: 2px;
    text-transform: uppercase;
    margin-right: 10px;
  }

  .new {
    background: #ffbfbf;
  }

  .mp {
    background: #bfffd1;
  }

  .tn {
    background: #bfe8ff;
  }
}

.topic_desc {
  max-width: 800px;
  margin: 0 0 30px 0;

  p {
    color: #6d6d6d;
  }
}

.progress {
  margin: 20px auto;
  padding: 0;
  width: 90%;
  height: 9px;
  overflow: hidden;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background: #e5e5e5;
  border-radius: 30px;
  transition: 0.2s all ease;

  input {
    width: 100%;
    min-width: 100%;
    background: transparent;
    color: transparent;
    opacity: 0;
    position: absolute;
    bottom: -4px;
    z-index: 999;
    cursor: pointer;
  }
}

.bar {
  position: relative;
  float: left;
  min-width: 0%;
  width: 0;
  height: 100%;
  background: $primary-color;
  transition: 0.5s all ease;
}

.percent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  font-family: tahoma, arial, helvetica;
  font-size: 12px;
  color: white;
}

.coming_soon {
  position: absolute;
  background: linear-gradient(to bottom, #ff7272, #e91e63);
  left: 0;
  text-align: center;
  bottom: 0;
  width: 100%;

  span {
    font-size: 11px;
    margin-left: 27px;
    margin-bottom: 6px;
    display: inline-block;
    font-weight: 700;
    color: #fff;
  }
}

/********** Main Content ******/

/******* Lessons Video Animation ***********/
.suggest_block {
  border-left: 1px solid #ebebeb;
  padding-left: 30px;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-right: 15px;

  .content_links {
    .video_item {
      min-height: auto;
      margin-bottom: 15px;
      padding: 15px 15px 45px 15px;
      height: auto;
    }
  }
}

.lesson_wrap {
  padding-top: 53px;

  hr {
    border-color: #b8b8b8;
  }
}

.lesson_block {
  padding: 30px 5px 30px 20px;
  height: 100%;
}

.redirect_sec {
  height: calc(100% - 92px);
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  h3 {
    margin-bottom: 20px;
  }

  .loader_dir {
    img {
      height: 60px;
    }

    margin-bottom: 30px;
  }

  p {
    max-width: 400px;
    text-align: center;
  }

  .btn_underline {
    max-width: 82px;
    padding: 0;
    text-align: center;
    font-weight: 500;
    margin-left: 10px;
  }
}

h2.main_heading {
  font-size: 24px;
  font-weight: 700;
  display: flex;
  align-items: center;
  color: #202020;
  margin-bottom: 20px;
  position: relative;
}

/******************* Christmas ***********/
.christmas_wish {
  position: absolute;
  right: 155px;
  bottom: 88px;

  img {
    height: 180px;
  }
}

.logo_sec {
  .christmas_theme_logo {
    max-width: 100%;
    margin-top: 4px;
    max-height: 57px;
  }
}

.hanging_bells {
  position: fixed;
  top: 0;
  z-index: 9999;
  height: 100%;
  width: 100%;
  display: flex;
  left: 0;
  max-width: 1200px;
  justify-content: space-between;
  margin: auto;
  right: 0;
  -webkit-animation: expand 0.5s;
  animation: expand 0.5s;
  pointer-events: none;

  .bell {
    width: 35px;
    height: auto;
    display: inline-block;
    -webkit-transform-origin: top center;
    transform-origin: top center;
    -webkit-animation-duration: 8s;
    animation-duration: 8s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }

  .bell_1 {
    -webkit-animation-name: swing1;
    animation-name: swing1;
  }

  .bell_2 {
    -webkit-animation-name: swing2;
    animation-name: swing2;
  }

  .bell_3 {
    -webkit-animation-name: swing3;
    animation-name: swing3;
  }

  @-webkit-keyframes swing1 {
    20%,
    40%,
    60%,
    80%,
    100% {
      -webkit-transform-origin: top center;
    }
    20% {
      -webkit-transform: rotate(10deg);
    }
    40% {
      -webkit-transform: rotate(-5deg);
    }
    60% {
      -webkit-transform: rotate(2deg);
    }
    80% {
      -webkit-transform: rotate(-2deg);
    }
    100% {
      -webkit-transform: rotate(0deg);
    }
  }

  @keyframes swing1 {
    20% {
      transform: rotate(10deg);
    }
    40% {
      transform: rotate(-5deg);
    }
    60% {
      transform: rotate(2deg);
    }
    80% {
      transform: rotate(-2deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  @-webkit-keyframes swing2 {
    20%,
    40%,
    60%,
    80%,
    100% {
      -webkit-transform-origin: top center;
    }
    20% {
      -webkit-transform: rotate(-10deg);
    }
    40% {
      -webkit-transform: rotate(5deg);
    }
    60% {
      -webkit-transform: rotate(-2deg);
    }
    80% {
      -webkit-transform: rotate(2deg);
    }
    100% {
      -webkit-transform: rotate(0deg);
    }
  }

  @keyframes swing2 {
    20% {
      transform: rotate(-10deg);
    }
    40% {
      transform: rotate(5deg);
    }
    60% {
      transform: rotate(-2deg);
    }
    80% {
      transform: rotate(2deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  @-webkit-keyframes swing3 {
    20%,
    40%,
    60%,
    80%,
    100% {
      -webkit-transform-origin: top center;
    }
    20% {
      -webkit-transform: rotate(-7deg);
    }
    40% {
      -webkit-transform: rotate(3deg);
    }
    60% {
      -webkit-transform: rotate(-2deg);
    }
    80% {
      -webkit-transform: rotate(1deg);
    }
    100% {
      -webkit-transform: rotate(0deg);
    }
  }

  @keyframes swing3 {
    20% {
      transform: rotate(-7deg);
    }
    40% {
      transform: rotate(3deg);
    }
    60% {
      transform: rotate(-2deg);
    }
    80% {
      transform: rotate(1deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
}

@-webkit-keyframes expand {
  0% {
    top: -300px;
  }
  100% {
    top: 0px;
  }
}

@keyframes expand {
  0% {
    top: -300px;
  }
  100% {
    top: 0px;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(1turn);
  }
}

@keyframes glow {
  50% {
    text-shadow: 0 0 3rem rgba(255, 255, 255, 0.2);
  }
}

@keyframes drop {
  from {
    top: 0;
  }

  to {
    top: 1500px;
  }
}

.snowfall {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 9999;
  background: rgb(0 0 0 / 0);
  pointer-events: none;

  @function random_range($min, $max) {
    $rand: random();
    $random_range: $min + floor($rand * (($max - $min) + 1));
    @return $random_range;
  }

  .snow {
    $total: 200;
    position: fixed;
    width: 15px;
    height: 15px;
    background: #ddddda;
    border-radius: 50%;
    box-shadow: 2px 5px 10px rgb(0 0 0 / 15%);
    filter: blur(2px);

    @for $i from 1 through $total {
      $random-x: random(1000000) * 0.0001vw;
      $random-offset: random_range(-100000, 100000) * 0.0001vw;
      $random-x-end: $random-x + $random-offset;
      $random-x-end-yoyo: $random-x + ($random-offset / 2);
      $random-yoyo-time: random_range(30000, 80000) / 100000;
      $random-yoyo-y: $random-yoyo-time * 100vh;
      $random-scale: random(10000) * 0.0001;
      $fall-duration: random_range(10, 30) * 1s;
      $fall-delay: random(30) * -1s;

      &:nth-child(#{$i}) {
        opacity: random(10000) * 0.0001;
        transform: translate($random-x, -10px) scale($random-scale);
        animation: fall-#{$i} $fall-duration $fall-delay linear infinite;
      }

      @keyframes fall-#{$i} {
        #{percentage($random-yoyo-time)} {
          transform: translate($random-x-end, $random-yoyo-y) scale($random-scale);
        }

        to {
          transform: translate($random-x-end-yoyo, 100vh) scale($random-scale);
        }
      }
    }
  }
}

body {
  overflow-x: hidden;
}

.video_deer {
  position: absolute;
  z-index: 99999;
  top: 20%;
  left: 0px;
  height: 600px;
  width: 100%;
  animation: linear;
  -webkit-animation: linear;
  -webkit-animation-name: run;
  -webkit-animation-duration: 14s;
  animation-fill-mode: forwards;

  img {
    height: 100%;
    max-width: 100%;
    object-fit: contain;
  }
}

@keyframes run {
  0% {
    left: -100%;
  }
  50% {
    left: 100%;
  }
  100% {
    left: 100%;
    visibility: hidden;
    display: none;
  }
}

@-webkit-keyframes run {
  0% {
    left: 0;
  }
  50% {
    left: 100%;
  }
  100% {
    left: 100%;
    display: none;
  }
}

/******************* Christmas ***********/

.animation_player {
  height: auto;
  background-color: #fff;
  margin-top: 30px;
  position: relative;
  transition: 0.2s all ease;

  .anim_video {
    min-height: 500px;
  }

  .ctrl_click {
    position: absolute;
    width: 100%;
    height: 100%;
    background: transparent;
    z-index: 9;
  }

  &:hover::after,
  &:active::after {
    opacity: 1;
    display: block;
    visibility: visible;
  }

  &:hover .text_exp {
    bottom: 70px;
  }

  &:hover .controller_sec,
  &:active .controller_sec {
    display: flex;
    visibility: visible;
    opacity: 1;
  }

  &::after {
    content: "";
    width: 100%;
    height: 50%;
    pointer-events: none;
    background: linear-gradient(to top, rgb(56 56 56 / 82%), rgb(78 78 78 / 22%), rgb(255 255 255 / 0%));
    position: absolute;
    z-index: 0;
    bottom: 0;
    opacity: 0;
    display: block;
    transition: 0.2s all ease;
  }
}

.a-enter-ar-button,
.a-enter-vr-button {
  z-index: 9 !important;
}

.a-enter-ar,
.a-enter-vr {
  bottom: unset !important;
  top: 40px;
}

.controller_sec {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: none;
  z-index: 99;
  padding: 20px;
  align-items: center;
  justify-content: space-between;

  button {
    &:hover i {
      opacity: 1;
    }
  }

  .time_slider {
    position: absolute;
    width: 100%;
    bottom: 70px;
    left: 0;
    right: 0;

    input {
      border-radius: 0;
    }
  }

  .full_screen {
    width: 190px;
    text-align: right;
  }

  .left_ctrls {
    display: flex;
    width: 190px;

    .dropdown-menu.show {
      display: block;
      border: none;
      box-shadow: 2px 3px 10px rgb(0 0 0 / 16%);

      li {
        margin: 3px 0;
      }

      button {
        font-size: 14px;
      }
    }

    .text_shw {
      position: relative;

      &::after {
        content: "";
        border: 2px solid #fff;
        position: absolute;
        width: 23px;
        background: #fff;
        left: 0;
        right: 0;
        margin: auto;
        top: 31px;
        border-radius: 10px;
      }
    }

    .volume_btn {
      position: relative;
      padding: 0 5px;
    }

    .vol-neg {
      position: relative;

      &::after {
        content: "";
        border: 2px solid #fff;
        position: absolute;
        width: 30px;
        border-radius: 10px;
        background: #fff;
        transform: rotate(45deg);
        left: 6px;
        top: 10px;
      }
    }
  }

  .mid_ctrls {
    button {
      margin: 0 10px;
    }

    .play_pause {
      font-size: 1.5em;
      min-width: 45px;
      transition: 0.5s all ease;

      i {
        transition: 0.5s all ease;
      }
    }
  }

  .volm {
    margin-left: 20px;
  }

  .next_play {
    transform: scaleX(-1);
  }

  .dropdown-toggle::after {
    display: none;
  }

  .dropdown-toggle {
    span {
      font-size: 16px;
      font-weight: 600;
      position: relative;
      bottom: 3px;
      margin-left: 5px;
      color: #fff;
    }
  }

  i {
    font-size: 1.6em;
    color: #fff;
    opacity: 0.75;
    transition: 0.25s all ease;
  }
}

.text_exp {
  color: #202020;
  line-height: 28px;
  font-size: 18px;
  font-weight: 600;
  width: auto;
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0px;
  transition: 0.3s all ease;
  padding: 20px 30px;
  background: rgba(241, 241, 241, 0.7);
  z-index: 9;
  pointer-events: none;
  margin: 30px;

  p {
    margin-bottom: 0;
  }
}

/******* Lessons Video Animation ***********/

.error_blk {
  position: relative;
  background: #f1c72e;
  height: 40px;
  margin-top: 12px;
  display: flex;
  align-items: center;
  padding: 0 14px;

  h4 {
    font-size: 14px;
    text-align: center;
    margin-bottom: 0;
    font-weight: 500;
  }
}

.medium-zoom-overlay {
  z-index: 9999;
}

.medium-zoom-image--opened {
  z-index: 99999;
}

/****** Responsive Section *****/
@media (min-width: 1400px) and (max-width: 1520px) {
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
}

@media (max-width: 767px) {
  .christmas_wish {
    right: 20px;

    img {
      height: 135px;
    }
  }
  // .std_lessons{
  //     flex-direction: column;
  //     height: 600px;

  //     .std_img_block{
  //         width: 100%;
  //     }
  //     .std_text_block{
  //         width: 100%;
  //     }
  // }

  .logo_wrap img {
    height: 40px;
  }

  .signup_form_area {
    padding: 30px 15px;
  }

  .diamond_2 {
    left: 117px;
  }
  .diamond_1 {
    left: 80px;
  }
  .one {
    right: -97px;
  }
  .two {
    left: 113px;
  }

  .p_block {
    margin-bottom: 20px;

    .p_block_contents ul li p {
      max-width: 205px;
    }
  }

  .subsc_sec {
    .subs_btm {
      flex-direction: column;

      img {
        transform: rotate(90deg);
        margin-top: 50px;
        width: 45px;
      }
    }

    .price_val {
      padding-top: 40px;
    }
  }

  .quiz_modal .modal-header .mcq_progress {
    width: 86%;
  }

  .offer_popup .offer_block .offer_sec h1 {
    font-size: 50px;
  }

  .offer_popup .offer_block .off_btn_blk {
    margin-top: 25px;
  }

  .offer_popup .offer_block .offer_details {
    flex-direction: column;
    margin-top: 25px;

    .off_opt {
      width: 100% !important;
      padding: 20px 0;
    }

    .off_opt:nth-child(2) {
      border-left: none !important;
      padding-left: 0 !important;
      border-top: 1px solid #161616;
    }
  }
  .top_header h2 {
    font-size: 18px;
    max-width: 200px;
    margin-top: 5px;
    margin-left: 15px;
  }
  .right-head {
    .btn_common,
    .btn_grey {
      width: 100px;
    }
  }

  .prm_content_popup .prm_wrap {
    flex-direction: column;

    .prm_left {
      width: 100%;
    }

    .prm_right {
      position: relative;
      width: 100%;
      flex-direction: row;
      justify-content: space-around;
      padding: 0 15px;

      .play_store {
        margin-top: 20px;
        margin-bottom: 20px;

        a {
          margin-bottom: 10px;
        }
      }
    }

    .exp_vid {
      display: none;
    }

    .exp_video_sec .exp_txt {
      max-width: 100%;
    }

    .qr_code {
      display: none;
    }

    .prm_right h2 {
      margin: 0;
    }
  }
  .activity_header h2 {
    font-size: 18px;
    padding-right: 7px;
  }
  .suggest_block {
    padding-left: 15px;
    border-left: none;
    border-top: 1px solid #ebebeb;
  }
  .animation_player {
    .text_exp {
      margin: 10px;
      z-index: 99;
      font-size: 12px;
      padding: 10px 12px;
      line-height: 16px;
    }
  }

  .animation_player:hover {
    .text_exp {
      bottom: 0px;
    }

    &::after {
      display: none;
    }

    .controller_sec {
      display: none;
    }
  }
  .show_ctrl {
    .text_exp {
      bottom: 85px !important;
    }

    &::after {
      display: block !important;
      height: 100% !important;
      background: rgba(0, 0, 0, 0.3) !important;
      opacity: 1;
    }

    .controller_sec {
      z-index: 999 !important;
      display: flex !important;
      opacity: 1;

      .volm {
        margin-left: 12px;
      }

      .mid_ctrls {
        bottom: 250px;
        margin: auto;
        left: 0;
        right: 0;
        text-align: center;
        z-index: 999;
        position: absolute;
      }
    }
  }
  .lesson_block {
    padding: 30px 15px 30px 15px;
  }
  .top_header .search_n_profile {
    display: flex;
    justify-content: space-between;
  }
  .main_area {
    .top_header {
      padding-left: 50px;
    }
  }
  .menu_collapse {
    left: 15px;
    top: 35px;
  }
  .top_header .account_area a,
  .top_header .account_area button {
    padding-right: 15px;
  }
  .top_header .account_area .dropdown-toggle .usr_name {
    display: none;
  }

  .search_box {
    width: 100%;
    padding: 0 10px;

    input {
      display: block;
    }

    input::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #979797;
      opacity: 1; /* Firefox */
      font-size: 14px;
      width: 48px !important;
      font-weight: 500;
      overflow: hidden;
      white-space: nowrap;
    }

    input::-moz-placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #979797;
      opacity: 1; /* Firefox */
      font-size: 14px;
      width: 48px !important;
      font-weight: 500;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    input:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #979797;
      font-size: 14px;
      width: 48px;
      font-weight: 500;
      width: 50px;
      overflow: hidden;
      white-space: nowrap;
    }

    input::-ms-input-placeholder {
      /* Microsoft Edge */
      color: #979797;
      font-size: 14px;
      width: 48px;
      font-weight: 500;
      width: 50px;
      overflow: hidden;
      white-space: nowrap;
    }
  }
  // .account_area{
  //     position: relative;
  //     bottom: 6px;
  // }
  .short_cut_links {
    bottom: 7px;
    left: 0;
    width: 100%;
    overflow: scroll;

    ul {
      display: flex;

      li {
        button {
          min-width: 75px;
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .prm_content_popup .exp_video_sec .exp_vid {
    width: 171px;
    height: 196px;
    border-radius: 12px;
  }
  .prm_content_popup .modal-dialog {
    max-width: 620px;
  }
  .login_check label {
    max-width: 140px;
  }

  .left_block {
    .shape_block {
      .shape_1 {
        margin-right: 25px;

        img {
          height: 13em;
        }
      }

      .shape_2 {
        right: 192px;
        margin-top: 182px;
      }
    }
  }

  .login_text_block {
    bottom: 40px;
    left: 40px;

    h1 {
      font-size: 2rem;
    }
  }
}

@media (max-width: 1024px) {
  .shape_block {
    .shape_2 {
      right: 272px;
      margin-top: 215px;
    }

    .shape_1 {
      margin-right: 50px;
    }
  }
}

@media (max-width: 1441px) {
  .animation_player {
    min-height: 365px;
  }
}

/********* Responsive Menu *********/
@media (max-width: 1024px) {
  .left_menu {
    left: -250px;
    z-index: 99999;
  }
  .main_area {
    padding-left: 0;
  }
  .top_header {
    left: 0;
    padding-left: 70px;
  }

  .menu_collapse {
    z-index: 999999;
  }

  .menu_shrink {
    .top_header {
      left: 0;
    }

    .left_menu {
      left: 0px;
      width: 250px;
      box-shadow: 4px 0px 10px rgba(0, 0, 0, 0.08);
      border-right: none;
    }

    .menu_sec ul li a,
    .logo_sec img,
    .logo_sec span {
      opacity: 1;
    }
  }
}

// @media(min-width:1440px){
//     .std_lessons{
//         height: 465px;
//     }
// }

// @media(min-width:1540px){
//     .std_lessons{
//         height: 550px;
//     }
// }

/********* Responsive Menu *********/

/******* Standard Lessons ***********/
.standard_player {
  min-height: 500px;
  background-color: #fff;
  margin-top: 30px;
  position: relative;

  &:hover .controller_sec {
    display: flex;
  }

  &::after {
    content: "";
    width: 100%;
    height: 50%;
    pointer-events: none;
    background: linear-gradient(to top, rgb(56 56 56 / 82%), rgb(78 78 78 / 22%), rgb(255 255 255 / 0%));
    position: absolute;
    z-index: 9;
    bottom: 0;
    opacity: 0;
    transition: 0.2s all ease;
  }
}
