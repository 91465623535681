/**** Immersify Web Platform Common Styles******/
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/montserrat/Montserrat-Black.ttf") format("truetype");
  font-weight: 900;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/montserrat/Montserrat-Bold.ttf") format("truetype");
  font-weight: 700;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/montserrat/Montserrat-Regular.ttf") format("truetype");
  font-weight: 400;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/montserrat/Montserrat-ExtraBold.ttf") format("truetype");
  font-weight: 800;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/montserrat/Montserrat-ExtraLight.ttf") format("truetype");
  font-weight: 200;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/montserrat/Montserrat-Light.ttf") format("truetype");
  font-weight: 300;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/montserrat/Montserrat-Medium.ttf") format("truetype");
  font-weight: 500;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/montserrat/Montserrat-SemiBold.ttf") format("truetype");
  font-weight: 600;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/montserrat/Montserrat-Thin.ttf") format("truetype");
  font-weight: 100;
}
/********* Common **********/
body {
  font-family: "Montserrat", sans-serif;
}

/**** Breadcrumb *****/
.breadcrumb_des {
  padding: 5px 15px;
  border-bottom: 1px solid #ebebeb;
  display: flex;
  position: fixed;
  width: 100%;
  z-index: 999;
  align-items: center;
  background: #f7f8fa;
}
.breadcrumb_des .back_btn {
  font-size: 1.7em;
  color: #7a7a7a;
  border: none;
  background: none;
  display: inline-block;
}
.breadcrumb_des ul {
  margin-bottom: 0;
  padding-left: 5px;
}
.breadcrumb_des ul li {
  list-style: none;
  font-size: 14px;
  margin: 0 7px;
  float: left;
}
.breadcrumb_des ul li::after {
  content: "\f105";
  font: normal normal normal 14px/1 FontAwesome;
  margin-left: 15px;
}
.breadcrumb_des ul li:last-child button {
  font-weight: 600;
  color: #161616;
}
.breadcrumb_des ul li:last-child::after {
  display: none;
}
.breadcrumb_des ul li button {
  color: #767676;
  text-align: left;
}

/**** Breadcrumb *****/
.form-check {
  margin-bottom: 5px;
}
.form-check .form-check-input:checked {
  background-color: #0077d6;
  border-color: #0077d6;
}
.form-check .form-check-input {
  border-radius: 2px;
  height: 1.3rem;
  width: 1.3rem;
  box-shadow: none;
}
.form-check label {
  margin-top: 4px;
  margin-left: 5px;
  font-size: 14px;
  color: #919191;
}

.form-group .form-control {
  height: 47px;
  color: #919191;
  font-size: 14px;
}
.form-group .form-control.name:valid, .form-group .form-control.email:valid, .form-group .form-control.pwd:valid {
  border-color: #0077d6;
}
.form-group .form-control:focus {
  border-color: #0077d6;
  outline: none !important;
  box-shadow: none;
}
.form-group ::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #919191;
  opacity: 1; /* Firefox */
  font-size: 14px;
}
.form-group :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #919191;
  font-size: 14px;
}
.form-group ::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #919191;
  font-size: 14px;
}

.dropdown .dropdown-toggle {
  font-size: 14px;
  color: #aeaeae;
}

.btn_underline {
  font-size: 16px;
  color: #0077d6;
  border-bottom: 1px solid #0077d6;
}
.btn_underline:hover {
  color: #5089fa;
  border-color: #5089fa;
}

.btn_grey {
  background: #cbcbcb;
  border: none;
  height: 45px;
  padding: 10px 25px;
  border-radius: 3px;
  color: #fff;
  font-weight: 470;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  background-position: center;
  transition: background 0.8s;
}

.btn_readmore {
  display: inline-block;
  padding: 0;
  color: #0077d6;
}

.btn_common {
  background: #0077d6;
  border: none;
  min-height: 45px;
  padding: 10px 25px;
  border-radius: 3px;
  color: #fff;
  font-weight: 470;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  background-position: center;
  transition: background 0.8s;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.btn_common:hover {
  background: #5089fa radial-gradient(circle, transparent 1%, #5089fa 1%) center/14700%;
}
.btn_common:active {
  background-color: #77a3fa;
  background-size: 100%;
  transition: background 0s;
}

.right-head {
  position: relative;
  z-index: 9;
}

@keyframes pulse-black {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(1, 76, 187, 0.7);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(82, 1, 187, 0);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(1, 76, 187, 0);
  }
}
.pulse {
  animation: pulse-black 2s infinite;
}

.ripple {
  margin: auto;
  background-color: rgba(10, 10, 10, 0);
  width: 130px;
  height: 45px;
  border-radius: 3px;
  display: block;
  animation: ripple 3s linear infinite;
  position: absolute;
  right: 0px;
}

.ripple::before,
.ripple::after {
  content: "";
  animation: inherit;
  animation-delay: 1s;
  position: absolute;
  right: 10px;
  width: 110px;
  height: 45px;
  border-radius: 3px;
  display: block;
  top: 0px;
}

.ripple::after {
  animation-delay: 2.2s;
}

@keyframes ripple {
  0% {
    box-shadow: 0 0 0 0.35rem rgba(151, 193, 255, 0.3);
  }
  100% {
    box-shadow: 0 0 0 3rem rgba(255, 255, 255, 0);
  }
}
.glow-on-hover {
  overflow: visible !important;
}

.glow-on-hover::after {
  content: "";
  width: 30px;
  height: 30px;
  border-radius: 100%;
  border: 6px solid #00ffcb;
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: ring 1.5s infinite;
}

@keyframes ring {
  0% {
    width: 30px;
    height: 30px;
    opacity: 1;
  }
  100% {
    width: 300px;
    height: 300px;
    opacity: 0;
  }
}
.btn_success {
  background: #28ad4e;
  border: none;
  height: 45px;
  padding: 10px 25px;
  border-radius: 3px;
  color: #fff;
  font-weight: 470;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  background-position: center;
  transition: background 0.8s;
}
.btn_success::after {
  content: "\f00c";
  color: #ffffff;
  font: normal normal normal 18px/1 FontAwesome;
  margin-left: 5px;
}
.btn_success:hover {
  background: #3ac762 radial-gradient(circle, transparent 1%, #3ac762 1%) center/14700%;
}
.btn_success:active {
  background-color: #31b155;
  background-size: 100%;
  transition: background 0s;
}

button,
a {
  transition: 0.2s all ease;
  text-decoration: none;
}

.main_area {
  padding-left: 250px;
  padding-top: 96px;
  min-height: 100vh;
  background-color: #f7f8fa;
  transition: 0.4s all ease;
}

.main_area_clean {
  min-height: 100vh;
  background-color: #f7f8fa;
  transition: 0.4s all ease;
  padding-top: 97px;
}

button {
  border: none;
  background: none;
}

.main_topics {
  padding-top: 75px;
  padding-left: 30px;
  padding-right: 30px;
}

.animation_player:-moz-full-screen .full_screen_det {
  position: absolute;
  height: 200px;
  width: 100%;
  bottom: 0;
  z-index: 9;
  transition: 0.2s all ease;
}
.animation_player:-moz-full-screen .controller_sec {
  height: 200px;
  padding-top: 100px;
}
.animation_player:-moz-full-screen .time_slider {
  bottom: 100px;
}
.animation_player:-moz-full-screen:hover::after {
  visibility: hidden;
  opacity: 0;
}
.animation_player:-moz-full-screen:hover .controller_sec {
  display: none;
}
.animation_player:-moz-full-screen:hover .text_exp {
  bottom: 0;
}
.animation_player:-moz-full-screen .controller_sec:hover + .text_exp {
  bottom: 100px !important;
}
.animation_player:-moz-full-screen .full_screen_det:hover + .controller_sec {
  display: flex !important;
}
.animation_player:-moz-full-screen .controller_sec:hover {
  display: flex !important;
  background: linear-gradient(to top, #222222, transparent);
}

.animation_player:-webkit-full-screen .full_screen_det {
  position: absolute;
  height: 200px;
  width: 100%;
  bottom: 0;
  z-index: 9;
  transition: 0.2s all ease;
}
.animation_player:-webkit-full-screen .controller_sec {
  height: 200px;
  padding-top: 100px;
}
.animation_player:-webkit-full-screen .time_slider {
  bottom: 100px;
}
.animation_player:-webkit-full-screen:hover::after {
  visibility: hidden;
  opacity: 0;
}
.animation_player:-webkit-full-screen:hover .controller_sec {
  display: none;
}
.animation_player:-webkit-full-screen:hover .text_exp {
  bottom: 0;
}
.animation_player:-webkit-full-screen .controller_sec:hover + .text_exp {
  bottom: 100px !important;
}
.animation_player:-webkit-full-screen .full_screen_det:hover + .controller_sec {
  display: flex !important;
}
.animation_player:-webkit-full-screen .controller_sec:hover {
  display: flex !important;
  background: linear-gradient(to top, #222222, transparent);
}

.animation_player:-ms-fullscreen {
  width: 100%; /* needed to center contents in IE */
}
.animation_player:-ms-fullscreen .full_screen_det {
  position: absolute;
  height: 200px;
  width: 100%;
  bottom: 0;
  z-index: 9;
  transition: 0.2s all ease;
}
.animation_player:-ms-fullscreen .controller_sec {
  height: 200px;
  padding-top: 100px;
}
.animation_player:-ms-fullscreen .time_slider {
  bottom: 100px;
}
.animation_player:-ms-fullscreen:hover::after {
  visibility: hidden;
  opacity: 0;
}
.animation_player:-ms-fullscreen:hover .controller_sec {
  display: none;
}
.animation_player:-ms-fullscreen:hover .text_exp {
  bottom: 0;
}
.animation_player:-ms-fullscreen .controller_sec:hover + .text_exp {
  bottom: 100px !important;
}
.animation_player:-ms-fullscreen .full_screen_det:hover + .controller_sec {
  display: flex !important;
}
.animation_player:-ms-fullscreen .controller_sec:hover {
  display: flex !important;
  background: linear-gradient(to top, #222222, transparent);
}

.animation_player:fullscreen .full_screen_det {
  position: absolute;
  height: 200px;
  width: 100%;
  bottom: 0;
  z-index: 9;
  transition: 0.2s all ease;
}
.animation_player:fullscreen .controller_sec {
  height: 200px;
  padding-top: 100px;
}
.animation_player:fullscreen .time_slider {
  bottom: 100px;
}
.animation_player:fullscreen:hover::after {
  visibility: hidden;
  opacity: 0;
}
.animation_player:fullscreen:hover .controller_sec {
  display: none;
}
.animation_player:fullscreen:hover .text_exp {
  bottom: 0;
}
.animation_player:fullscreen .controller_sec:hover + .text_exp {
  bottom: 100px !important;
}
.animation_player:fullscreen .full_screen_det:hover + .controller_sec {
  display: flex !important;
}
.animation_player:fullscreen .controller_sec:hover {
  display: flex !important;
  background: linear-gradient(to top, #222222, transparent);
}

.modal-backdrop {
  z-index: 9999 !important;
}

.modal {
  z-index: 99999 !important;
}

.hide_top_bar {
  padding-top: 0 !important;
}
.hide_top_bar .top_header {
  height: 0;
  padding: 0;
  opacity: 0;
}

/********* Common **********/
/********* Profile **********/
.frgt_pd img {
  height: 140px;
  padding: 10px;
}

.frgt_pd {
  text-align: center;
  padding-bottom: 20px;
}
.frgt_pd h4 {
  font-size: 18px;
  max-width: 565px;
  padding: 20px 0 10px;
  margin: auto;
  font-weight: 500;
  color: #202020;
  display: block;
}

.confirmation_popup .bottom_btns {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
}
.confirmation_popup .bottom_btns button {
  width: 130px;
  margin-left: 10px;
}
.confirmation_popup .confirm_head {
  width: 100%;
  position: absolute;
  height: 7px;
  background: #0077d6;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}
.confirmation_popup .confirm_head .cfm_cnt {
  height: 45px;
  width: 45px;
  display: block;
  border-radius: 50%;
  background: #0077d6;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: -20px;
  text-align: center;
  padding-top: 8px;
  font-size: 1.2em;
  color: #fff;
}
.confirmation_popup .confirm_block {
  padding: 50px 0 40px 0;
}
.confirmation_popup .confirm_block h4 {
  font-size: 18px;
  color: #202020;
  font-weight: 600;
  text-align: center;
}
.confirmation_popup .modal-dialog {
  margin-top: 180px;
}
.confirmation_popup .modal-header {
  border: none;
}
.confirmation_popup .btn_mod_close {
  right: 0;
}

.cancel_subs {
  position: absolute;
  right: 15px;
  transition: 0.2s all ease;
}
.cancel_subs i {
  transition: 0.5s all ease;
}
.cancel_subs .btn-cancel:hover i {
  transform: rotate(30deg);
}
.cancel_subs .dropdown-toggle::after {
  display: none;
}
.cancel_subs .dropdown i {
  font-size: 1.5em;
  color: #fff;
}
.cancel_subs .dropdown-menu {
  font-size: 13px;
  border: none;
  box-shadow: 2px 3px 10px rgba(0, 0, 0, 0.16);
}

.subsc_sec .bill_explained {
  display: flex;
  align-items: center;
}
.subsc_sec .bill_explained .bill_yearly {
  margin-left: 15px;
  background: #fff;
  height: 30px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  border-radius: 2px;
}
.subsc_sec .bill_explained .bill_yearly p {
  color: #533acf;
  font-weight: 600;
  font-size: 12px;
}
.subsc_sec .alert {
  border-radius: 0;
}
.subsc_sec .alert-success {
  color: #006234;
  background-color: #c5f3d1;
  border-color: #53c988;
  margin-bottom: 0;
}
.subsc_sec .subscription_det {
  position: relative;
  overflow: hidden;
  background: linear-gradient(to bottom, #084bb7, #a728ea);
  padding: 20px;
}
.subsc_sec .subscription_det p {
  color: #fff;
  margin-top: 15px;
}
.subsc_sec .subscription_det hr {
  border-color: #fff;
  opacity: 0.3;
}
.subsc_sec .subscription_det .subsc_top {
  display: flex;
  align-items: center;
}
.subsc_sec .subscription_det .subsc_top h4 {
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  text-transform: uppercase;
  margin-bottom: 0;
  margin-left: 10px;
  letter-spacing: 1px;
}
.subsc_sec .price_val h5 {
  color: #fff;
  font-weight: 600;
  font-size: 2rem;
}
.subsc_sec .price_val h5 span {
  font-size: 1.2rem !important;
  font-weight: 500;
}
.subsc_sec .price_val h6 {
  color: #fff;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 30px;
}
.subsc_sec .subs_btm {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.subsc_sec .subs_btm .btn_switch {
  color: #fff;
  font-weight: 600;
  margin: 0;
  position: relative;
  transition: 0.2s all ease;
}
.subsc_sec .subs_btm .btn_switch:hover, .subsc_sec .subs_btm .btn_switch:hover::after {
  color: #f5b9ff;
  border-color: #f5b9ff;
}
.subsc_sec .subs_btm .btn_switch::after {
  transition: 0.2s all ease;
  position: absolute;
  content: "";
  border-bottom: 2px solid #fff;
  width: 94%;
  bottom: -2px;
  top: 0;
  left: 7px;
}
.subsc_sec .subs_btm .price_val h5 {
  font-size: 1.3rem;
  margin-bottom: 0;
}
.subsc_sec .subs_btm .price_val h5 span {
  font-size: 1rem !important;
  font-weight: 500;
}
.subsc_sec .subs_btm .price_val p {
  color: #fff;
  font-weight: 400;
  font-size: 12px;
  opacity: 0.8;
  margin-top: 3px;
  margin-bottom: 0px;
}

.right_profile {
  box-shadow: 2px 3px 10px rgba(0, 0, 0, 0.08);
  background-color: #fff;
  padding: 30px;
  border-radius: 5px;
}
.right_profile .form-group {
  margin-bottom: 15px;
}
.right_profile h3 {
  font-size: 20px;
  font-weight: 700;
  color: #202020;
  margin-bottom: 20px;
}
.right_profile .profile_edit_sec.qr_c h3 {
  margin-bottom: 3px;
}
.right_profile p {
  color: #919191;
}

.subscriptions-page .subscription-tabs .subscription-tab {
  border: none;
  border-bottom: 2px solid lightgray;
  color: gray;
}
.subscriptions-page .subscription-tabs .subscription-tab.active {
  border-bottom: 2px solid #0077d6;
  color: #0077d6;
  font-weight: 600;
}
.subscriptions-page .product_title {
  display: flex;
  align-items: center;
}
.subscriptions-page .product_title .product-card-name {
  margin-left: 10px;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 2px;
  text-transform: uppercase;
}
.subscriptions-page .product-card-header {
  display: flex;
  justify-content: space-between;
}
.subscriptions-page .product-card-header .product-card-price {
  font-size: 20px;
  font-weight: 800;
  color: #2A1D47;
}
.subscriptions-page .product-cards {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 1em;
  gap: 1em;
}
@media (max-width: 768px) {
  .subscriptions-page .product-cards {
    grid-template-columns: 1fr;
  }
}
.subscriptions-page .product-cards .product-card {
  border: 1px solid #D6DEE7;
  border-radius: 8px;
  padding: 0.8rem;
  flex-grow: 1;
}
.subscriptions-page .product-cards .product-card .product-card-feature p {
  font-size: 16px;
  font-weight: 500;
}
.subscriptions-page .product-cards .product-card .product-card-body {
  padding-left: 30px;
  padding-top: 5px;
}
.subscriptions-page .product-cards .product-card .product-card-daily-activities h4 {
  font-size: 14px;
  font-weight: 600;
  background: #7521B9;
  padding: 10px 15px;
  border-radius: 26px;
  color: #fff;
  display: inline-block;
}
.subscriptions-page .product-cards .product-card .product-card-daily-activities .product-card-inner {
  display: flex;
  flex-direction: column;
  font-weight: 600;
}
.subscriptions-page .product-cards .product-card .product-card-daily-activities .product-card-inner .product-card-header {
  display: flex;
  align-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 0.3rem;
}
.subscriptions-page .product-cards .product-card .product-card-daily-activities .product-card-inner .product-card-header .product-card-name {
  font-size: 1.2rem;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  margin-left: 0.5rem;
}
.subscriptions-page .product-cards .product-card .product-card-daily-activities .product-card-inner .product-card-header .product-card-active {
  font-size: small;
  background-color: gold;
  padding: 0.2rem;
  margin-left: 0.2rem;
  border-radius: 5px;
  text-align: center;
  align-self: start;
}
.subscriptions-page .product-cards .product-card .product-card-daily-activities .product-card-inner .product-card-header .product-card-price {
  margin-left: auto;
}
.subscriptions-page .subscription-actions {
  display: flex;
  gap: 1em;
  justify-content: right;
  margin: 10px 15px;
  align-items: center;
  text-align: center;
}
.subscriptions-page .subscription-actions .subscriptions-actions-wrong-platform-message {
  border: solid 1px red;
  width: 50%;
  border-radius: 1rem;
  padding: 1rem;
  margin-right: auto;
}

.profile_edit_sec .profile_img {
  text-align: center;
  margin: 30px auto;
  position: relative;
  background: #eaf1ff;
  border-radius: 50%;
  padding: 20px;
  height: 120px;
  width: 120px;
}
.profile_edit_sec .profile_img img {
  height: 80px;
  object-fit: contain;
}
.profile_edit_sec .profile_img .edit_pic {
  position: absolute;
  bottom: 0;
  right: 5px;
  background: #0077d6;
  border-radius: 50%;
  color: #fff;
  width: 30px;
  height: 30px;
  text-align: center;
  padding-left: 9px;
  padding-top: 3px;
}

.profile_page {
  margin-top: 15px;
  max-width: 1200px;
}

.left_settings {
  margin-bottom: 15px;
  background: #fff;
  border: 1px solid #e2e2e2;
  border-radius: 7px;
}
.left_settings ul {
  padding: 15px 20px 15px 15px;
  margin-bottom: 0;
}
.left_settings ul li {
  list-style: none;
  margin-bottom: 15px;
  position: relative;
}
.left_settings ul li.active::after {
  content: "\f105";
  font: normal normal normal 22px/1 FontAwesome;
  position: absolute;
  font-weight: 600;
  margin-top: 2px;
  right: 0;
  color: #0077d6;
}
.left_settings ul li:hover button {
  color: #0077d6;
}
.left_settings ul li.active button {
  color: #0077d6;
  font-weight: 600;
}
.left_settings ul li button {
  color: #717171;
  font-weight: 400;
  transition: 0.2s all ease;
  text-align: left;
}
.left_settings ul li button i {
  font-weight: 600;
  width: 18px;
  text-align: center;
  height: 18px;
  margin-right: 7px;
}
.left_settings ul li:last-child {
  margin-bottom: 0;
}

/********* Profile **********/
/*** Top header ****/
.top_header {
  width: auto;
  height: 97px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  box-shadow: 2px 3px 10px rgba(0, 0, 0, 0.08);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 9999;
  transition: 0.4s all ease;
  padding-left: 30px;
  padding-top: 15px;
  padding-bottom: 15px;
}
.top_header h2 {
  float: left;
  font-size: 18px;
  font-weight: 600;
  color: #0077d6;
  margin-bottom: 0;
  margin-top: 20px;
}
.top_header .search_n_profile {
  display: block;
  width: 100%;
}
.top_header .user_img {
  height: 53px;
  width: 53px;
  border-radius: 50%;
  padding: 9px;
  background: #eaf1ff;
}
.top_header .user_img img {
  height: 35px;
  object-fit: contain;
}
.top_header .account_area ul li {
  padding: 5px 0;
}
.top_header .account_area .dropdown-toggle {
  display: flex;
  align-items: center;
}
.top_header .account_area .dropdown-toggle::after {
  border-top: 0.5em solid;
  border-right: 0.45em solid transparent;
  border-bottom: 0;
  border-left: 0.45em solid transparent;
  color: #afafaf;
}
.top_header .account_area .dropdown-toggle .usr_name {
  font-size: 16px;
  font-weight: 500;
  color: #202020;
  margin-right: 7px;
}
.top_header .account_area .dropdown-menu {
  border: none;
  padding: 10px;
  box-shadow: 2px 3px 10px rgba(0, 0, 0, 0.16);
}
.top_header .account_area a,
.top_header .account_area button {
  padding-right: 30px;
  display: flex;
  align-items: center;
  justify-content: normal;
  color: #3d3d3d;
  font-weight: 500;
  font-size: 16px;
}
.top_header .account_area a:hover,
.top_header .account_area button:hover {
  background: none;
  color: #0077d6;
}
.top_header .account_area a i,
.top_header .account_area button i {
  margin-right: 10px;
  height: 15px;
  width: 15px;
}
.top_header .account_area a .usr_name,
.top_header .account_area button .usr_name {
  padding-right: 5px;
  font-weight: 500;
}

.search_result {
  position: absolute;
  background: #fff;
  display: block;
  width: 100%;
  z-index: 999;
  box-shadow: 2px 3px 10px rgba(0, 0, 0, 0.16);
}
.search_result ul {
  padding: 15px 15px 0 15px;
}
.search_result ul li {
  list-style: none;
  padding: 10px 0;
  border-bottom: 1px solid #ebebeb;
  transition: 0.2s all ease;
}
.search_result ul li:last-child {
  border: none;
}
.search_result ul li:hover {
  background-color: #f5f5f5;
}
.search_result ul li button {
  width: 100%;
  display: flex;
}
.search_result ul li button .actvt_blk {
  text-align: left;
  padding-left: 10px;
}
.search_result ul li button .actvt_blk h3 {
  font-size: 15px;
  font-weight: 600;
  color: #202020;
  margin-bottom: 0;
}
.search_result ul li button .actvt_blk h4 {
  font-size: 12px;
  font-weight: 500;
  color: #7a7a7a;
}
.search_result ul li img {
  height: 35px;
}

.account_area {
  float: right;
}

.search_box {
  width: 60%;
  position: relative;
  float: left;
  bottom: 10px;
}
.search_box .form-control {
  height: 48px;
  box-shadow: none !important;
  outline: none;
  color: #3e3e3e;
  font-size: 16px;
}
.search_box button.search_sub {
  position: absolute;
  top: 0px;
  background: none;
  right: 0px;
  width: 60px;
  border: none;
  outline: none;
  height: 50px;
}
.search_box button.search_sub i {
  color: #3e3e3e;
  font-size: 23px;
}
.search_box ::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #3e3e3e;
  opacity: 1; /* Firefox */
  font-size: 16px;
  font-weight: 600;
}
.search_box :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #3e3e3e;
  font-size: 16px;
  font-weight: 600;
}
.search_box ::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #3e3e3e;
  font-weight: 600;
  font-size: 16px;
}

.short_cut_links {
  margin-top: 5px;
  z-index: 99;
  position: absolute;
  bottom: 12px;
}
.short_cut_links ul {
  padding-left: 0px;
  margin-bottom: 0;
}
.short_cut_links ul li {
  list-style: none;
  float: left;
  margin-right: 10px;
}
.short_cut_links ul li button {
  color: #161616;
  font-weight: 500;
  border: 1px solid #e7e9ec;
  font-size: 12px;
  border-radius: 20px;
  background-color: #f2f7fd;
  padding: 5px 10px;
}
.short_cut_links ul li button:hover {
  background-color: #0077d6;
  color: #fff;
  border-color: #0077d6;
}

/*** Top header ****/
/***** Loader CSS ****/
.diamond_1 {
  height: 32px;
  width: 32px;
  top: 80px;
  left: 92px;
  position: absolute;
  background: url(../images/diamond.svg) no-repeat center;
  background-size: contain;
}

.diamond_2 {
  height: 32px;
  width: 32px;
  top: 103px;
  left: 135px;
  position: absolute;
  background: url(../images/diamond.svg) no-repeat center;
  background-size: contain;
}

.sparkles {
  z-index: 2;
  position: absolute;
}

.one {
  right: -114px;
  top: -15px;
  position: absolute;
  font-size: 40px;
  color: transparent;
  text-shadow: 0 0 white, -115px -75px white;
  animation: shine 0.5s linear infinite;
}
.one img {
  height: 30px;
}

.two {
  top: 22px;
  position: absolute;
  color: transparent;
  font-size: 25px;
  left: 127px;
  text-shadow: 0 0 white, 55px -115px white;
  animation: shine 0.5s linear infinite 0.25s;
}
.two img {
  height: 30px;
}

@keyframes shine {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.dot-one {
  animation: dot-one 2s infinite linear;
}

.dot-two {
  animation: dot-two 2s infinite linear;
}

.dot-three {
  animation: dot-three 2s infinite linear;
}

@keyframes dot-one {
  0% {
    opacity: 0;
  }
  15% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
@keyframes dot-two {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
@keyframes dot-three {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
@media (max-width: 700px) {
  .loading__letter {
    font-size: 50px;
  }
}
@media (max-width: 340px) {
  .loading__letter {
    font-size: 40px;
  }
}
.content_loader {
  background: #383838;
  background-size: 400% 400%;
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  opacity: 1;
  z-index: 9;
}
.content_loader .loading_txts {
  position: absolute;
  z-index: 99;
  color: #fff;
  bottom: 20%;
  font-size: 18px;
  font-weight: 500;
}
.content_loader .loading_txts span {
  font-size: 18px;
}
.content_loader .start_anim {
  height: 70px;
  width: 70px;
  background-color: transparent !important;
  background-size: 50px !important;
  bottom: 0px;
  position: relative;
}
.content_loader .progress {
  max-width: 150px;
  top: 60px;
  margin: auto;
  background: rgba(255, 255, 255, 0.2);
}
.content_loader .progress .bar {
  background: linear-gradient(-45deg, #a728ea, #3140c7, #a240cb, #249eff);
  animation: gradient 5s ease infinite;
  background-size: 400% 400%;
}
@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/***** Loader CSS ****/
/*******  Spnosor ***********/
.title_block_m .sponsor_tag {
  margin-top: 0;
}

.sponsor_tag {
  position: relative;
  height: 22px;
  margin-top: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
}
.sponsor_tag img {
  height: 24px !important;
  object-fit: contain;
  margin-left: 7px;
  margin-right: 4px;
}
.sponsor_tag h5 {
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 0;
}

/*******  Spnosor ***********/
/************** Verify Email ************/
.verify_email .modal-dialog {
  margin-top: 100px;
}
.verify_email .modal-header {
  border: none;
}
.verify_email .modal-header .btn_mod_close {
  position: absolute;
  right: 20px;
  top: 25px;
  z-index: 99;
}
.verify_email .modal-body {
  text-align: center;
}
.verify_email .modal-body .fgt_header {
  margin-top: 65px;
  padding: 30px;
}
.verify_email .modal-body .fgt_header h5 {
  font-size: 24px;
  font-weight: 600;
}
.verify_email .modal-body .fgt_header span {
  color: #0077d6;
  font-weight: 600;
}
.verify_email .modal-body .fgt_header p {
  color: #686868;
  margin-bottom: 25px;
}
.verify_email .modal-body .fgt_header h6 {
  font-size: 14px;
  color: #878787;
  font-weight: 400;
  margin-bottom: 25px;
}

.verify_m .verify_sec {
  max-width: 450px;
  margin-top: 20%;
}
.verify_m .verify_sec h2 {
  text-align: center;
}
.verify_m .verify_sec .verify_txt_m {
  font-size: 16px;
  margin-bottom: 10px;
  font-weight: 400;
  color: #747474;
  text-align: center;
}
.verify_m .verify_sec .verify_em_t2 {
  font-size: 18px;
  font-weight: 500;
  color: #333333;
  text-align: center;
}
.verify_m .verify_sec .em_img_block {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
}
.verify_m .verify_sec .em_img_block img {
  height: 300px;
}

/************** Verify Email ************/
/****** Signup - Login *********/
.reports_sec {
  display: flex;
  width: 100%;
  min-height: 500px;
}
.reports_sec iframe {
  width: 100%;
}

.start_anim {
  background-image: url(../images/loader3.gif) !important;
  background-size: 40px !important;
  background-position: 0 center !important;
  background-repeat: no-repeat !important;
  background-color: #d3e2ff !important;
  font-size: 0;
  cursor: not-allowed;
  pointer-events: none;
}

.start_anim2 {
  background-image: url(../images/logo_anim.gif) !important;
  background-size: 40px !important;
  background-position: center center !important;
  background-repeat: no-repeat !important;
  background-color: #d3e2ff !important;
  font-size: 0;
  cursor: not-allowed;
  pointer-events: none;
}

.left_block {
  padding: 0;
}

.signup_image {
  min-height: 100vh;
  height: 100%;
  background: url(../images/bg_login.png) no-repeat center center;
  background-size: cover;
  position: relative;
}

.logo_wrap {
  display: inline-block;
  width: 100%;
  position: relative;
  text-align: center;
  padding: 40px 0 0;
}
.logo_wrap img:hover {
  cursor: pointer;
}

.shape_block {
  position: relative;
  padding-top: 50px;
}
.shape_block .shape_1 {
  float: right;
  display: block;
  margin-right: 147px;
}
.shape_block .shape_1 img {
  height: 15em;
}
.shape_block .shape_2 {
  right: 355px;
  position: absolute;
  margin-top: 215px;
}
.shape_block .shape_2 img {
  height: 7em;
}

.login_text_block {
  position: absolute;
  bottom: 80px;
  left: 80px;
}
.login_text_block h1 {
  font-size: 3rem;
  font-weight: 800;
  letter-spacing: 3px;
  color: #3d3d3d;
}

.signup_form_area {
  max-width: 600px;
  margin: 10% auto 0;
  padding: 0 30px;
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: center;
}
.signup_form_area h3 {
  font-size: 18px;
  color: #7d7d7d;
  font-weight: 400;
  margin-bottom: 0;
}
.signup_form_area h2 {
  font-size: 28px;
  font-weight: 700;
  color: #0077d6;
}
.signup_form_area .form-group {
  margin-top: 30px;
}
.signup_form_area .form-group .form-field {
  margin-bottom: 15px;
  position: relative;
}

span.error_txt {
  color: #f94545;
  font-size: 13px;
  font-weight: 500;
  padding-bottom: 1px;
}

.login_check {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.check_area {
  margin-top: 10px;
}

.btn_area {
  margin-top: 30px;
}

.other_signup {
  margin-top: 40px;
}
.other_signup .sign_in_header {
  text-align: center;
  position: relative;
  max-width: 250px;
  margin: auto;
}
.other_signup .sign_in_header:before {
  content: "";
  position: absolute;
  border: 1px solid #ebebeb;
  width: 60px;
  left: 0;
  bottom: 7px;
}
.other_signup .sign_in_header:after {
  content: "";
  position: absolute;
  border: 1px solid #ebebeb;
  width: 60px;
  right: 0;
  bottom: 7px;
}
.other_signup .sign_in_header h5 {
  font-size: 14px;
  font-weight: 400;
  color: #8f8f8f;
}
.other_signup .signup_options {
  text-align: center;
  margin-top: 30px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.other_signup .signup_options .kep-login-facebook {
  padding: 0 15px;
  height: 53px;
  border-radius: 4px !important;
  margin-top: 4px;
  width: 300px;
  text-transform: initial;
  font-weight: 400;
  font-family: "Montserrat", sans-serif !important;
  font-size: 16px;
}
.other_signup .signup_options .btn_login {
  text-decoration: none;
  border-radius: 4px;
  height: 52px;
  width: 300px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 8px;
}
.other_signup .signup_options .btn_login.google {
  border: 2px solid #e6e6e6;
  color: #000000;
  background: #ffffff;
}
.other_signup .signup_options .btn_login.google img {
  transition: 0.2s all ease;
  position: relative;
  bottom: 0px;
  height: 25px;
  margin-right: 8px;
}
.other_signup .signup_options .btn_login.google:hover {
  background-color: #f5f5f5;
}
.other_signup .signup_options .btn_login.apple {
  border: 2px solid #202020;
  color: #fff;
  background: #202020;
}
.other_signup .signup_options .btn_login.apple img {
  transition: 0.2s all ease;
  position: relative;
  bottom: 0px;
  height: 25px;
  margin-right: 8px;
}
.other_signup .signup_options .btn_login.apple:hover {
  background-color: #383838;
}
.other_signup h4 {
  margin-top: 50px;
  font-size: 16px;
  text-align: center;
}
.other_signup h4 a {
  color: #0077d6;
}
.other_signup h4 a:hover {
  color: #5484e6;
}

.border_color {
  border-color: #0077d6;
}

.forgot_password .modal-header {
  border: none;
}
.forgot_password .modal-body {
  max-width: 400px;
  margin: auto;
  width: 100%;
}
.forgot_password .fgt_header {
  display: block;
  text-align: center;
  width: 100%;
}
.forgot_password .fgt_header h5 {
  font-size: 24px;
  color: #202020;
  margin-top: 15px;
  font-weight: 700;
}
.forgot_password .fgt_header p {
  color: #8a8a8a;
  font-size: 16px;
}
.forgot_password .btn_mod_close {
  background: none !important;
  outline: none !important;
  border: none;
  position: absolute;
  right: 30px;
  top: 30px;
}
.forgot_password .modal-dialog {
  max-width: 450px;
  margin-top: 150px;
}
.forgot_password .modal-content {
  border: none;
  padding: 30px 0;
}

.switch_signup {
  background: none;
  color: #0077d6;
  border: none;
  font-weight: 500;
  cursor: pointer;
}

/****** Signup - Login *********/
/***** Error Page ****/
.mx-50 {
  max-width: 140px;
  margin: 15px auto;
}

.error_block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 250px;
}
.error_block h3 {
  text-align: center;
  font-size: 26px;
  font-weight: bold;
  color: #202020;
  margin-top: 30px;
}
.error_block h2 {
  text-align: center;
  font-size: 26px;
  font-weight: bold;
  color: #28ad4e;
  margin-top: 20px;
}
.error_block p {
  font-size: 16px;
  color: #7c7c7c;
  text-align: center;
}
.error_block .error_txt {
  text-align: center;
}

/***** Error Page ****/
/******** Left Menu *********/
.menu_collapse {
  position: fixed;
  left: 22px;
  height: 30px;
  width: 40px;
  top: 40px;
  z-index: 99;
}
.menu_collapse:focus-visible {
  outline: none;
}
.menu_collapse i {
  font-size: 20px;
  color: #333333;
}

.left_menu {
  background: #fff;
  border-right: 1px solid #e6e6e6;
  min-height: 100vh;
  height: 100%;
  position: fixed;
  width: 250px;
  left: 0;
  top: 0;
  transition: 0.4s all ease;
}

.prem_usr {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 68px;
  margin-left: auto;
  position: relative;
  height: 18px;
  bottom: 5px;
  font-size: 9px;
  color: #fff;
  font-weight: 600;
  letter-spacing: 1px;
  border-radius: 30px;
  background: linear-gradient(to right, #0077d6, #9a39ea);
}

.menu_shrink {
  padding-left: 75px;
}
.menu_shrink .left_menu {
  width: 75px;
}
.menu_shrink .top_header {
  left: 75px;
}
.menu_shrink .menu_sec ul li a {
  opacity: 0;
}
.menu_shrink .logo_sec span {
  opacity: 0;
}
.menu_shrink .logo_sec img {
  opacity: 0;
}

.common_acc {
  max-width: 155px;
  margin-top: 17px;
}

.av_in_app .coming_soon {
  background: linear-gradient(to top, #3063cc, #6497ff);
}
.av_in_app p {
  margin-bottom: 20px !important;
}

.logo_sec {
  padding: 15px 25px;
  width: 100%;
  text-align: right;
  position: relative;
}
.logo_sec:hover {
  cursor: pointer;
}
.logo_sec img {
  max-height: 70px;
}

.menu_sec {
  margin-top: 30px;
}
.menu_sec ul {
  padding-left: 13px;
}
.menu_sec ul li {
  list-style: none;
  margin-bottom: 10px;
  padding: 15px 20px;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  position: relative;
}
.menu_sec ul li .icon-premium:before {
  content: "";
  background: url(../images/icons/premium.svg) no-repeat center;
  height: 25px;
  display: inline-block;
  width: 25px;
  position: relative;
  top: 4px;
  filter: grayscale(1);
  opacity: 0.7;
  transition: 0.2s all ease;
}
.menu_sec ul li .link_cl {
  color: #7d7c7c;
  font-weight: 500;
}
.menu_sec ul li .link_cl a {
  color: #7d7c7c;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 53px;
}
.menu_sec ul li .link_cl i {
  padding-right: 18px;
  font-size: 20px;
  transition: 0.2s all ease;
}
.menu_sec ul li .link_cl:hover a, .menu_sec ul li .link_cl:hover i, .menu_sec ul li .link_cl:hover .icon-premium:before {
  color: #0077d6;
  filter: none;
}
.menu_sec ul li.active {
  background-color: #e8efff;
}
.menu_sec ul li.active i {
  color: #0077d6;
}
.menu_sec ul li.active .icon-premium:before {
  filter: none;
  opacity: 1;
}
.menu_sec ul li.active a {
  color: #0077d6;
}

/******** Left Menu *********/
/******** MCQ Modal ********/
.progz {
  position: absolute;
  width: 100%;
  height: 4px;
  left: 0;
  bottom: 0;
  background: #ccddff;
  z-index: 0;
}
.progz .progz_slide {
  background: #5f93ff;
  height: 100%;
}

.mcq_end {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 0;
}
.mcq_end h4 {
  margin-top: 30px;
  font-weight: 600;
}
.mcq_end .score_txt {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #edf2fb;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
.mcq_end .score_txt h3 {
  font-size: 20px;
}
.mcq_end .score_txt h3 span {
  font-size: 40px;
  font-weight: 700;
}
.mcq_end .show_mcq {
  padding-top: 30px;
}

.quiz_modal .btn_mod_close {
  position: absolute;
  right: 25px;
  top: 25px;
}
.quiz_modal .modal-dialog {
  margin-top: 100px;
  max-width: 700px;
}
.quiz_modal .fgt_header {
  width: 100%;
  position: relative;
}
.quiz_modal .modal-header {
  background: #e8eff9;
  padding: 30px;
  border: none;
  position: relative;
}
.quiz_modal .modal-header h5 {
  font-size: 20px;
  font-weight: 600;
  color: #0077d6;
  margin-bottom: 4px;
}
.quiz_modal .modal-header h6 {
  font-size: 13px;
  font-weight: 500;
  color: #7c8da4;
}
.quiz_modal .modal-header .mcq_progress {
  width: 93%;
  margin-top: 15px;
  position: relative;
  display: inline-block;
  margin-left: 3px;
}
.quiz_modal .modal-header .mcq_progress .progress {
  background: #fff;
  margin: 0;
  width: 100%;
}
.quiz_modal .modal-header .quiz_num {
  position: absolute;
  right: 0px;
  top: 51px;
  font-size: 17px;
  font-weight: 600;
  letter-spacing: 1px;
}
.quiz_modal .modal-body {
  background: #f8f9fc;
}
.quiz_modal .mcq_data {
  padding: 20px;
}
.quiz_modal .mcq_data h4 {
  font-size: 18px;
  color: #202020;
  font-weight: 700;
}
.quiz_modal .mcq_data ul {
  padding: 0;
  margin-top: 40px;
}
.quiz_modal .mcq_data ul li {
  list-style: none;
}
.quiz_modal .mcq_data ul li button {
  width: 100%;
  min-height: 75px;
  background: #fff;
  box-shadow: 2px 3px 10px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  display: flex;
  align-items: center;
  list-style: none;
  padding: 15px 20px;
  margin: 15px 0;
  transition: 0.2s all ease;
  position: relative;
}
.quiz_modal .mcq_data ul li button p {
  margin-bottom: 0;
  text-align: left;
  line-height: 22px;
  padding-right: 20px;
  font-weight: 600;
}
.quiz_modal .mcq_data ul li button:hover {
  background: #d7e1ff;
}
.quiz_modal .mcq_data ul .right_ans button {
  background-color: #bdffdf !important;
}
.quiz_modal .mcq_data ul .right_ans button:after {
  content: "\f00c";
  font: normal normal normal 14px/1 FontAwesome;
  font-size: 1.5em;
  position: absolute;
  right: 20px;
  color: #38af75;
}
.quiz_modal .mcq_data ul .wrong_ans button {
  background-color: #ffcfcf !important;
}
.quiz_modal .mcq_data ul .wrong_ans button:after {
  content: "\f00d";
  font: normal normal normal 14px/1 FontAwesome;
  font-size: 1.5em;
  position: absolute;
  right: 20px;
  color: #dd6d6d;
}
.quiz_modal .mcq_submit {
  margin: 20px;
  padding-top: 30px;
  border-top: 1px solid #ddd;
  text-align: right;
}
.quiz_modal .mcq_submit .btn_common {
  max-width: 130px;
}

/******** MCQ Modal ********/
/******** Premium Content Modal ********/
.prm_content_popup .modal-content {
  overflow: hidden;
}
.prm_content_popup .modal-dialog {
  margin-top: 100px;
  max-width: 820px;
}
.prm_content_popup .btn_mod_close {
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 9;
}
.prm_content_popup .modal-body {
  padding: 0;
}
.prm_content_popup .prm_wrap {
  display: flex;
  align-items: flex-start;
  height: auto;
}
.prm_content_popup .prm_left {
  width: 70%;
  padding: 35px;
}
.prm_content_popup .prm_left p {
  line-height: 22px;
}
.prm_content_popup .prm_left_header {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  position: relative;
}
.prm_content_popup .prm_left_header img {
  height: 60px;
}
.prm_content_popup .prm_left_header .title_block_m {
  margin-left: 15px;
}
.prm_content_popup .prm_left_header h2 {
  font-size: 20px;
  font-weight: 700;
  color: #202020;
}
.prm_content_popup .exp_video_sec {
  display: flex;
  align-items: center;
  padding: 25px;
  background: #f0f8ff;
  justify-content: space-between;
}
.prm_content_popup .exp_video_sec .exp_vid {
  background: url(../images/mobile_frame.png) no-repeat left center;
  height: 340px;
  border-radius: 22px;
  box-shadow: 16px 16px 35px rgba(0, 0, 0, 0.35);
  background-size: cover;
  width: 165px;
}
.prm_content_popup .exp_video_sec .exp_vid img {
  height: 360px;
  object-fit: contain;
  position: relative;
  padding: 30px 0px;
  left: 11px;
}
.prm_content_popup .exp_video_sec .exp_txt {
  text-align: center;
  max-width: 230px;
  margin-left: 5px;
}
.prm_content_popup .exp_video_sec .exp_txt h2 {
  background-color: #0077d6;
  padding: 4px 7px;
  margin: 15px 0;
  text-align: center;
  font-weight: 500;
  font-size: 11px;
  color: #fff;
  display: inline-block;
  letter-spacing: 2px;
  text-transform: uppercase;
}
.prm_content_popup .exp_video_sec .exp_txt h3 {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 20px;
  color: #202020;
}
.prm_content_popup .prm_right {
  height: 100%;
  display: flex;
  width: 30%;
  right: 0;
  position: absolute;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to bottom, #d7e3ff, #f2e6f9);
}
.prm_content_popup .prm_right h2,
.prm_content_popup .prm_right .play_link {
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 1px;
  color: #0077d6;
  margin-bottom: 20px;
  display: block;
}
.prm_content_popup .prm_right .qr_code .qr_block {
  background: #fff;
  padding: 5px;
  border: 1px solid #484747;
  text-align: center;
  height: 150px;
  width: 150px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.prm_content_popup .prm_right h5 {
  font-size: 12px;
  text-align: left;
  max-width: 150px;
  margin-top: 5px;
}
.prm_content_popup .prm_right .play_store {
  display: inline-flex;
  flex-direction: column;
  margin-top: 30px;
}
.prm_content_popup .prm_right .play_store a {
  margin-bottom: 10px;
}
.prm_content_popup .prm_right .play_store a img {
  height: 43px;
  object-fit: contain;
}

.btn_premium {
  height: 40px;
  width: 165px;
  display: inline-flex;
  border-radius: 30px;
  background: linear-gradient(to bottom, #084bb7, #a728ea);
  color: #fff;
  font-weight: 600;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin: auto;
  transition: 0.4s all ease;
}
.btn_premium:hover {
  background: linear-gradient(to bottom, #a728ea, #084bb7);
  color: #fff;
}

/******** Premium Content Modal ********/
/*********** Standard LEssons ****/
@keyframes hideBlock {
  to {
    visibility: hidden;
  }
}
@keyframes hideControls {
  to {
    opacity: 0;
  }
}
.pan_info {
  position: absolute;
  right: 15px;
  bottom: 15px;
  width: 25px;
  text-align: center;
  border-radius: 50%;
  padding: 1px;
  height: 25px;
  background: #ffffff;
  border: 1px solid #ddd;
}
.pan_info:hover .tooltip_pan {
  visibility: visible !important;
}
.pan_info .tooltip_pan {
  position: absolute;
  background: #fff;
  box-shadow: 6px 9px 25px rgba(0, 0, 0, 0.16);
  font-size: 14px;
  width: 300px;
  height: 50px;
  font-weight: 500;
  bottom: 40px;
  right: 15px;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s all ease-in-out;
  -moz-animation: hideBlock 0s ease-in 5s forwards;
  /* Firefox */
  -webkit-animation: hideBlock 0s ease-in 5s forwards;
  /* Safari and Chrome */
  -o-animation: hideBlock 0s ease-in 5s forwards;
  /* Opera */
  animation: hideBlock 0s ease-in 5s forwards;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
.pan_info .tooltip_pan:after {
  width: 0px;
  height: 0px;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 27px solid #fff;
  content: "";
  position: absolute;
  right: -3px;
  bottom: -15px;
  transform: rotate(336deg);
}
.pan_info .tooltip_pan img {
  height: 20px;
  width: 20px;
  margin: 0 10px 0 0 !important;
}
.pan_info .tooltip_pan p {
  margin: 0;
}

.btn_outline {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
  border: 1px solid #0077d6;
  color: #0077d6;
  border-radius: 3px;
  font-weight: 500;
  height: 45px;
  transition: 0.2s all ease;
}
.btn_outline:hover {
  background-color: #0077d6;
  color: #fff;
}

.show_mcq {
  display: flex;
  width: 100%;
  justify-content: center;
}
.show_mcq button {
  margin: 0 7px;
  width: 120px;
}

.std_lessons::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.std_lessons::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

.std_lessons::-webkit-scrollbar-thumb {
  background-color: #7e899f;
}

.img_box {
  width: 100%;
}

.std_lessons {
  display: flex;
  align-items: flex-start;
  width: 100%;
  background: #fff;
  flex-direction: column;
  margin: 20px 0 20px 0;
  height: 600px;
}
.std_lessons .std_img_block {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 20px;
  width: 100%;
}
.std_lessons .std_img_block .anim_video {
  position: relative;
}
.std_lessons .std_img_block .anim_video button {
  position: absolute;
  z-index: 9;
  top: 15px;
  right: 15px;
}
.std_lessons .std_img_block .anim_video > div {
  height: 300px;
}
.std_lessons .std_img_block img {
  margin: auto;
  max-height: 300px;
  object-fit: contain;
}
.std_lessons .std_text_block {
  padding: 0px 20px 20px;
  width: 100%;
  overflow-y: auto;
}
.std_lessons .std_text_block h4 {
  margin-bottom: 15px;
  font-size: 20px;
  color: #202020;
  font-weight: 700;
}

.std_wrap {
  position: relative;
}
.std_wrap .slick-next,
.std_wrap .slick-prev {
  top: unset;
  bottom: -65px;
  height: 50px;
  width: 45px;
}
.std_wrap .slick-next:before {
  content: "\f105";
}
.std_wrap .slick-prev:before {
  content: "\f104";
}
.std_wrap .slick-prev {
  left: 0;
  right: 135px;
  margin: auto;
}
.std_wrap .slick-next {
  left: 125px;
  right: 0;
  margin: auto;
}
.std_wrap .slick-next:before,
.std_wrap .slick-prev:before {
  height: 50px;
  width: 50px;
  background: #fff;
  border-radius: 2px;
  color: #0077d6;
  box-shadow: 2px 3px 10px rgba(0, 0, 0, 0.08);
  display: block;
  text-align: center;
  font: normal normal normal 45px/1 FontAwesome;
}
.std_wrap .slider_num {
  display: flex;
  align-items: center;
  justify-content: center;
}
.std_wrap .slider_num h3 {
  font-size: 19px;
  font-weight: bold;
  margin: 5px;
  text-align: center;
}
.std_wrap .std_lesson_progress {
  position: relative;
}
.std_wrap .std_lesson_progress .progress {
  margin: auto;
  width: 100%;
  bottom: 20px;
  height: 6px;
  border-radius: 0;
  overflow: visible;
  transition: 0.2s all ease;
}
.std_wrap .std_lesson_progress .progress:hover {
  height: 9px;
}
.std_wrap .std_lesson_progress .progress:hover .bar::after {
  opacity: 1;
}
.std_wrap .std_lesson_progress .progress .bar {
  z-index: 999;
  transition: 0.2s all ease;
  pointer-events: none;
}
.std_wrap .std_lesson_progress .progress .bar:after {
  position: absolute;
  height: 18px;
  width: 18px;
  background: #3063cc;
  border-radius: 50%;
  content: "";
  right: -2px;
  bottom: -4px;
  z-index: 9;
  opacity: 0;
  transition: 0.2s all ease;
}

.activity_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.activity_header .left-head {
  display: flex;
  align-items: center;
  padding-right: 5px;
}
.activity_header .right-head .btn_common,
.activity_header .right-head .btn_grey {
  position: relative;
  overflow: hidden;
}
.activity_header .right-head .btn_common i,
.activity_header .right-head .btn_grey i {
  padding-left: 5px;
}
.activity_header .right-head .btn_common .quiz_label,
.activity_header .right-head .btn_grey .quiz_label {
  white-space: nowrap;
}
.activity_header img {
  height: 60px;
}
.activity_header h2 {
  font-size: 24px;
  color: #202020;
  font-weight: bold;
  margin-left: 15px;
}

.skel_box {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
}

.skeleton-box {
  display: inline-flex;
  position: relative;
  overflow: hidden;
  background-color: #dfdfdf;
  margin-bottom: 10px;
}

.bx1 {
  width: 25em;
  height: 17em;
}

.bx2 {
  width: 45em;
  height: 1.5em;
}

.bx3 {
  width: 35em;
  height: 1em;
}

.skeleton-box::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.2) 20%, rgba(255, 255, 255, 0.5) 60%, rgba(255, 255, 255, 0));
  -webkit-animation: shimmer 2s infinite;
  animation: shimmer 2s infinite;
  content: "";
}

@-webkit-keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
/*********** Standard LEssons ****/
/************ Offer Section*********/
.offer_popup .modal-content {
  background: #eaf0fa;
  padding: 20px;
  margin-top: 150px;
  border-radius: 0;
}
.offer_popup .btn_mod_close {
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 99;
}
.offer_popup .modal-header {
  padding: 0;
  border: none;
}
.offer_popup .offer_block .offer_head h3 {
  font-size: 20px;
  position: relative;
  margin-top: 10px;
  color: #161616;
}
.offer_popup .offer_block .offer_head h3::after {
  position: absolute;
  width: 54px;
  height: 5px;
  border-radius: 10px;
  content: "";
  left: 0;
  top: -10px;
  background: #9a39ea;
}
.offer_popup .offer_block .offer_sec {
  margin-top: 20px;
}
.offer_popup .offer_block .offer_sec .off_nj {
  font-size: 60px;
  font-weight: 700;
  color: #161616;
  padding-left: 70px;
}
.offer_popup .offer_block .offer_sec h1 {
  font-size: 60px;
  font-weight: 700;
  color: #161616;
}
.offer_popup .offer_block .offer_sec h1 .slash_rt {
  font-size: 50px;
  transform: rotate(16deg);
  position: absolute;
  top: 78px;
  margin-left: 7px;
}
.offer_popup .offer_block .offer_sec h5 {
  color: #161616;
  font-size: 16px;
}
.offer_popup .offer_block .offer_details {
  display: flex;
  margin-top: 50px;
}
.offer_popup .offer_block .offer_details .off_opt {
  width: 50%;
  position: relative;
}
.offer_popup .offer_block .offer_details .off_opt .bill_yearly {
  background: white;
  padding: 3px 5px;
}
.offer_popup .offer_block .offer_details .off_opt .bill_yearly h6 {
  font-size: 0.64rem;
  font-weight: 600;
  margin-bottom: 0;
  text-align: center;
}
.offer_popup .offer_block .offer_details .off_opt .bill_yearly h6 span {
  margin-left: 3px;
  text-decoration: line-through;
}
.offer_popup .offer_block .offer_details .off_opt .per_mo {
  font-size: 14px;
  display: inline-block;
  margin-bottom: 0;
  padding-left: 5px;
  margin-top: 4px;
}
.offer_popup .offer_block .offer_details .off_opt h5 {
  font-size: 16px;
  color: #161616;
  text-transform: uppercase;
  letter-spacing: 2px;
}
.offer_popup .offer_block .offer_details .off_opt h2 {
  font-size: 28px;
  font-weight: 700;
  color: #9a39ea;
  position: relative;
  display: flex;
  align-items: center;
}
.offer_popup .offer_block .offer_details .off_opt h2 span {
  font-size: 16px;
  font-weight: 500;
  text-decoration: line-through;
  padding-left: 15px;
}
.offer_popup .offer_block .offer_details .off_opt:nth-child(2) {
  padding-left: 28px;
  border-left: 1px solid #161616;
}
.offer_popup .offer_block .off_btn_blk {
  margin-top: 50px;
  display: block;
}
.offer_popup .offer_block .off_btn_blk .btn_exit {
  margin: auto;
  display: block;
  margin-bottom: 10px;
  color: #979797;
  font-weight: 300;
}
.offer_popup .offer_block .off_btn_blk .btn_grab {
  display: inline-flex;
  width: 100%;
  border: 1px solid #161616;
  height: 60px;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 500;
  transition: 0.2s all ease;
}
.offer_popup .offer_block .off_btn_blk .btn_grab:hover {
  background: #9a39ea;
  border-color: #9a39ea;
  color: #fff;
}

/************ Offer Section*********/
/************ Time Slider for Animated **************/
.time_slider {
  display: flex;
  justify-content: center;
  /* === range commons === */
  /* === WebKit specific styles === */
  /* === Firefox specific styles === */
}
.time_slider input[type=range] {
  font-size: 1.15rem;
  width: 12.5em;
}
.time_slider input[type=range] {
  color: #0077d6;
  --thumb-height: 1.125em;
  --track-height: 0.125em;
  --track-color: rgba(0, 0, 0, 0.2);
  --brightness-hover: 120%;
  --brightness-down: 100%;
  --clip-edges: 0.125em;
}
.time_slider input[type=range] {
  position: relative;
  background: rgba(255, 255, 255, 0);
  overflow: hidden;
}
.time_slider input[type=range]:active {
  cursor: grabbing;
}
.time_slider input[type=range]:disabled {
  filter: grayscale(1);
  opacity: 0.3;
  cursor: not-allowed;
}
.time_slider input[type=range],
.time_slider input[type=range]::-webkit-slider-runnable-track,
.time_slider input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  transition: all ease 100ms;
  height: var(--thumb-height);
}
.time_slider input[type=range]::-webkit-slider-runnable-track,
.time_slider input[type=range]::-webkit-slider-thumb {
  position: relative;
}
.time_slider input[type=range]::-webkit-slider-thumb {
  --thumb-radius: calc((var(--thumb-height) * 0.2) - 1px);
  --clip-top: calc((var(--thumb-height) - var(--track-height)) * 0.5 - 0.5px);
  --clip-bottom: calc(var(--thumb-height) - var(--clip-top));
  --clip-further: calc(100% + 1px);
  --box-fill: calc(-100vmax - var(--thumb-width, var(--thumb-height))) 0 0 100vmax currentColor;
  width: var(--thumb-width, var(--thumb-height));
  background: linear-gradient(currentColor 0 0) scroll no-repeat left center/50% calc(var(--track-height) + 1px);
  background-color: currentColor;
  box-shadow: var(--box-fill);
  border-radius: var(--thumb-width, var(--thumb-height));
  filter: brightness(100%);
  clip-path: polygon(100% -1px, var(--clip-edges) -1px, 0 var(--clip-top), -100vmax var(--clip-top), -100vmax var(--clip-bottom), 0 var(--clip-bottom), var(--clip-edges) 100%, var(--clip-further) var(--clip-further));
}
.time_slider input[type=range]:hover::-webkit-slider-thumb {
  filter: brightness(var(--brightness-hover));
  cursor: grab;
}
.time_slider input[type=range]:active::-webkit-slider-thumb {
  filter: brightness(var(--brightness-down));
  cursor: grabbing;
}
.time_slider input[type=range]::-webkit-slider-runnable-track {
  background: linear-gradient(var(--track-color) 0 0) scroll no-repeat center/100% calc(var(--track-height) + 1px);
}
.time_slider input[type=range]:disabled::-webkit-slider-thumb {
  cursor: not-allowed;
}
.time_slider input[type=range],
.time_slider input[type=range]::-moz-range-track,
.time_slider input[type=range]::-moz-range-thumb {
  appearance: none;
  transition: all ease 100ms;
  height: var(--thumb-height);
}
.time_slider input[type=range]::-moz-range-track,
.time_slider input[type=range]::-moz-range-thumb,
.time_slider input[type=range]::-moz-range-progress {
  background: rgba(255, 255, 255, 0);
}
.time_slider input[type=range]::-moz-range-thumb {
  background: currentColor;
  border: 0;
  width: var(--thumb-width, var(--thumb-height));
  border-radius: var(--thumb-width, var(--thumb-height));
  cursor: grab;
}
.time_slider input[type=range]:active::-moz-range-thumb {
  cursor: grabbing;
}
.time_slider input[type=range]::-moz-range-track {
  width: 100%;
  background: var(--track-color);
}
.time_slider input[type=range]::-moz-range-progress {
  appearance: none;
  background: currentColor;
  transition-delay: 30ms;
}
.time_slider input[type=range]::-moz-range-track,
.time_slider input[type=range]::-moz-range-progress {
  height: calc(var(--track-height) + 1px);
  border-radius: var(--track-height);
}
.time_slider input[type=range]::-moz-range-thumb,
.time_slider input[type=range]::-moz-range-progress {
  filter: brightness(100%);
}
.time_slider input[type=range]:hover::-moz-range-thumb,
.time_slider input[type=range]:hover::-moz-range-progress {
  filter: brightness(var(--brightness-hover));
}
.time_slider input[type=range]:active::-moz-range-thumb,
.time_slider input[type=range]:active::-moz-range-progress {
  filter: brightness(var(--brightness-down));
}
.time_slider input[type=range]:disabled::-moz-range-thumb {
  cursor: not-allowed;
}

/************ Time Slider for Animated **************/
/************ Premium Section ******/
.premium_blocks {
  max-width: 900px;
  margin: 30px auto 0;
}
.premium_blocks .p_block.premium_set {
  background: linear-gradient(to bottom, #0077d6, #9a39ea);
}
.premium_blocks .p_block.premium_set .p_mode {
  color: #fff;
}
.premium_blocks h4 {
  color: #fff;
}
.premium_blocks h4 span {
  color: #fff;
}
.premium_blocks .p_block_contents ul li::before {
  color: #fff;
}
.premium_blocks .p_block_contents ul li p {
  color: #fff;
}
.premium_blocks .p_block_contents ul li.web_mob::after {
  background: url(../images/web_mob_white.svg) no-repeat center center;
}
.premium_blocks .p_block_contents ul li.mob::after {
  background: url(../images/mobile_icon_white.svg) no-repeat center center;
}
.premium_blocks .btn_common_white {
  background: #fff;
  width: 90%;
  height: 47px;
  border-radius: 3px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 600;
  color: #923de8;
  transition: 0.3s all ease;
  opacity: 0.9;
}
.premium_blocks .btn_common_white:hover {
  opacity: 1;
}
.premium_blocks .btm_button h6 {
  font-size: 12px;
  color: #fff;
  font-weight: 300 !important;
  opacity: 0.85;
  margin-bottom: 12px;
}

.p_block.free {
  background: #fff;
  margin-bottom: 15px;
}
.p_block.free .p_mode {
  color: #6a6a6a;
}
.p_block.free h4 {
  color: #202020;
}
.p_block.free h4 span {
  color: #a1a1a1;
}
.p_block.free .p_block_contents ul li::before {
  color: #a9a9a9;
}
.p_block.free .p_block_contents ul li p {
  color: #202020;
}
.p_block.free .p_block_contents ul li.web_mob::after {
  background: url(../images/web_mob_grey.svg) no-repeat center center;
}
.p_block.free .p_block_contents ul li.mob::after {
  background: url(../images/mobile_icon.svg) no-repeat center center;
}

.p_block {
  padding: 20px 30px 30px 30px;
  box-shadow: 2px 3px 10px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  height: 500px;
  position: relative;
  overflow: hidden;
}
.p_block .offer_label {
  position: absolute;
  top: 12px;
  right: -35px;
  background: #ff3535;
  padding: 4px 30px;
  font-size: 13px;
  color: #fff;
  transform: rotate(45deg);
  font-weight: 700;
}
.p_block .p_block_top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.p_block .p_block_top .p_mode {
  text-transform: uppercase;
  font-size: 13px;
  letter-spacing: 2px;
  font-weight: 600;
}
.p_block .p_block_top h4 {
  font-size: 35px;
  font-weight: bold;
}
.p_block .p_block_top h4 span {
  font-size: 17px;
  font-weight: 600;
  margin-right: 5px;
}
.p_block .p_block_contents {
  margin-top: 30px;
}
.p_block .p_block_contents ul {
  padding-left: 0;
}
.p_block .p_block_contents ul .web_mob::after {
  content: "";
  position: absolute;
  height: 20px;
  width: 40px;
  right: 0;
  top: 0;
}
.p_block .p_block_contents ul .mob::after {
  content: "";
  position: absolute;
  height: 20px;
  width: 40px;
  right: 0;
  top: 0;
}
.p_block .p_block_contents ul li {
  padding-left: 25px;
  margin-bottom: 25px;
  list-style: none;
  position: relative;
}
.p_block .p_block_contents ul li::before {
  content: "\f058";
  font: normal normal normal 18px/1 FontAwesome;
  position: absolute;
  top: 3px;
  left: 0;
}
.p_block .p_block_contents ul li p {
  margin-bottom: 0;
  max-width: 300px;
  font-weight: 500;
}
.p_block .btm_button {
  text-align: center;
  position: absolute;
  bottom: 20px;
  margin: auto;
  width: 100%;
  height: auto;
  left: 0;
  right: 0;
}
.p_block .btm_button span {
  text-transform: uppercase;
  letter-spacing: 3px;
  font-weight: 600;
  color: #6a6a6a;
  font-size: 14px;
  margin-bottom: 10px;
}

.pay_route {
  max-width: 300px;
  margin: 5px auto;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.pay_route span {
  font-weight: 600;
  color: #202020;
}
.pay_route input[type=checkbox] {
  height: 0;
  width: 0;
  visibility: hidden;
}
.pay_route label {
  cursor: pointer;
  text-indent: -9999px;
  width: 50px;
  height: 26px;
  background: #0077d6;
  display: block;
  border-radius: 100px;
  bottom: 12px;
  position: relative;
}
.pay_route label:after {
  content: "";
  position: absolute;
  top: 3px;
  left: 4px;
  width: 20px;
  height: 20px;
  background: #fff;
  border-radius: 90px;
  transition: 0.3s;
}
.pay_route input:checked + label {
  background: #0077d6;
}
.pay_route input:checked + label:after {
  left: calc(100% - 5px);
  transform: translateX(-100%);
}
.pay_route label:active:after {
  width: 25px;
}

.premium_header {
  text-align: center;
  margin-top: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.premium_header span {
  font-size: 34px;
  font-weight: bold;
  color: #202020;
  margin-left: 10px;
}

/************ Premium Section ******/
/********** Main Content ******/
.module_wrap {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.module_btn {
  background: #fff;
  height: 200px;
  width: 240px;
  font-size: 16px;
  font-weight: bold;
  margin: 15px;
  color: #0077d6;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 2px 3px 10px rgba(0, 0, 0, 0.08);
}

.content_block {
  padding: 30px;
}
.content_block .content_heading {
  font-size: 20px;
  font-weight: 700;
}

.video_slider {
  margin-top: 30px;
}
.video_slider .slick-arrow:before {
  color: #bababa;
}
.video_slider .slick-arrow:hover::before {
  color: #fff !important;
}
.video_slider .slick-next,
.video_slider .slick-prev {
  top: -20px;
  width: 40px;
  height: 40px;
  position: absolute;
  left: unset;
  background: #ffffff;
  border-radius: 4px;
}
.video_slider .slick-next:hover,
.video_slider .slick-prev:hover {
  background: #0077d6;
}
.video_slider .slick-next:hover .slick-next:before, .video_slider .slick-next:hover [dir=rtl] .slick-prev:before,
.video_slider .slick-prev:hover .slick-next:before,
.video_slider .slick-prev:hover [dir=rtl] .slick-prev:before {
  color: #fff;
}
.video_slider .slick-prev {
  right: 80px;
}
.video_slider .slick-next {
  right: 35px;
}
.video_slider .slick-next:before,
.video_slider [dir=rtl] .slick-prev:before {
  content: "\f105";
  font: normal normal normal 14px/1 FontAwesome;
  font-size: 30px;
}
.video_slider .slick-prev:before {
  content: "\f104";
  font: normal normal normal 14px/1 FontAwesome;
  font-size: 30px;
  color: #bababa;
}
.video_slider .video_item {
  height: 250px;
}

.launch_soon h5 {
  font-size: 11px;
  margin: 0;
  padding: 7px 10px;
  color: #fff;
  font-weight: 600;
}

.launch_soon {
  position: absolute;
  bottom: -40px;
  text-align: center;
  background: linear-gradient(to bottom, #ff7272, #e91e63);
  left: 0;
  right: 0;
  margin: auto;
  max-width: 100%;
  transition: 0.2s all ease;
}

.cmng_sn {
  opacity: 0.6;
}

.cmng_sn:focus .launch_soon {
  bottom: 0px !important;
}

.video_item {
  background: #fff;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 2px 3px 10px rgba(0, 0, 0, 0.08);
  transition: 0.2s all ease;
}
.video_item:hover {
  transform: scale(1.03);
  background: #f4faff;
  opacity: 1;
}

.content_links h3 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0;
}
.content_links h4 {
  font-size: 18px;
  margin-top: 30px;
  margin-bottom: 20px;
}
.content_links .video_item {
  min-height: 240px;
  height: 100%;
}
.content_links .video_item:hover {
  cursor: pointer;
}
.content_links .link_type {
  color: #202020;
  padding: 2px 5px;
  margin: 0px 10px 5px 0px;
  display: inline-flex;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  position: relative;
}
.content_links .link_type.lesson {
  background-color: #ffea9e;
}
.content_links .link_type.exp {
  background-color: #cbdcff;
}
.content_links .link_type.topic {
  background-color: #f1aaff;
}
.content_links .link_type.animated_less {
  background-color: #aaffdc;
}
.content_links .act_icon {
  max-height: 70px;
  margin: 10px auto;
  width: 70px;
}
.content_links .act_icon img {
  object-fit: contain;
}
.content_links .act_title {
  padding: 0px 20px;
  text-align: center;
  font-weight: 700;
  color: #202020;
  font-size: 16px;
  margin-top: 20px;
  margin-bottom: 0;
}

.premium_icon::after {
  content: "";
  background: url(../images/diamond.svg) no-repeat center center;
  height: 30px;
  width: 30px;
  display: block;
  position: absolute;
  top: -10px;
  left: 40px;
  z-index: 9;
  background-color: transparent;
}

.premium_item .video_item::after {
  content: "";
  background: url(../images/premium_icon.png) no-repeat center center;
  height: 30px;
  width: 30px;
  display: block;
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 9;
  background-color: transparent;
}

.premium_item {
  position: relative;
}
.premium_item .video_item {
  background: linear-gradient(#faefff, #ecf2ff);
  position: relative;
}
.premium_item .video_item .prm_icon {
  position: absolute;
  right: 10px;
  top: 10px;
}

.main_topics .content_links {
  height: 100%;
}
.main_topics .content_links .video_item {
  position: relative;
  padding: 15px 20px 25px 20px;
}
.main_topics .content_links .video_item p {
  text-align: left;
  padding: 10px 10px 0 10px;
  font-size: 14px;
  margin-bottom: 0;
}
.main_topics .content_links .video_item .link_type h6 {
  margin: 0 5px 0 0px;
  font-size: 12px;
  padding: 2px;
  text-align: center;
  width: 20px;
  font-weight: bold;
  border-radius: 3px;
}
.main_topics .content_links .video_item .link_type.lesson h6 {
  background-color: #ffbd43;
}
.main_topics .content_links .video_item .link_type.exp h6 {
  background-color: #79a5ff;
}
.main_topics .content_links .video_item .link_type.topic h6 {
  background-color: #e979ff;
}
.main_topics .content_links .premium_topic {
  position: absolute;
  top: 15px;
  right: 15px;
}

.topic-image {
  padding-right: 15px;
  display: inline-block;
}
.topic-image img {
  height: 80px;
  object-fit: contain;
}

.topic_tags span {
  padding: 2px 7px;
  font-size: 14px;
  font-weight: 500;
  color: #202020;
  border-radius: 2px;
  text-transform: uppercase;
  margin-right: 10px;
}
.topic_tags .new {
  background: #ffbfbf;
}
.topic_tags .mp {
  background: #bfffd1;
}
.topic_tags .tn {
  background: #bfe8ff;
}

.topic_desc {
  max-width: 800px;
  margin: 0 0 30px 0;
}
.topic_desc p {
  color: #6d6d6d;
}

.progress {
  margin: 20px auto;
  padding: 0;
  width: 90%;
  height: 9px;
  overflow: hidden;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background: #e5e5e5;
  border-radius: 30px;
  transition: 0.2s all ease;
}
.progress input {
  width: 100%;
  min-width: 100%;
  background: transparent;
  color: transparent;
  opacity: 0;
  position: absolute;
  bottom: -4px;
  z-index: 999;
  cursor: pointer;
}

.bar {
  position: relative;
  float: left;
  min-width: 0%;
  width: 0;
  height: 100%;
  background: #0077d6;
  transition: 0.5s all ease;
}

.percent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  font-family: tahoma, arial, helvetica;
  font-size: 12px;
  color: white;
}

.coming_soon {
  position: absolute;
  background: linear-gradient(to bottom, #ff7272, #e91e63);
  left: 0;
  text-align: center;
  bottom: 0;
  width: 100%;
}
.coming_soon span {
  font-size: 11px;
  margin-left: 27px;
  margin-bottom: 6px;
  display: inline-block;
  font-weight: 700;
  color: #fff;
}

/********** Main Content ******/
/******* Lessons Video Animation ***********/
.suggest_block {
  border-left: 1px solid #ebebeb;
  padding-left: 30px;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-right: 15px;
}
.suggest_block .content_links .video_item {
  min-height: auto;
  margin-bottom: 15px;
  padding: 15px 15px 45px 15px;
  height: auto;
}

.lesson_wrap {
  padding-top: 53px;
}
.lesson_wrap hr {
  border-color: #b8b8b8;
}

.lesson_block {
  padding: 30px 5px 30px 20px;
  height: 100%;
}

.redirect_sec {
  height: calc(100% - 92px);
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.redirect_sec h3 {
  margin-bottom: 20px;
}
.redirect_sec .loader_dir {
  margin-bottom: 30px;
}
.redirect_sec .loader_dir img {
  height: 60px;
}
.redirect_sec p {
  max-width: 400px;
  text-align: center;
}
.redirect_sec .btn_underline {
  max-width: 82px;
  padding: 0;
  text-align: center;
  font-weight: 500;
  margin-left: 10px;
}

h2.main_heading {
  font-size: 24px;
  font-weight: 700;
  display: flex;
  align-items: center;
  color: #202020;
  margin-bottom: 20px;
  position: relative;
}

/******************* Christmas ***********/
.christmas_wish {
  position: absolute;
  right: 155px;
  bottom: 88px;
}
.christmas_wish img {
  height: 180px;
}

.logo_sec .christmas_theme_logo {
  max-width: 100%;
  margin-top: 4px;
  max-height: 57px;
}

.hanging_bells {
  position: fixed;
  top: 0;
  z-index: 9999;
  height: 100%;
  width: 100%;
  display: flex;
  left: 0;
  max-width: 1200px;
  justify-content: space-between;
  margin: auto;
  right: 0;
  -webkit-animation: expand 0.5s;
  animation: expand 0.5s;
  pointer-events: none;
}
.hanging_bells .bell {
  width: 35px;
  height: auto;
  display: inline-block;
  -webkit-transform-origin: top center;
  transform-origin: top center;
  -webkit-animation-duration: 8s;
  animation-duration: 8s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.hanging_bells .bell_1 {
  -webkit-animation-name: swing1;
  animation-name: swing1;
}
.hanging_bells .bell_2 {
  -webkit-animation-name: swing2;
  animation-name: swing2;
}
.hanging_bells .bell_3 {
  -webkit-animation-name: swing3;
  animation-name: swing3;
}
@-webkit-keyframes swing1 {
  20%, 40%, 60%, 80%, 100% {
    -webkit-transform-origin: top center;
  }
  20% {
    -webkit-transform: rotate(10deg);
  }
  40% {
    -webkit-transform: rotate(-5deg);
  }
  60% {
    -webkit-transform: rotate(2deg);
  }
  80% {
    -webkit-transform: rotate(-2deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
  }
}
@keyframes swing1 {
  20% {
    transform: rotate(10deg);
  }
  40% {
    transform: rotate(-5deg);
  }
  60% {
    transform: rotate(2deg);
  }
  80% {
    transform: rotate(-2deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@-webkit-keyframes swing2 {
  20%, 40%, 60%, 80%, 100% {
    -webkit-transform-origin: top center;
  }
  20% {
    -webkit-transform: rotate(-10deg);
  }
  40% {
    -webkit-transform: rotate(5deg);
  }
  60% {
    -webkit-transform: rotate(-2deg);
  }
  80% {
    -webkit-transform: rotate(2deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
  }
}
@keyframes swing2 {
  20% {
    transform: rotate(-10deg);
  }
  40% {
    transform: rotate(5deg);
  }
  60% {
    transform: rotate(-2deg);
  }
  80% {
    transform: rotate(2deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@-webkit-keyframes swing3 {
  20%, 40%, 60%, 80%, 100% {
    -webkit-transform-origin: top center;
  }
  20% {
    -webkit-transform: rotate(-7deg);
  }
  40% {
    -webkit-transform: rotate(3deg);
  }
  60% {
    -webkit-transform: rotate(-2deg);
  }
  80% {
    -webkit-transform: rotate(1deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
  }
}
@keyframes swing3 {
  20% {
    transform: rotate(-7deg);
  }
  40% {
    transform: rotate(3deg);
  }
  60% {
    transform: rotate(-2deg);
  }
  80% {
    transform: rotate(1deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@-webkit-keyframes expand {
  0% {
    top: -300px;
  }
  100% {
    top: 0px;
  }
}
@keyframes expand {
  0% {
    top: -300px;
  }
  100% {
    top: 0px;
  }
}
@keyframes spin {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(1turn);
  }
}
@keyframes glow {
  50% {
    text-shadow: 0 0 3rem rgba(255, 255, 255, 0.2);
  }
}
@keyframes drop {
  from {
    top: 0;
  }
  to {
    top: 1500px;
  }
}
.snowfall {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 9999;
  background: rgba(0, 0, 0, 0);
  pointer-events: none;
}
.snowfall .snow {
  position: fixed;
  width: 15px;
  height: 15px;
  background: #ddddda;
  border-radius: 50%;
  box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.15);
  filter: blur(2px);
}
.snowfall .snow:nth-child(1) {
  opacity: 0.2439;
  transform: translate(70.3411vw, -10px) scale(0.3602);
  animation: fall-1 14s -20s linear infinite;
}
@keyframes fall-1 {
  36.235% {
    transform: translate(65.8121vw, 36.235vh) scale(0.3602);
  }
  to {
    transform: translate(68.0766vw, 100vh) scale(0.3602);
  }
}
.snowfall .snow:nth-child(2) {
  opacity: 0.202;
  transform: translate(14.7144vw, -10px) scale(0.1405);
  animation: fall-2 24s -8s linear infinite;
}
@keyframes fall-2 {
  77.315% {
    transform: translate(8.7115vw, 77.315vh) scale(0.1405);
  }
  to {
    transform: translate(11.71295vw, 100vh) scale(0.1405);
  }
}
.snowfall .snow:nth-child(3) {
  opacity: 0.2996;
  transform: translate(71.7686vw, -10px) scale(0.539);
  animation: fall-3 27s -14s linear infinite;
}
@keyframes fall-3 {
  55.948% {
    transform: translate(73.1544vw, 55.948vh) scale(0.539);
  }
  to {
    transform: translate(72.4615vw, 100vh) scale(0.539);
  }
}
.snowfall .snow:nth-child(4) {
  opacity: 0.5539;
  transform: translate(37.8143vw, -10px) scale(0.6981);
  animation: fall-4 28s -17s linear infinite;
}
@keyframes fall-4 {
  72.801% {
    transform: translate(28.9482vw, 72.801vh) scale(0.6981);
  }
  to {
    transform: translate(33.38125vw, 100vh) scale(0.6981);
  }
}
.snowfall .snow:nth-child(5) {
  opacity: 0.5792;
  transform: translate(78.8752vw, -10px) scale(0.2607);
  animation: fall-5 24s -16s linear infinite;
}
@keyframes fall-5 {
  64.856% {
    transform: translate(80.1574vw, 64.856vh) scale(0.2607);
  }
  to {
    transform: translate(79.5163vw, 100vh) scale(0.2607);
  }
}
.snowfall .snow:nth-child(6) {
  opacity: 0.581;
  transform: translate(96.6948vw, -10px) scale(0.185);
  animation: fall-6 18s -12s linear infinite;
}
@keyframes fall-6 {
  48.981% {
    transform: translate(94.8141vw, 48.981vh) scale(0.185);
  }
  to {
    transform: translate(95.75445vw, 100vh) scale(0.185);
  }
}
.snowfall .snow:nth-child(7) {
  opacity: 0.6744;
  transform: translate(76.6779vw, -10px) scale(0.4583);
  animation: fall-7 10s -9s linear infinite;
}
@keyframes fall-7 {
  67.62% {
    transform: translate(69.9066vw, 67.62vh) scale(0.4583);
  }
  to {
    transform: translate(73.29225vw, 100vh) scale(0.4583);
  }
}
.snowfall .snow:nth-child(8) {
  opacity: 0.7774;
  transform: translate(34.9031vw, -10px) scale(0.1771);
  animation: fall-8 27s -19s linear infinite;
}
@keyframes fall-8 {
  32.3% {
    transform: translate(35.747vw, 32.3vh) scale(0.1771);
  }
  to {
    transform: translate(35.32505vw, 100vh) scale(0.1771);
  }
}
.snowfall .snow:nth-child(9) {
  opacity: 0.6901;
  transform: translate(76.3864vw, -10px) scale(0.1961);
  animation: fall-9 28s -5s linear infinite;
}
@keyframes fall-9 {
  76.702% {
    transform: translate(73.9302vw, 76.702vh) scale(0.1961);
  }
  to {
    transform: translate(75.1583vw, 100vh) scale(0.1961);
  }
}
.snowfall .snow:nth-child(10) {
  opacity: 0.8268;
  transform: translate(79.5703vw, -10px) scale(0.3021);
  animation: fall-10 30s -18s linear infinite;
}
@keyframes fall-10 {
  63.9% {
    transform: translate(86.7341vw, 63.9vh) scale(0.3021);
  }
  to {
    transform: translate(83.1522vw, 100vh) scale(0.3021);
  }
}
.snowfall .snow:nth-child(11) {
  opacity: 0.539;
  transform: translate(27.9804vw, -10px) scale(0.205);
  animation: fall-11 25s -7s linear infinite;
}
@keyframes fall-11 {
  75.495% {
    transform: translate(37.2753vw, 75.495vh) scale(0.205);
  }
  to {
    transform: translate(32.62785vw, 100vh) scale(0.205);
  }
}
.snowfall .snow:nth-child(12) {
  opacity: 0.2859;
  transform: translate(52.8452vw, -10px) scale(0.6455);
  animation: fall-12 23s -24s linear infinite;
}
@keyframes fall-12 {
  46.069% {
    transform: translate(48.2306vw, 46.069vh) scale(0.6455);
  }
  to {
    transform: translate(50.5379vw, 100vh) scale(0.6455);
  }
}
.snowfall .snow:nth-child(13) {
  opacity: 0.3088;
  transform: translate(61.9073vw, -10px) scale(0.8345);
  animation: fall-13 17s -10s linear infinite;
}
@keyframes fall-13 {
  63.538% {
    transform: translate(61.748vw, 63.538vh) scale(0.8345);
  }
  to {
    transform: translate(61.82765vw, 100vh) scale(0.8345);
  }
}
.snowfall .snow:nth-child(14) {
  opacity: 0.4478;
  transform: translate(70.3445vw, -10px) scale(0.7831);
  animation: fall-14 23s -1s linear infinite;
}
@keyframes fall-14 {
  49.141% {
    transform: translate(61.3601vw, 49.141vh) scale(0.7831);
  }
  to {
    transform: translate(65.8523vw, 100vh) scale(0.7831);
  }
}
.snowfall .snow:nth-child(15) {
  opacity: 0.3052;
  transform: translate(5.6217vw, -10px) scale(0.9984);
  animation: fall-15 29s -3s linear infinite;
}
@keyframes fall-15 {
  61.167% {
    transform: translate(-2.4049vw, 61.167vh) scale(0.9984);
  }
  to {
    transform: translate(1.6084vw, 100vh) scale(0.9984);
  }
}
.snowfall .snow:nth-child(16) {
  opacity: 0.1524;
  transform: translate(42.2702vw, -10px) scale(0.3759);
  animation: fall-16 22s -7s linear infinite;
}
@keyframes fall-16 {
  68.708% {
    transform: translate(50.3904vw, 68.708vh) scale(0.3759);
  }
  to {
    transform: translate(46.3303vw, 100vh) scale(0.3759);
  }
}
.snowfall .snow:nth-child(17) {
  opacity: 0.2639;
  transform: translate(42.6556vw, -10px) scale(0.8526);
  animation: fall-17 17s -17s linear infinite;
}
@keyframes fall-17 {
  44.31% {
    transform: translate(43.581vw, 44.31vh) scale(0.8526);
  }
  to {
    transform: translate(43.1183vw, 100vh) scale(0.8526);
  }
}
.snowfall .snow:nth-child(18) {
  opacity: 0.422;
  transform: translate(71.8249vw, -10px) scale(0.8911);
  animation: fall-18 30s -4s linear infinite;
}
@keyframes fall-18 {
  44.539% {
    transform: translate(71.3747vw, 44.539vh) scale(0.8911);
  }
  to {
    transform: translate(71.5998vw, 100vh) scale(0.8911);
  }
}
.snowfall .snow:nth-child(19) {
  opacity: 0.0181;
  transform: translate(72.9137vw, -10px) scale(0.3244);
  animation: fall-19 11s -4s linear infinite;
}
@keyframes fall-19 {
  59.891% {
    transform: translate(67.6502vw, 59.891vh) scale(0.3244);
  }
  to {
    transform: translate(70.28195vw, 100vh) scale(0.3244);
  }
}
.snowfall .snow:nth-child(20) {
  opacity: 0.5087;
  transform: translate(25.1577vw, -10px) scale(0.5395);
  animation: fall-20 16s -13s linear infinite;
}
@keyframes fall-20 {
  44.452% {
    transform: translate(33.581vw, 44.452vh) scale(0.5395);
  }
  to {
    transform: translate(29.36935vw, 100vh) scale(0.5395);
  }
}
.snowfall .snow:nth-child(21) {
  opacity: 0.7946;
  transform: translate(92.5042vw, -10px) scale(0.4874);
  animation: fall-21 11s -19s linear infinite;
}
@keyframes fall-21 {
  32.072% {
    transform: translate(85.6403vw, 32.072vh) scale(0.4874);
  }
  to {
    transform: translate(89.07225vw, 100vh) scale(0.4874);
  }
}
.snowfall .snow:nth-child(22) {
  opacity: 0.507;
  transform: translate(35.9603vw, -10px) scale(0.6991);
  animation: fall-22 14s -26s linear infinite;
}
@keyframes fall-22 {
  47.559% {
    transform: translate(39.6957vw, 47.559vh) scale(0.6991);
  }
  to {
    transform: translate(37.828vw, 100vh) scale(0.6991);
  }
}
.snowfall .snow:nth-child(23) {
  opacity: 0.8901;
  transform: translate(92.5079vw, -10px) scale(0.7659);
  animation: fall-23 19s -7s linear infinite;
}
@keyframes fall-23 {
  61.399% {
    transform: translate(93.4793vw, 61.399vh) scale(0.7659);
  }
  to {
    transform: translate(92.9936vw, 100vh) scale(0.7659);
  }
}
.snowfall .snow:nth-child(24) {
  opacity: 0.9852;
  transform: translate(67.0814vw, -10px) scale(0.5652);
  animation: fall-24 16s -19s linear infinite;
}
@keyframes fall-24 {
  76.861% {
    transform: translate(61.1276vw, 76.861vh) scale(0.5652);
  }
  to {
    transform: translate(64.1045vw, 100vh) scale(0.5652);
  }
}
.snowfall .snow:nth-child(25) {
  opacity: 0.0194;
  transform: translate(67.233vw, -10px) scale(0.0881);
  animation: fall-25 13s -17s linear infinite;
}
@keyframes fall-25 {
  62.135% {
    transform: translate(60.0335vw, 62.135vh) scale(0.0881);
  }
  to {
    transform: translate(63.63325vw, 100vh) scale(0.0881);
  }
}
.snowfall .snow:nth-child(26) {
  opacity: 0.8064;
  transform: translate(20.0145vw, -10px) scale(0.4472);
  animation: fall-26 12s -12s linear infinite;
}
@keyframes fall-26 {
  44.895% {
    transform: translate(20.1193vw, 44.895vh) scale(0.4472);
  }
  to {
    transform: translate(20.0669vw, 100vh) scale(0.4472);
  }
}
.snowfall .snow:nth-child(27) {
  opacity: 0.164;
  transform: translate(2.2251vw, -10px) scale(0.7708);
  animation: fall-27 20s -7s linear infinite;
}
@keyframes fall-27 {
  32.735% {
    transform: translate(7.4902vw, 32.735vh) scale(0.7708);
  }
  to {
    transform: translate(4.85765vw, 100vh) scale(0.7708);
  }
}
.snowfall .snow:nth-child(28) {
  opacity: 0.2703;
  transform: translate(50.8117vw, -10px) scale(0.7609);
  animation: fall-28 23s -14s linear infinite;
}
@keyframes fall-28 {
  35.309% {
    transform: translate(42.7974vw, 35.309vh) scale(0.7609);
  }
  to {
    transform: translate(46.80455vw, 100vh) scale(0.7609);
  }
}
.snowfall .snow:nth-child(29) {
  opacity: 0.1095;
  transform: translate(36.0643vw, -10px) scale(0.9244);
  animation: fall-29 16s -17s linear infinite;
}
@keyframes fall-29 {
  54.42% {
    transform: translate(32.2964vw, 54.42vh) scale(0.9244);
  }
  to {
    transform: translate(34.18035vw, 100vh) scale(0.9244);
  }
}
.snowfall .snow:nth-child(30) {
  opacity: 0.5501;
  transform: translate(92.7617vw, -10px) scale(0.6868);
  animation: fall-30 13s -16s linear infinite;
}
@keyframes fall-30 {
  30.848% {
    transform: translate(86.3548vw, 30.848vh) scale(0.6868);
  }
  to {
    transform: translate(89.55825vw, 100vh) scale(0.6868);
  }
}
.snowfall .snow:nth-child(31) {
  opacity: 0.8798;
  transform: translate(3.7721vw, -10px) scale(0.1611);
  animation: fall-31 24s -30s linear infinite;
}
@keyframes fall-31 {
  45.962% {
    transform: translate(-3.7394vw, 45.962vh) scale(0.1611);
  }
  to {
    transform: translate(0.01635vw, 100vh) scale(0.1611);
  }
}
.snowfall .snow:nth-child(32) {
  opacity: 0.2107;
  transform: translate(34.3809vw, -10px) scale(0.4887);
  animation: fall-32 14s -30s linear infinite;
}
@keyframes fall-32 {
  52.086% {
    transform: translate(30.2668vw, 52.086vh) scale(0.4887);
  }
  to {
    transform: translate(32.32385vw, 100vh) scale(0.4887);
  }
}
.snowfall .snow:nth-child(33) {
  opacity: 0.2245;
  transform: translate(55.4186vw, -10px) scale(0.1482);
  animation: fall-33 21s -26s linear infinite;
}
@keyframes fall-33 {
  70.629% {
    transform: translate(59.1808vw, 70.629vh) scale(0.1482);
  }
  to {
    transform: translate(57.2997vw, 100vh) scale(0.1482);
  }
}
.snowfall .snow:nth-child(34) {
  opacity: 0.6892;
  transform: translate(17.586vw, -10px) scale(0.4521);
  animation: fall-34 30s -11s linear infinite;
}
@keyframes fall-34 {
  65.676% {
    transform: translate(13.127vw, 65.676vh) scale(0.4521);
  }
  to {
    transform: translate(15.3565vw, 100vh) scale(0.4521);
  }
}
.snowfall .snow:nth-child(35) {
  opacity: 0.2882;
  transform: translate(5.5301vw, -10px) scale(0.6458);
  animation: fall-35 27s -16s linear infinite;
}
@keyframes fall-35 {
  65.005% {
    transform: translate(9.2226vw, 65.005vh) scale(0.6458);
  }
  to {
    transform: translate(7.37635vw, 100vh) scale(0.6458);
  }
}
.snowfall .snow:nth-child(36) {
  opacity: 0.6072;
  transform: translate(50.3923vw, -10px) scale(0.3118);
  animation: fall-36 19s -28s linear infinite;
}
@keyframes fall-36 {
  33.779% {
    transform: translate(47.8636vw, 33.779vh) scale(0.3118);
  }
  to {
    transform: translate(49.12795vw, 100vh) scale(0.3118);
  }
}
.snowfall .snow:nth-child(37) {
  opacity: 0.2042;
  transform: translate(95.327vw, -10px) scale(0.682);
  animation: fall-37 17s -8s linear infinite;
}
@keyframes fall-37 {
  68.73% {
    transform: translate(104.4497vw, 68.73vh) scale(0.682);
  }
  to {
    transform: translate(99.88835vw, 100vh) scale(0.682);
  }
}
.snowfall .snow:nth-child(38) {
  opacity: 0.5918;
  transform: translate(25.3441vw, -10px) scale(0.6378);
  animation: fall-38 14s -25s linear infinite;
}
@keyframes fall-38 {
  58.274% {
    transform: translate(34.0074vw, 58.274vh) scale(0.6378);
  }
  to {
    transform: translate(29.67575vw, 100vh) scale(0.6378);
  }
}
.snowfall .snow:nth-child(39) {
  opacity: 0.7323;
  transform: translate(0.2446vw, -10px) scale(0.5635);
  animation: fall-39 17s -18s linear infinite;
}
@keyframes fall-39 {
  64.687% {
    transform: translate(5.8174vw, 64.687vh) scale(0.5635);
  }
  to {
    transform: translate(3.031vw, 100vh) scale(0.5635);
  }
}
.snowfall .snow:nth-child(40) {
  opacity: 0.6054;
  transform: translate(56.3079vw, -10px) scale(0.0079);
  animation: fall-40 28s -5s linear infinite;
}
@keyframes fall-40 {
  50.856% {
    transform: translate(58.1214vw, 50.856vh) scale(0.0079);
  }
  to {
    transform: translate(57.21465vw, 100vh) scale(0.0079);
  }
}
.snowfall .snow:nth-child(41) {
  opacity: 0.1938;
  transform: translate(23.073vw, -10px) scale(0.3496);
  animation: fall-41 14s -28s linear infinite;
}
@keyframes fall-41 {
  67.925% {
    transform: translate(13.8788vw, 67.925vh) scale(0.3496);
  }
  to {
    transform: translate(18.4759vw, 100vh) scale(0.3496);
  }
}
.snowfall .snow:nth-child(42) {
  opacity: 0.1003;
  transform: translate(68.2949vw, -10px) scale(0.2437);
  animation: fall-42 14s -16s linear infinite;
}
@keyframes fall-42 {
  40.324% {
    transform: translate(70.8312vw, 40.324vh) scale(0.2437);
  }
  to {
    transform: translate(69.56305vw, 100vh) scale(0.2437);
  }
}
.snowfall .snow:nth-child(43) {
  opacity: 0.5424;
  transform: translate(7.6699vw, -10px) scale(0.9455);
  animation: fall-43 27s -3s linear infinite;
}
@keyframes fall-43 {
  69.045% {
    transform: translate(5.2118vw, 69.045vh) scale(0.9455);
  }
  to {
    transform: translate(6.44085vw, 100vh) scale(0.9455);
  }
}
.snowfall .snow:nth-child(44) {
  opacity: 0.8831;
  transform: translate(28.1943vw, -10px) scale(0.4134);
  animation: fall-44 17s -7s linear infinite;
}
@keyframes fall-44 {
  58.301% {
    transform: translate(21.3207vw, 58.301vh) scale(0.4134);
  }
  to {
    transform: translate(24.7575vw, 100vh) scale(0.4134);
  }
}
.snowfall .snow:nth-child(45) {
  opacity: 0.4508;
  transform: translate(2.4309vw, -10px) scale(0.8694);
  animation: fall-45 15s -7s linear infinite;
}
@keyframes fall-45 {
  78.21% {
    transform: translate(6.5352vw, 78.21vh) scale(0.8694);
  }
  to {
    transform: translate(4.48305vw, 100vh) scale(0.8694);
  }
}
.snowfall .snow:nth-child(46) {
  opacity: 0.2072;
  transform: translate(98.421vw, -10px) scale(0.1527);
  animation: fall-46 15s -13s linear infinite;
}
@keyframes fall-46 {
  47.404% {
    transform: translate(95.309vw, 47.404vh) scale(0.1527);
  }
  to {
    transform: translate(96.865vw, 100vh) scale(0.1527);
  }
}
.snowfall .snow:nth-child(47) {
  opacity: 0.2119;
  transform: translate(47.0861vw, -10px) scale(0.6626);
  animation: fall-47 20s -21s linear infinite;
}
@keyframes fall-47 {
  35.341% {
    transform: translate(56.5025vw, 35.341vh) scale(0.6626);
  }
  to {
    transform: translate(51.7943vw, 100vh) scale(0.6626);
  }
}
.snowfall .snow:nth-child(48) {
  opacity: 0.8489;
  transform: translate(30.4568vw, -10px) scale(0.3637);
  animation: fall-48 22s -9s linear infinite;
}
@keyframes fall-48 {
  70.085% {
    transform: translate(25.1037vw, 70.085vh) scale(0.3637);
  }
  to {
    transform: translate(27.78025vw, 100vh) scale(0.3637);
  }
}
.snowfall .snow:nth-child(49) {
  opacity: 0.6608;
  transform: translate(5.4608vw, -10px) scale(0.6126);
  animation: fall-49 16s -17s linear infinite;
}
@keyframes fall-49 {
  59.167% {
    transform: translate(11.4936vw, 59.167vh) scale(0.6126);
  }
  to {
    transform: translate(8.4772vw, 100vh) scale(0.6126);
  }
}
.snowfall .snow:nth-child(50) {
  opacity: 0.6429;
  transform: translate(17.5354vw, -10px) scale(0.3651);
  animation: fall-50 15s -28s linear infinite;
}
@keyframes fall-50 {
  48.365% {
    transform: translate(20.1789vw, 48.365vh) scale(0.3651);
  }
  to {
    transform: translate(18.85715vw, 100vh) scale(0.3651);
  }
}
.snowfall .snow:nth-child(51) {
  opacity: 0.906;
  transform: translate(37.3019vw, -10px) scale(0.1607);
  animation: fall-51 29s -3s linear infinite;
}
@keyframes fall-51 {
  64.712% {
    transform: translate(43.6237vw, 64.712vh) scale(0.1607);
  }
  to {
    transform: translate(40.4628vw, 100vh) scale(0.1607);
  }
}
.snowfall .snow:nth-child(52) {
  opacity: 0.8268;
  transform: translate(69.607vw, -10px) scale(0.0645);
  animation: fall-52 10s -26s linear infinite;
}
@keyframes fall-52 {
  69.406% {
    transform: translate(65.3248vw, 69.406vh) scale(0.0645);
  }
  to {
    transform: translate(67.4659vw, 100vh) scale(0.0645);
  }
}
.snowfall .snow:nth-child(53) {
  opacity: 0.6246;
  transform: translate(79.0507vw, -10px) scale(0.718);
  animation: fall-53 21s -21s linear infinite;
}
@keyframes fall-53 {
  32.23% {
    transform: translate(88.8522vw, 32.23vh) scale(0.718);
  }
  to {
    transform: translate(83.95145vw, 100vh) scale(0.718);
  }
}
.snowfall .snow:nth-child(54) {
  opacity: 0.2077;
  transform: translate(48.0102vw, -10px) scale(0.1797);
  animation: fall-54 16s -1s linear infinite;
}
@keyframes fall-54 {
  32.004% {
    transform: translate(48.9575vw, 32.004vh) scale(0.1797);
  }
  to {
    transform: translate(48.48385vw, 100vh) scale(0.1797);
  }
}
.snowfall .snow:nth-child(55) {
  opacity: 0.7442;
  transform: translate(37.0931vw, -10px) scale(0.3323);
  animation: fall-55 10s -29s linear infinite;
}
@keyframes fall-55 {
  78.822% {
    transform: translate(38.2708vw, 78.822vh) scale(0.3323);
  }
  to {
    transform: translate(37.68195vw, 100vh) scale(0.3323);
  }
}
.snowfall .snow:nth-child(56) {
  opacity: 0.5193;
  transform: translate(88.8224vw, -10px) scale(0.1684);
  animation: fall-56 16s -26s linear infinite;
}
@keyframes fall-56 {
  47.41% {
    transform: translate(79.7082vw, 47.41vh) scale(0.1684);
  }
  to {
    transform: translate(84.2653vw, 100vh) scale(0.1684);
  }
}
.snowfall .snow:nth-child(57) {
  opacity: 0.0336;
  transform: translate(64.5337vw, -10px) scale(0.8468);
  animation: fall-57 26s -25s linear infinite;
}
@keyframes fall-57 {
  79.539% {
    transform: translate(64.1834vw, 79.539vh) scale(0.8468);
  }
  to {
    transform: translate(64.35855vw, 100vh) scale(0.8468);
  }
}
.snowfall .snow:nth-child(58) {
  opacity: 0.1299;
  transform: translate(88.2577vw, -10px) scale(0.3804);
  animation: fall-58 21s -23s linear infinite;
}
@keyframes fall-58 {
  77.438% {
    transform: translate(93.7166vw, 77.438vh) scale(0.3804);
  }
  to {
    transform: translate(90.98715vw, 100vh) scale(0.3804);
  }
}
.snowfall .snow:nth-child(59) {
  opacity: 0.1978;
  transform: translate(90.759vw, -10px) scale(0.2089);
  animation: fall-59 27s -23s linear infinite;
}
@keyframes fall-59 {
  44.183% {
    transform: translate(84.6167vw, 44.183vh) scale(0.2089);
  }
  to {
    transform: translate(87.68785vw, 100vh) scale(0.2089);
  }
}
.snowfall .snow:nth-child(60) {
  opacity: 0.5134;
  transform: translate(6.1732vw, -10px) scale(0.6627);
  animation: fall-60 22s -19s linear infinite;
}
@keyframes fall-60 {
  35.271% {
    transform: translate(11.2529vw, 35.271vh) scale(0.6627);
  }
  to {
    transform: translate(8.71305vw, 100vh) scale(0.6627);
  }
}
.snowfall .snow:nth-child(61) {
  opacity: 0.2009;
  transform: translate(69.7748vw, -10px) scale(0.4422);
  animation: fall-61 27s -15s linear infinite;
}
@keyframes fall-61 {
  76.309% {
    transform: translate(77.8306vw, 76.309vh) scale(0.4422);
  }
  to {
    transform: translate(73.8027vw, 100vh) scale(0.4422);
  }
}
.snowfall .snow:nth-child(62) {
  opacity: 0.8489;
  transform: translate(92.3014vw, -10px) scale(0.4247);
  animation: fall-62 27s -26s linear infinite;
}
@keyframes fall-62 {
  50.628% {
    transform: translate(89.1228vw, 50.628vh) scale(0.4247);
  }
  to {
    transform: translate(90.7121vw, 100vh) scale(0.4247);
  }
}
.snowfall .snow:nth-child(63) {
  opacity: 0.7402;
  transform: translate(13.4379vw, -10px) scale(0.8958);
  animation: fall-63 27s -18s linear infinite;
}
@keyframes fall-63 {
  58.629% {
    transform: translate(3.7255vw, 58.629vh) scale(0.8958);
  }
  to {
    transform: translate(8.5817vw, 100vh) scale(0.8958);
  }
}
.snowfall .snow:nth-child(64) {
  opacity: 0.557;
  transform: translate(20.9228vw, -10px) scale(0.5477);
  animation: fall-64 23s -12s linear infinite;
}
@keyframes fall-64 {
  37.31% {
    transform: translate(21.0016vw, 37.31vh) scale(0.5477);
  }
  to {
    transform: translate(20.9622vw, 100vh) scale(0.5477);
  }
}
.snowfall .snow:nth-child(65) {
  opacity: 0.6058;
  transform: translate(31.4256vw, -10px) scale(0.7561);
  animation: fall-65 26s -27s linear infinite;
}
@keyframes fall-65 {
  44.658% {
    transform: translate(27.8686vw, 44.658vh) scale(0.7561);
  }
  to {
    transform: translate(29.6471vw, 100vh) scale(0.7561);
  }
}
.snowfall .snow:nth-child(66) {
  opacity: 0.3016;
  transform: translate(70.9828vw, -10px) scale(0.5397);
  animation: fall-66 30s -1s linear infinite;
}
@keyframes fall-66 {
  33.227% {
    transform: translate(78.66vw, 33.227vh) scale(0.5397);
  }
  to {
    transform: translate(74.8214vw, 100vh) scale(0.5397);
  }
}
.snowfall .snow:nth-child(67) {
  opacity: 0.2141;
  transform: translate(33.8731vw, -10px) scale(0.4174);
  animation: fall-67 23s -12s linear infinite;
}
@keyframes fall-67 {
  65.135% {
    transform: translate(30.1477vw, 65.135vh) scale(0.4174);
  }
  to {
    transform: translate(32.0104vw, 100vh) scale(0.4174);
  }
}
.snowfall .snow:nth-child(68) {
  opacity: 0.03;
  transform: translate(49.6472vw, -10px) scale(0.878);
  animation: fall-68 20s -1s linear infinite;
}
@keyframes fall-68 {
  65.29% {
    transform: translate(45.3704vw, 65.29vh) scale(0.878);
  }
  to {
    transform: translate(47.5088vw, 100vh) scale(0.878);
  }
}
.snowfall .snow:nth-child(69) {
  opacity: 0.7849;
  transform: translate(7.7689vw, -10px) scale(0.1179);
  animation: fall-69 16s -21s linear infinite;
}
@keyframes fall-69 {
  70.791% {
    transform: translate(12.2009vw, 70.791vh) scale(0.1179);
  }
  to {
    transform: translate(9.9849vw, 100vh) scale(0.1179);
  }
}
.snowfall .snow:nth-child(70) {
  opacity: 0.5088;
  transform: translate(52.7777vw, -10px) scale(0.4869);
  animation: fall-70 13s -17s linear infinite;
}
@keyframes fall-70 {
  60.599% {
    transform: translate(53.7979vw, 60.599vh) scale(0.4869);
  }
  to {
    transform: translate(53.2878vw, 100vh) scale(0.4869);
  }
}
.snowfall .snow:nth-child(71) {
  opacity: 0.518;
  transform: translate(43.2335vw, -10px) scale(0.2815);
  animation: fall-71 22s -11s linear infinite;
}
@keyframes fall-71 {
  71.222% {
    transform: translate(49.5211vw, 71.222vh) scale(0.2815);
  }
  to {
    transform: translate(46.3773vw, 100vh) scale(0.2815);
  }
}
.snowfall .snow:nth-child(72) {
  opacity: 0.0979;
  transform: translate(73.2681vw, -10px) scale(0.5222);
  animation: fall-72 30s -30s linear infinite;
}
@keyframes fall-72 {
  36.591% {
    transform: translate(80.7532vw, 36.591vh) scale(0.5222);
  }
  to {
    transform: translate(77.01065vw, 100vh) scale(0.5222);
  }
}
.snowfall .snow:nth-child(73) {
  opacity: 0.6727;
  transform: translate(53.7293vw, -10px) scale(0.483);
  animation: fall-73 13s -3s linear infinite;
}
@keyframes fall-73 {
  44.832% {
    transform: translate(49.2421vw, 44.832vh) scale(0.483);
  }
  to {
    transform: translate(51.4857vw, 100vh) scale(0.483);
  }
}
.snowfall .snow:nth-child(74) {
  opacity: 0.8886;
  transform: translate(26.5139vw, -10px) scale(0.5206);
  animation: fall-74 27s -11s linear infinite;
}
@keyframes fall-74 {
  70.791% {
    transform: translate(18.5985vw, 70.791vh) scale(0.5206);
  }
  to {
    transform: translate(22.5562vw, 100vh) scale(0.5206);
  }
}
.snowfall .snow:nth-child(75) {
  opacity: 0.1627;
  transform: translate(85.6853vw, -10px) scale(0.6691);
  animation: fall-75 11s -24s linear infinite;
}
@keyframes fall-75 {
  77.757% {
    transform: translate(94.516vw, 77.757vh) scale(0.6691);
  }
  to {
    transform: translate(90.10065vw, 100vh) scale(0.6691);
  }
}
.snowfall .snow:nth-child(76) {
  opacity: 0.7408;
  transform: translate(64.0759vw, -10px) scale(0.8167);
  animation: fall-76 25s -14s linear infinite;
}
@keyframes fall-76 {
  68.931% {
    transform: translate(62.1599vw, 68.931vh) scale(0.8167);
  }
  to {
    transform: translate(63.1179vw, 100vh) scale(0.8167);
  }
}
.snowfall .snow:nth-child(77) {
  opacity: 0.1531;
  transform: translate(98.419vw, -10px) scale(0.4361);
  animation: fall-77 13s -1s linear infinite;
}
@keyframes fall-77 {
  51.95% {
    transform: translate(100.5752vw, 51.95vh) scale(0.4361);
  }
  to {
    transform: translate(99.4971vw, 100vh) scale(0.4361);
  }
}
.snowfall .snow:nth-child(78) {
  opacity: 0.9461;
  transform: translate(54.9728vw, -10px) scale(0.505);
  animation: fall-78 20s -14s linear infinite;
}
@keyframes fall-78 {
  63.694% {
    transform: translate(51.672vw, 63.694vh) scale(0.505);
  }
  to {
    transform: translate(53.3224vw, 100vh) scale(0.505);
  }
}
.snowfall .snow:nth-child(79) {
  opacity: 0.5145;
  transform: translate(39.3289vw, -10px) scale(0.0592);
  animation: fall-79 21s -24s linear infinite;
}
@keyframes fall-79 {
  57.532% {
    transform: translate(48.7229vw, 57.532vh) scale(0.0592);
  }
  to {
    transform: translate(44.0259vw, 100vh) scale(0.0592);
  }
}
.snowfall .snow:nth-child(80) {
  opacity: 0.4349;
  transform: translate(41.6479vw, -10px) scale(0.6813);
  animation: fall-80 26s -5s linear infinite;
}
@keyframes fall-80 {
  78.64% {
    transform: translate(41.0364vw, 78.64vh) scale(0.6813);
  }
  to {
    transform: translate(41.34215vw, 100vh) scale(0.6813);
  }
}
.snowfall .snow:nth-child(81) {
  opacity: 0.3587;
  transform: translate(58.7222vw, -10px) scale(0.0967);
  animation: fall-81 12s -8s linear infinite;
}
@keyframes fall-81 {
  30% {
    transform: translate(62.5652vw, 30vh) scale(0.0967);
  }
  to {
    transform: translate(60.6437vw, 100vh) scale(0.0967);
  }
}
.snowfall .snow:nth-child(82) {
  opacity: 0.0911;
  transform: translate(22.4592vw, -10px) scale(0.9165);
  animation: fall-82 24s -26s linear infinite;
}
@keyframes fall-82 {
  64.433% {
    transform: translate(22.4714vw, 64.433vh) scale(0.9165);
  }
  to {
    transform: translate(22.4653vw, 100vh) scale(0.9165);
  }
}
.snowfall .snow:nth-child(83) {
  opacity: 0.0409;
  transform: translate(93.3804vw, -10px) scale(0.8883);
  animation: fall-83 17s -18s linear infinite;
}
@keyframes fall-83 {
  74.858% {
    transform: translate(84.1637vw, 74.858vh) scale(0.8883);
  }
  to {
    transform: translate(88.77205vw, 100vh) scale(0.8883);
  }
}
.snowfall .snow:nth-child(84) {
  opacity: 0.1229;
  transform: translate(25.535vw, -10px) scale(0.3389);
  animation: fall-84 21s -8s linear infinite;
}
@keyframes fall-84 {
  65.17% {
    transform: translate(34.8273vw, 65.17vh) scale(0.3389);
  }
  to {
    transform: translate(30.18115vw, 100vh) scale(0.3389);
  }
}
.snowfall .snow:nth-child(85) {
  opacity: 0.4502;
  transform: translate(79.7269vw, -10px) scale(0.1964);
  animation: fall-85 13s -10s linear infinite;
}
@keyframes fall-85 {
  64.561% {
    transform: translate(88.8749vw, 64.561vh) scale(0.1964);
  }
  to {
    transform: translate(84.3009vw, 100vh) scale(0.1964);
  }
}
.snowfall .snow:nth-child(86) {
  opacity: 0.9;
  transform: translate(40.4682vw, -10px) scale(0.1462);
  animation: fall-86 28s -20s linear infinite;
}
@keyframes fall-86 {
  53.913% {
    transform: translate(30.7189vw, 53.913vh) scale(0.1462);
  }
  to {
    transform: translate(35.59355vw, 100vh) scale(0.1462);
  }
}
.snowfall .snow:nth-child(87) {
  opacity: 0.7339;
  transform: translate(94.3757vw, -10px) scale(0.6324);
  animation: fall-87 13s -8s linear infinite;
}
@keyframes fall-87 {
  73.472% {
    transform: translate(84.4159vw, 73.472vh) scale(0.6324);
  }
  to {
    transform: translate(89.3958vw, 100vh) scale(0.6324);
  }
}
.snowfall .snow:nth-child(88) {
  opacity: 0.2082;
  transform: translate(75.8722vw, -10px) scale(0.2701);
  animation: fall-88 17s -15s linear infinite;
}
@keyframes fall-88 {
  40.6% {
    transform: translate(85.4663vw, 40.6vh) scale(0.2701);
  }
  to {
    transform: translate(80.66925vw, 100vh) scale(0.2701);
  }
}
.snowfall .snow:nth-child(89) {
  opacity: 0.061;
  transform: translate(13.3541vw, -10px) scale(0.9951);
  animation: fall-89 23s -14s linear infinite;
}
@keyframes fall-89 {
  37.434% {
    transform: translate(18.536vw, 37.434vh) scale(0.9951);
  }
  to {
    transform: translate(15.94505vw, 100vh) scale(0.9951);
  }
}
.snowfall .snow:nth-child(90) {
  opacity: 0.276;
  transform: translate(21.5664vw, -10px) scale(0.3981);
  animation: fall-90 12s -28s linear infinite;
}
@keyframes fall-90 {
  38.278% {
    transform: translate(30.9412vw, 38.278vh) scale(0.3981);
  }
  to {
    transform: translate(26.2538vw, 100vh) scale(0.3981);
  }
}
.snowfall .snow:nth-child(91) {
  opacity: 0.8961;
  transform: translate(94.8572vw, -10px) scale(0.7837);
  animation: fall-91 17s -19s linear infinite;
}
@keyframes fall-91 {
  73.909% {
    transform: translate(104.3003vw, 73.909vh) scale(0.7837);
  }
  to {
    transform: translate(99.57875vw, 100vh) scale(0.7837);
  }
}
.snowfall .snow:nth-child(92) {
  opacity: 0.4065;
  transform: translate(61.2054vw, -10px) scale(0.1852);
  animation: fall-92 21s -9s linear infinite;
}
@keyframes fall-92 {
  45.99% {
    transform: translate(60.6518vw, 45.99vh) scale(0.1852);
  }
  to {
    transform: translate(60.9286vw, 100vh) scale(0.1852);
  }
}
.snowfall .snow:nth-child(93) {
  opacity: 0.2535;
  transform: translate(8.2267vw, -10px) scale(0.6262);
  animation: fall-93 22s -16s linear infinite;
}
@keyframes fall-93 {
  66.423% {
    transform: translate(-0.9007vw, 66.423vh) scale(0.6262);
  }
  to {
    transform: translate(3.663vw, 100vh) scale(0.6262);
  }
}
.snowfall .snow:nth-child(94) {
  opacity: 0.4293;
  transform: translate(3.414vw, -10px) scale(0.3089);
  animation: fall-94 12s -7s linear infinite;
}
@keyframes fall-94 {
  39.202% {
    transform: translate(-1.8893vw, 39.202vh) scale(0.3089);
  }
  to {
    transform: translate(0.76235vw, 100vh) scale(0.3089);
  }
}
.snowfall .snow:nth-child(95) {
  opacity: 0.6185;
  transform: translate(73.1278vw, -10px) scale(0.9192);
  animation: fall-95 15s -9s linear infinite;
}
@keyframes fall-95 {
  47.236% {
    transform: translate(82.7062vw, 47.236vh) scale(0.9192);
  }
  to {
    transform: translate(77.917vw, 100vh) scale(0.9192);
  }
}
.snowfall .snow:nth-child(96) {
  opacity: 0.8223;
  transform: translate(13.7681vw, -10px) scale(0.575);
  animation: fall-96 24s -29s linear infinite;
}
@keyframes fall-96 {
  57.287% {
    transform: translate(15.3479vw, 57.287vh) scale(0.575);
  }
  to {
    transform: translate(14.558vw, 100vh) scale(0.575);
  }
}
.snowfall .snow:nth-child(97) {
  opacity: 0.9582;
  transform: translate(24.6832vw, -10px) scale(0.6903);
  animation: fall-97 21s -19s linear infinite;
}
@keyframes fall-97 {
  76.502% {
    transform: translate(26.593vw, 76.502vh) scale(0.6903);
  }
  to {
    transform: translate(25.6381vw, 100vh) scale(0.6903);
  }
}
.snowfall .snow:nth-child(98) {
  opacity: 0.0274;
  transform: translate(76.0794vw, -10px) scale(0.2151);
  animation: fall-98 25s -7s linear infinite;
}
@keyframes fall-98 {
  78.361% {
    transform: translate(70.3753vw, 78.361vh) scale(0.2151);
  }
  to {
    transform: translate(73.22735vw, 100vh) scale(0.2151);
  }
}
.snowfall .snow:nth-child(99) {
  opacity: 0.0862;
  transform: translate(12.447vw, -10px) scale(0.3952);
  animation: fall-99 18s -7s linear infinite;
}
@keyframes fall-99 {
  43.883% {
    transform: translate(20.5978vw, 43.883vh) scale(0.3952);
  }
  to {
    transform: translate(16.5224vw, 100vh) scale(0.3952);
  }
}
.snowfall .snow:nth-child(100) {
  opacity: 0.6558;
  transform: translate(3.3721vw, -10px) scale(0.0266);
  animation: fall-100 12s -12s linear infinite;
}
@keyframes fall-100 {
  53.305% {
    transform: translate(2.9877vw, 53.305vh) scale(0.0266);
  }
  to {
    transform: translate(3.1799vw, 100vh) scale(0.0266);
  }
}
.snowfall .snow:nth-child(101) {
  opacity: 0.0593;
  transform: translate(38.1096vw, -10px) scale(0.5388);
  animation: fall-101 10s -15s linear infinite;
}
@keyframes fall-101 {
  75.312% {
    transform: translate(33.099vw, 75.312vh) scale(0.5388);
  }
  to {
    transform: translate(35.6043vw, 100vh) scale(0.5388);
  }
}
.snowfall .snow:nth-child(102) {
  opacity: 0.2029;
  transform: translate(50.3268vw, -10px) scale(0.4264);
  animation: fall-102 15s -23s linear infinite;
}
@keyframes fall-102 {
  49.697% {
    transform: translate(48.5078vw, 49.697vh) scale(0.4264);
  }
  to {
    transform: translate(49.4173vw, 100vh) scale(0.4264);
  }
}
.snowfall .snow:nth-child(103) {
  opacity: 0.1841;
  transform: translate(36.8283vw, -10px) scale(0.5778);
  animation: fall-103 20s -16s linear infinite;
}
@keyframes fall-103 {
  49.712% {
    transform: translate(41.4835vw, 49.712vh) scale(0.5778);
  }
  to {
    transform: translate(39.1559vw, 100vh) scale(0.5778);
  }
}
.snowfall .snow:nth-child(104) {
  opacity: 0.0698;
  transform: translate(30.3729vw, -10px) scale(0.9918);
  animation: fall-104 12s -7s linear infinite;
}
@keyframes fall-104 {
  68.79% {
    transform: translate(28.7782vw, 68.79vh) scale(0.9918);
  }
  to {
    transform: translate(29.57555vw, 100vh) scale(0.9918);
  }
}
.snowfall .snow:nth-child(105) {
  opacity: 0.8887;
  transform: translate(70.1227vw, -10px) scale(0.3867);
  animation: fall-105 11s -3s linear infinite;
}
@keyframes fall-105 {
  64.035% {
    transform: translate(73.0202vw, 64.035vh) scale(0.3867);
  }
  to {
    transform: translate(71.57145vw, 100vh) scale(0.3867);
  }
}
.snowfall .snow:nth-child(106) {
  opacity: 0.6648;
  transform: translate(14.508vw, -10px) scale(0.8609);
  animation: fall-106 21s -1s linear infinite;
}
@keyframes fall-106 {
  69.27% {
    transform: translate(8.8127vw, 69.27vh) scale(0.8609);
  }
  to {
    transform: translate(11.66035vw, 100vh) scale(0.8609);
  }
}
.snowfall .snow:nth-child(107) {
  opacity: 0.8428;
  transform: translate(21.9835vw, -10px) scale(0.348);
  animation: fall-107 27s -28s linear infinite;
}
@keyframes fall-107 {
  44.556% {
    transform: translate(12.8535vw, 44.556vh) scale(0.348);
  }
  to {
    transform: translate(17.4185vw, 100vh) scale(0.348);
  }
}
.snowfall .snow:nth-child(108) {
  opacity: 0.8629;
  transform: translate(25.8696vw, -10px) scale(0.1452);
  animation: fall-108 14s -16s linear infinite;
}
@keyframes fall-108 {
  46.93% {
    transform: translate(26.8669vw, 46.93vh) scale(0.1452);
  }
  to {
    transform: translate(26.36825vw, 100vh) scale(0.1452);
  }
}
.snowfall .snow:nth-child(109) {
  opacity: 0.4417;
  transform: translate(14.6941vw, -10px) scale(0.9362);
  animation: fall-109 23s -20s linear infinite;
}
@keyframes fall-109 {
  50.391% {
    transform: translate(15.5742vw, 50.391vh) scale(0.9362);
  }
  to {
    transform: translate(15.13415vw, 100vh) scale(0.9362);
  }
}
.snowfall .snow:nth-child(110) {
  opacity: 0.7436;
  transform: translate(93.3115vw, -10px) scale(0.1949);
  animation: fall-110 21s -18s linear infinite;
}
@keyframes fall-110 {
  74.381% {
    transform: translate(97.0164vw, 74.381vh) scale(0.1949);
  }
  to {
    transform: translate(95.16395vw, 100vh) scale(0.1949);
  }
}
.snowfall .snow:nth-child(111) {
  opacity: 0.0787;
  transform: translate(45.3702vw, -10px) scale(0.1628);
  animation: fall-111 11s -2s linear infinite;
}
@keyframes fall-111 {
  76.634% {
    transform: translate(40.4703vw, 76.634vh) scale(0.1628);
  }
  to {
    transform: translate(42.92025vw, 100vh) scale(0.1628);
  }
}
.snowfall .snow:nth-child(112) {
  opacity: 0.6845;
  transform: translate(8.3788vw, -10px) scale(0.9261);
  animation: fall-112 26s -7s linear infinite;
}
@keyframes fall-112 {
  77.905% {
    transform: translate(1.0311vw, 77.905vh) scale(0.9261);
  }
  to {
    transform: translate(4.70495vw, 100vh) scale(0.9261);
  }
}
.snowfall .snow:nth-child(113) {
  opacity: 0.0172;
  transform: translate(28.002vw, -10px) scale(0.5827);
  animation: fall-113 16s -12s linear infinite;
}
@keyframes fall-113 {
  41.095% {
    transform: translate(29.4545vw, 41.095vh) scale(0.5827);
  }
  to {
    transform: translate(28.72825vw, 100vh) scale(0.5827);
  }
}
.snowfall .snow:nth-child(114) {
  opacity: 0.9923;
  transform: translate(89.5748vw, -10px) scale(0.0194);
  animation: fall-114 10s -4s linear infinite;
}
@keyframes fall-114 {
  68.388% {
    transform: translate(92.0313vw, 68.388vh) scale(0.0194);
  }
  to {
    transform: translate(90.80305vw, 100vh) scale(0.0194);
  }
}
.snowfall .snow:nth-child(115) {
  opacity: 0.705;
  transform: translate(94.3064vw, -10px) scale(0.2549);
  animation: fall-115 30s -17s linear infinite;
}
@keyframes fall-115 {
  68.079% {
    transform: translate(100.6336vw, 68.079vh) scale(0.2549);
  }
  to {
    transform: translate(97.47vw, 100vh) scale(0.2549);
  }
}
.snowfall .snow:nth-child(116) {
  opacity: 0.0687;
  transform: translate(31.391vw, -10px) scale(0.6772);
  animation: fall-116 14s -16s linear infinite;
}
@keyframes fall-116 {
  64.873% {
    transform: translate(27.3291vw, 64.873vh) scale(0.6772);
  }
  to {
    transform: translate(29.36005vw, 100vh) scale(0.6772);
  }
}
.snowfall .snow:nth-child(117) {
  opacity: 0.4389;
  transform: translate(38.0066vw, -10px) scale(0.7742);
  animation: fall-117 18s -12s linear infinite;
}
@keyframes fall-117 {
  65.66% {
    transform: translate(38.4005vw, 65.66vh) scale(0.7742);
  }
  to {
    transform: translate(38.20355vw, 100vh) scale(0.7742);
  }
}
.snowfall .snow:nth-child(118) {
  opacity: 0.4934;
  transform: translate(88.4707vw, -10px) scale(0.7862);
  animation: fall-118 20s -15s linear infinite;
}
@keyframes fall-118 {
  48.432% {
    transform: translate(83.38vw, 48.432vh) scale(0.7862);
  }
  to {
    transform: translate(85.92535vw, 100vh) scale(0.7862);
  }
}
.snowfall .snow:nth-child(119) {
  opacity: 0.3951;
  transform: translate(80.0011vw, -10px) scale(0.9855);
  animation: fall-119 10s -2s linear infinite;
}
@keyframes fall-119 {
  40.58% {
    transform: translate(72.2729vw, 40.58vh) scale(0.9855);
  }
  to {
    transform: translate(76.137vw, 100vh) scale(0.9855);
  }
}
.snowfall .snow:nth-child(120) {
  opacity: 0.1652;
  transform: translate(68.6517vw, -10px) scale(0.5047);
  animation: fall-120 26s -22s linear infinite;
}
@keyframes fall-120 {
  53.748% {
    transform: translate(77.215vw, 53.748vh) scale(0.5047);
  }
  to {
    transform: translate(72.93335vw, 100vh) scale(0.5047);
  }
}
.snowfall .snow:nth-child(121) {
  opacity: 0.7839;
  transform: translate(68.7142vw, -10px) scale(0.3928);
  animation: fall-121 21s -4s linear infinite;
}
@keyframes fall-121 {
  45.123% {
    transform: translate(63.3914vw, 45.123vh) scale(0.3928);
  }
  to {
    transform: translate(66.0528vw, 100vh) scale(0.3928);
  }
}
.snowfall .snow:nth-child(122) {
  opacity: 0.0275;
  transform: translate(86.838vw, -10px) scale(0.0409);
  animation: fall-122 17s -21s linear infinite;
}
@keyframes fall-122 {
  56.089% {
    transform: translate(88.7167vw, 56.089vh) scale(0.0409);
  }
  to {
    transform: translate(87.77735vw, 100vh) scale(0.0409);
  }
}
.snowfall .snow:nth-child(123) {
  opacity: 0.0804;
  transform: translate(56.3078vw, -10px) scale(0.5444);
  animation: fall-123 30s -13s linear infinite;
}
@keyframes fall-123 {
  49.087% {
    transform: translate(49.103vw, 49.087vh) scale(0.5444);
  }
  to {
    transform: translate(52.7054vw, 100vh) scale(0.5444);
  }
}
.snowfall .snow:nth-child(124) {
  opacity: 0.3205;
  transform: translate(48.6921vw, -10px) scale(0.6515);
  animation: fall-124 13s -2s linear infinite;
}
@keyframes fall-124 {
  70.154% {
    transform: translate(39.2955vw, 70.154vh) scale(0.6515);
  }
  to {
    transform: translate(43.9938vw, 100vh) scale(0.6515);
  }
}
.snowfall .snow:nth-child(125) {
  opacity: 0.64;
  transform: translate(52.8329vw, -10px) scale(0.5956);
  animation: fall-125 22s -9s linear infinite;
}
@keyframes fall-125 {
  53.685% {
    transform: translate(46.2371vw, 53.685vh) scale(0.5956);
  }
  to {
    transform: translate(49.535vw, 100vh) scale(0.5956);
  }
}
.snowfall .snow:nth-child(126) {
  opacity: 0.0116;
  transform: translate(14.6601vw, -10px) scale(0.5569);
  animation: fall-126 13s -3s linear infinite;
}
@keyframes fall-126 {
  39.201% {
    transform: translate(19.4013vw, 39.201vh) scale(0.5569);
  }
  to {
    transform: translate(17.0307vw, 100vh) scale(0.5569);
  }
}
.snowfall .snow:nth-child(127) {
  opacity: 0.1474;
  transform: translate(6.2258vw, -10px) scale(0.8283);
  animation: fall-127 12s -16s linear infinite;
}
@keyframes fall-127 {
  36.765% {
    transform: translate(6.947vw, 36.765vh) scale(0.8283);
  }
  to {
    transform: translate(6.5864vw, 100vh) scale(0.8283);
  }
}
.snowfall .snow:nth-child(128) {
  opacity: 0.189;
  transform: translate(68.9068vw, -10px) scale(0.8127);
  animation: fall-128 19s -4s linear infinite;
}
@keyframes fall-128 {
  46.607% {
    transform: translate(63.6266vw, 46.607vh) scale(0.8127);
  }
  to {
    transform: translate(66.2667vw, 100vh) scale(0.8127);
  }
}
.snowfall .snow:nth-child(129) {
  opacity: 0.9394;
  transform: translate(40.7757vw, -10px) scale(0.0445);
  animation: fall-129 26s -21s linear infinite;
}
@keyframes fall-129 {
  73.039% {
    transform: translate(44.6179vw, 73.039vh) scale(0.0445);
  }
  to {
    transform: translate(42.6968vw, 100vh) scale(0.0445);
  }
}
.snowfall .snow:nth-child(130) {
  opacity: 0.1503;
  transform: translate(18.3848vw, -10px) scale(0.9123);
  animation: fall-130 23s -13s linear infinite;
}
@keyframes fall-130 {
  72.666% {
    transform: translate(8.7257vw, 72.666vh) scale(0.9123);
  }
  to {
    transform: translate(13.55525vw, 100vh) scale(0.9123);
  }
}
.snowfall .snow:nth-child(131) {
  opacity: 0.4315;
  transform: translate(81.3985vw, -10px) scale(0.3835);
  animation: fall-131 19s -29s linear infinite;
}
@keyframes fall-131 {
  64.361% {
    transform: translate(83.9454vw, 64.361vh) scale(0.3835);
  }
  to {
    transform: translate(82.67195vw, 100vh) scale(0.3835);
  }
}
.snowfall .snow:nth-child(132) {
  opacity: 0.054;
  transform: translate(63.6843vw, -10px) scale(0.8411);
  animation: fall-132 27s -11s linear infinite;
}
@keyframes fall-132 {
  67.579% {
    transform: translate(68.4952vw, 67.579vh) scale(0.8411);
  }
  to {
    transform: translate(66.08975vw, 100vh) scale(0.8411);
  }
}
.snowfall .snow:nth-child(133) {
  opacity: 0.9564;
  transform: translate(32.6782vw, -10px) scale(0.2814);
  animation: fall-133 25s -4s linear infinite;
}
@keyframes fall-133 {
  72.031% {
    transform: translate(29.0089vw, 72.031vh) scale(0.2814);
  }
  to {
    transform: translate(30.84355vw, 100vh) scale(0.2814);
  }
}
.snowfall .snow:nth-child(134) {
  opacity: 0.2601;
  transform: translate(71.8469vw, -10px) scale(0.3498);
  animation: fall-134 29s -11s linear infinite;
}
@keyframes fall-134 {
  36.561% {
    transform: translate(79.9236vw, 36.561vh) scale(0.3498);
  }
  to {
    transform: translate(75.88525vw, 100vh) scale(0.3498);
  }
}
.snowfall .snow:nth-child(135) {
  opacity: 0.8495;
  transform: translate(65.4016vw, -10px) scale(0.8374);
  animation: fall-135 15s -11s linear infinite;
}
@keyframes fall-135 {
  53.716% {
    transform: translate(55.7544vw, 53.716vh) scale(0.8374);
  }
  to {
    transform: translate(60.578vw, 100vh) scale(0.8374);
  }
}
.snowfall .snow:nth-child(136) {
  opacity: 0.3809;
  transform: translate(50.1689vw, -10px) scale(0.1234);
  animation: fall-136 15s -20s linear infinite;
}
@keyframes fall-136 {
  57.499% {
    transform: translate(50.5892vw, 57.499vh) scale(0.1234);
  }
  to {
    transform: translate(50.37905vw, 100vh) scale(0.1234);
  }
}
.snowfall .snow:nth-child(137) {
  opacity: 0.9119;
  transform: translate(60.573vw, -10px) scale(0.5293);
  animation: fall-137 12s -18s linear infinite;
}
@keyframes fall-137 {
  33.997% {
    transform: translate(62.2841vw, 33.997vh) scale(0.5293);
  }
  to {
    transform: translate(61.42855vw, 100vh) scale(0.5293);
  }
}
.snowfall .snow:nth-child(138) {
  opacity: 0.1106;
  transform: translate(48.2909vw, -10px) scale(0.7042);
  animation: fall-138 16s -30s linear infinite;
}
@keyframes fall-138 {
  61.875% {
    transform: translate(45.6576vw, 61.875vh) scale(0.7042);
  }
  to {
    transform: translate(46.97425vw, 100vh) scale(0.7042);
  }
}
.snowfall .snow:nth-child(139) {
  opacity: 0.9126;
  transform: translate(85.2573vw, -10px) scale(0.7573);
  animation: fall-139 19s -16s linear infinite;
}
@keyframes fall-139 {
  74.224% {
    transform: translate(78.4104vw, 74.224vh) scale(0.7573);
  }
  to {
    transform: translate(81.83385vw, 100vh) scale(0.7573);
  }
}
.snowfall .snow:nth-child(140) {
  opacity: 0.9532;
  transform: translate(42.8951vw, -10px) scale(0.6827);
  animation: fall-140 30s -27s linear infinite;
}
@keyframes fall-140 {
  68.249% {
    transform: translate(44.4268vw, 68.249vh) scale(0.6827);
  }
  to {
    transform: translate(43.66095vw, 100vh) scale(0.6827);
  }
}
.snowfall .snow:nth-child(141) {
  opacity: 0.1227;
  transform: translate(46.3337vw, -10px) scale(0.6124);
  animation: fall-141 26s -10s linear infinite;
}
@keyframes fall-141 {
  36.907% {
    transform: translate(52.331vw, 36.907vh) scale(0.6124);
  }
  to {
    transform: translate(49.33235vw, 100vh) scale(0.6124);
  }
}
.snowfall .snow:nth-child(142) {
  opacity: 0.9638;
  transform: translate(51.6559vw, -10px) scale(0.1895);
  animation: fall-142 30s -21s linear infinite;
}
@keyframes fall-142 {
  59.829% {
    transform: translate(48.2647vw, 59.829vh) scale(0.1895);
  }
  to {
    transform: translate(49.9603vw, 100vh) scale(0.1895);
  }
}
.snowfall .snow:nth-child(143) {
  opacity: 0.465;
  transform: translate(31.8937vw, -10px) scale(0.8829);
  animation: fall-143 16s -9s linear infinite;
}
@keyframes fall-143 {
  74.569% {
    transform: translate(28.7714vw, 74.569vh) scale(0.8829);
  }
  to {
    transform: translate(30.33255vw, 100vh) scale(0.8829);
  }
}
.snowfall .snow:nth-child(144) {
  opacity: 0.2817;
  transform: translate(7.235vw, -10px) scale(0.3202);
  animation: fall-144 24s -14s linear infinite;
}
@keyframes fall-144 {
  41.041% {
    transform: translate(-0.4012vw, 41.041vh) scale(0.3202);
  }
  to {
    transform: translate(3.4169vw, 100vh) scale(0.3202);
  }
}
.snowfall .snow:nth-child(145) {
  opacity: 0.5238;
  transform: translate(85.6532vw, -10px) scale(0.6388);
  animation: fall-145 15s -14s linear infinite;
}
@keyframes fall-145 {
  76.204% {
    transform: translate(90.0577vw, 76.204vh) scale(0.6388);
  }
  to {
    transform: translate(87.85545vw, 100vh) scale(0.6388);
  }
}
.snowfall .snow:nth-child(146) {
  opacity: 0.5472;
  transform: translate(44.1933vw, -10px) scale(0.4232);
  animation: fall-146 21s -28s linear infinite;
}
@keyframes fall-146 {
  72.287% {
    transform: translate(48.5368vw, 72.287vh) scale(0.4232);
  }
  to {
    transform: translate(46.36505vw, 100vh) scale(0.4232);
  }
}
.snowfall .snow:nth-child(147) {
  opacity: 0.5979;
  transform: translate(95.4256vw, -10px) scale(0.7513);
  animation: fall-147 22s -15s linear infinite;
}
@keyframes fall-147 {
  66.585% {
    transform: translate(95.8711vw, 66.585vh) scale(0.7513);
  }
  to {
    transform: translate(95.64835vw, 100vh) scale(0.7513);
  }
}
.snowfall .snow:nth-child(148) {
  opacity: 0.0206;
  transform: translate(81.825vw, -10px) scale(0.9331);
  animation: fall-148 26s -17s linear infinite;
}
@keyframes fall-148 {
  46.234% {
    transform: translate(91.8165vw, 46.234vh) scale(0.9331);
  }
  to {
    transform: translate(86.82075vw, 100vh) scale(0.9331);
  }
}
.snowfall .snow:nth-child(149) {
  opacity: 0.5959;
  transform: translate(63.8224vw, -10px) scale(0.5411);
  animation: fall-149 11s -3s linear infinite;
}
@keyframes fall-149 {
  54.333% {
    transform: translate(60.2234vw, 54.333vh) scale(0.5411);
  }
  to {
    transform: translate(62.0229vw, 100vh) scale(0.5411);
  }
}
.snowfall .snow:nth-child(150) {
  opacity: 0.4126;
  transform: translate(56.1465vw, -10px) scale(0.138);
  animation: fall-150 20s -20s linear infinite;
}
@keyframes fall-150 {
  76.28% {
    transform: translate(57.5568vw, 76.28vh) scale(0.138);
  }
  to {
    transform: translate(56.85165vw, 100vh) scale(0.138);
  }
}
.snowfall .snow:nth-child(151) {
  opacity: 0.3307;
  transform: translate(1.7021vw, -10px) scale(0.0081);
  animation: fall-151 15s -30s linear infinite;
}
@keyframes fall-151 {
  39.291% {
    transform: translate(11.4836vw, 39.291vh) scale(0.0081);
  }
  to {
    transform: translate(6.59285vw, 100vh) scale(0.0081);
  }
}
.snowfall .snow:nth-child(152) {
  opacity: 0.4631;
  transform: translate(64.4484vw, -10px) scale(0.3261);
  animation: fall-152 16s -6s linear infinite;
}
@keyframes fall-152 {
  45.083% {
    transform: translate(69.7908vw, 45.083vh) scale(0.3261);
  }
  to {
    transform: translate(67.1196vw, 100vh) scale(0.3261);
  }
}
.snowfall .snow:nth-child(153) {
  opacity: 0.2806;
  transform: translate(14.2654vw, -10px) scale(0.3983);
  animation: fall-153 28s -5s linear infinite;
}
@keyframes fall-153 {
  35.996% {
    transform: translate(17.6225vw, 35.996vh) scale(0.3983);
  }
  to {
    transform: translate(15.94395vw, 100vh) scale(0.3983);
  }
}
.snowfall .snow:nth-child(154) {
  opacity: 0.7697;
  transform: translate(44.9934vw, -10px) scale(0.6496);
  animation: fall-154 12s -17s linear infinite;
}
@keyframes fall-154 {
  34.272% {
    transform: translate(48.1314vw, 34.272vh) scale(0.6496);
  }
  to {
    transform: translate(46.5624vw, 100vh) scale(0.6496);
  }
}
.snowfall .snow:nth-child(155) {
  opacity: 0.9909;
  transform: translate(16.6814vw, -10px) scale(0.8771);
  animation: fall-155 30s -22s linear infinite;
}
@keyframes fall-155 {
  60.038% {
    transform: translate(9.8617vw, 60.038vh) scale(0.8771);
  }
  to {
    transform: translate(13.27155vw, 100vh) scale(0.8771);
  }
}
.snowfall .snow:nth-child(156) {
  opacity: 0.0176;
  transform: translate(5.2087vw, -10px) scale(0.5034);
  animation: fall-156 18s -30s linear infinite;
}
@keyframes fall-156 {
  77.994% {
    transform: translate(2.2614vw, 77.994vh) scale(0.5034);
  }
  to {
    transform: translate(3.73505vw, 100vh) scale(0.5034);
  }
}
.snowfall .snow:nth-child(157) {
  opacity: 0.0357;
  transform: translate(99.5603vw, -10px) scale(0.3468);
  animation: fall-157 29s -28s linear infinite;
}
@keyframes fall-157 {
  33.813% {
    transform: translate(91.5324vw, 33.813vh) scale(0.3468);
  }
  to {
    transform: translate(95.54635vw, 100vh) scale(0.3468);
  }
}
.snowfall .snow:nth-child(158) {
  opacity: 0.5391;
  transform: translate(67.9593vw, -10px) scale(0.069);
  animation: fall-158 28s -3s linear infinite;
}
@keyframes fall-158 {
  37.685% {
    transform: translate(77.0244vw, 37.685vh) scale(0.069);
  }
  to {
    transform: translate(72.49185vw, 100vh) scale(0.069);
  }
}
.snowfall .snow:nth-child(159) {
  opacity: 0.0957;
  transform: translate(47.3995vw, -10px) scale(0.5261);
  animation: fall-159 16s -18s linear infinite;
}
@keyframes fall-159 {
  72.678% {
    transform: translate(49.7769vw, 72.678vh) scale(0.5261);
  }
  to {
    transform: translate(48.5882vw, 100vh) scale(0.5261);
  }
}
.snowfall .snow:nth-child(160) {
  opacity: 0.6346;
  transform: translate(11.2843vw, -10px) scale(0.8119);
  animation: fall-160 15s -7s linear infinite;
}
@keyframes fall-160 {
  72.191% {
    transform: translate(8.7524vw, 72.191vh) scale(0.8119);
  }
  to {
    transform: translate(10.01835vw, 100vh) scale(0.8119);
  }
}
.snowfall .snow:nth-child(161) {
  opacity: 0.7837;
  transform: translate(6.8693vw, -10px) scale(0.5485);
  animation: fall-161 26s -27s linear infinite;
}
@keyframes fall-161 {
  32.449% {
    transform: translate(14.4449vw, 32.449vh) scale(0.5485);
  }
  to {
    transform: translate(10.6571vw, 100vh) scale(0.5485);
  }
}
.snowfall .snow:nth-child(162) {
  opacity: 0.0004;
  transform: translate(90.5271vw, -10px) scale(0.1424);
  animation: fall-162 21s -28s linear infinite;
}
@keyframes fall-162 {
  67.619% {
    transform: translate(88.5114vw, 67.619vh) scale(0.1424);
  }
  to {
    transform: translate(89.51925vw, 100vh) scale(0.1424);
  }
}
.snowfall .snow:nth-child(163) {
  opacity: 0.0415;
  transform: translate(27.544vw, -10px) scale(0.5202);
  animation: fall-163 21s -19s linear infinite;
}
@keyframes fall-163 {
  59.343% {
    transform: translate(19.1275vw, 59.343vh) scale(0.5202);
  }
  to {
    transform: translate(23.33575vw, 100vh) scale(0.5202);
  }
}
.snowfall .snow:nth-child(164) {
  opacity: 0.4104;
  transform: translate(66.3578vw, -10px) scale(0.1464);
  animation: fall-164 13s -1s linear infinite;
}
@keyframes fall-164 {
  55.159% {
    transform: translate(74.2088vw, 55.159vh) scale(0.1464);
  }
  to {
    transform: translate(70.2833vw, 100vh) scale(0.1464);
  }
}
.snowfall .snow:nth-child(165) {
  opacity: 0.9763;
  transform: translate(14.8014vw, -10px) scale(0.7686);
  animation: fall-165 22s -19s linear infinite;
}
@keyframes fall-165 {
  47.152% {
    transform: translate(13.2284vw, 47.152vh) scale(0.7686);
  }
  to {
    transform: translate(14.0149vw, 100vh) scale(0.7686);
  }
}
.snowfall .snow:nth-child(166) {
  opacity: 0.1187;
  transform: translate(40.4943vw, -10px) scale(0.3003);
  animation: fall-166 25s -18s linear infinite;
}
@keyframes fall-166 {
  38.056% {
    transform: translate(49.0345vw, 38.056vh) scale(0.3003);
  }
  to {
    transform: translate(44.7644vw, 100vh) scale(0.3003);
  }
}
.snowfall .snow:nth-child(167) {
  opacity: 0.2943;
  transform: translate(78.9622vw, -10px) scale(0.5677);
  animation: fall-167 18s -14s linear infinite;
}
@keyframes fall-167 {
  65.412% {
    transform: translate(83.3453vw, 65.412vh) scale(0.5677);
  }
  to {
    transform: translate(81.15375vw, 100vh) scale(0.5677);
  }
}
.snowfall .snow:nth-child(168) {
  opacity: 0.505;
  transform: translate(7.6896vw, -10px) scale(0.0002);
  animation: fall-168 14s -6s linear infinite;
}
@keyframes fall-168 {
  46.097% {
    transform: translate(10.5489vw, 46.097vh) scale(0.0002);
  }
  to {
    transform: translate(9.11925vw, 100vh) scale(0.0002);
  }
}
.snowfall .snow:nth-child(169) {
  opacity: 0.9911;
  transform: translate(99.5053vw, -10px) scale(0.8298);
  animation: fall-169 10s -24s linear infinite;
}
@keyframes fall-169 {
  40.868% {
    transform: translate(99.6119vw, 40.868vh) scale(0.8298);
  }
  to {
    transform: translate(99.5586vw, 100vh) scale(0.8298);
  }
}
.snowfall .snow:nth-child(170) {
  opacity: 0.1631;
  transform: translate(67.8836vw, -10px) scale(0.1813);
  animation: fall-170 23s -19s linear infinite;
}
@keyframes fall-170 {
  60.2% {
    transform: translate(75.525vw, 60.2vh) scale(0.1813);
  }
  to {
    transform: translate(71.7043vw, 100vh) scale(0.1813);
  }
}
.snowfall .snow:nth-child(171) {
  opacity: 0.8648;
  transform: translate(31.1206vw, -10px) scale(0.3416);
  animation: fall-171 28s -18s linear infinite;
}
@keyframes fall-171 {
  30.179% {
    transform: translate(27.2597vw, 30.179vh) scale(0.3416);
  }
  to {
    transform: translate(29.19015vw, 100vh) scale(0.3416);
  }
}
.snowfall .snow:nth-child(172) {
  opacity: 0.5027;
  transform: translate(14.4776vw, -10px) scale(0.804);
  animation: fall-172 28s -17s linear infinite;
}
@keyframes fall-172 {
  76.597% {
    transform: translate(18.4167vw, 76.597vh) scale(0.804);
  }
  to {
    transform: translate(16.44715vw, 100vh) scale(0.804);
  }
}
.snowfall .snow:nth-child(173) {
  opacity: 0.2925;
  transform: translate(36.6217vw, -10px) scale(0.706);
  animation: fall-173 29s -13s linear infinite;
}
@keyframes fall-173 {
  57.986% {
    transform: translate(35.3203vw, 57.986vh) scale(0.706);
  }
  to {
    transform: translate(35.971vw, 100vh) scale(0.706);
  }
}
.snowfall .snow:nth-child(174) {
  opacity: 0.478;
  transform: translate(80.6482vw, -10px) scale(0.3005);
  animation: fall-174 10s -16s linear infinite;
}
@keyframes fall-174 {
  72.351% {
    transform: translate(78.3155vw, 72.351vh) scale(0.3005);
  }
  to {
    transform: translate(79.48185vw, 100vh) scale(0.3005);
  }
}
.snowfall .snow:nth-child(175) {
  opacity: 0.1628;
  transform: translate(37.4203vw, -10px) scale(0.5898);
  animation: fall-175 17s -24s linear infinite;
}
@keyframes fall-175 {
  35.036% {
    transform: translate(40.1621vw, 35.036vh) scale(0.5898);
  }
  to {
    transform: translate(38.7912vw, 100vh) scale(0.5898);
  }
}
.snowfall .snow:nth-child(176) {
  opacity: 0.5086;
  transform: translate(41.2865vw, -10px) scale(0.0172);
  animation: fall-176 29s -7s linear infinite;
}
@keyframes fall-176 {
  64.34% {
    transform: translate(47.531vw, 64.34vh) scale(0.0172);
  }
  to {
    transform: translate(44.40875vw, 100vh) scale(0.0172);
  }
}
.snowfall .snow:nth-child(177) {
  opacity: 0.3354;
  transform: translate(10.0165vw, -10px) scale(0.1428);
  animation: fall-177 22s -23s linear infinite;
}
@keyframes fall-177 {
  76.029% {
    transform: translate(0.5139vw, 76.029vh) scale(0.1428);
  }
  to {
    transform: translate(5.2652vw, 100vh) scale(0.1428);
  }
}
.snowfall .snow:nth-child(178) {
  opacity: 0.6819;
  transform: translate(68.8475vw, -10px) scale(0.9451);
  animation: fall-178 27s -7s linear infinite;
}
@keyframes fall-178 {
  76.432% {
    transform: translate(75.5457vw, 76.432vh) scale(0.9451);
  }
  to {
    transform: translate(72.1966vw, 100vh) scale(0.9451);
  }
}
.snowfall .snow:nth-child(179) {
  opacity: 0.4221;
  transform: translate(36.8296vw, -10px) scale(0.7449);
  animation: fall-179 21s -30s linear infinite;
}
@keyframes fall-179 {
  45.108% {
    transform: translate(35.6097vw, 45.108vh) scale(0.7449);
  }
  to {
    transform: translate(36.21965vw, 100vh) scale(0.7449);
  }
}
.snowfall .snow:nth-child(180) {
  opacity: 0.111;
  transform: translate(46.7232vw, -10px) scale(0.1239);
  animation: fall-180 18s -28s linear infinite;
}
@keyframes fall-180 {
  31.477% {
    transform: translate(52.6404vw, 31.477vh) scale(0.1239);
  }
  to {
    transform: translate(49.6818vw, 100vh) scale(0.1239);
  }
}
.snowfall .snow:nth-child(181) {
  opacity: 0.2878;
  transform: translate(32.9334vw, -10px) scale(0.3735);
  animation: fall-181 16s -19s linear infinite;
}
@keyframes fall-181 {
  75.07% {
    transform: translate(40.865vw, 75.07vh) scale(0.3735);
  }
  to {
    transform: translate(36.8992vw, 100vh) scale(0.3735);
  }
}
.snowfall .snow:nth-child(182) {
  opacity: 0.1658;
  transform: translate(48.3877vw, -10px) scale(0.2887);
  animation: fall-182 19s -30s linear infinite;
}
@keyframes fall-182 {
  32.076% {
    transform: translate(42.0485vw, 32.076vh) scale(0.2887);
  }
  to {
    transform: translate(45.2181vw, 100vh) scale(0.2887);
  }
}
.snowfall .snow:nth-child(183) {
  opacity: 0.4892;
  transform: translate(59.9345vw, -10px) scale(0.0992);
  animation: fall-183 18s -16s linear infinite;
}
@keyframes fall-183 {
  78.44% {
    transform: translate(62.6436vw, 78.44vh) scale(0.0992);
  }
  to {
    transform: translate(61.28905vw, 100vh) scale(0.0992);
  }
}
.snowfall .snow:nth-child(184) {
  opacity: 0.4224;
  transform: translate(3.5778vw, -10px) scale(0.4538);
  animation: fall-184 20s -9s linear infinite;
}
@keyframes fall-184 {
  60.604% {
    transform: translate(4.4643vw, 60.604vh) scale(0.4538);
  }
  to {
    transform: translate(4.02105vw, 100vh) scale(0.4538);
  }
}
.snowfall .snow:nth-child(185) {
  opacity: 0.6336;
  transform: translate(0.5058vw, -10px) scale(0.9389);
  animation: fall-185 16s -22s linear infinite;
}
@keyframes fall-185 {
  36.084% {
    transform: translate(5.351vw, 36.084vh) scale(0.9389);
  }
  to {
    transform: translate(2.9284vw, 100vh) scale(0.9389);
  }
}
.snowfall .snow:nth-child(186) {
  opacity: 0.0354;
  transform: translate(18.9976vw, -10px) scale(0.4012);
  animation: fall-186 14s -4s linear infinite;
}
@keyframes fall-186 {
  76.748% {
    transform: translate(17.0229vw, 76.748vh) scale(0.4012);
  }
  to {
    transform: translate(18.01025vw, 100vh) scale(0.4012);
  }
}
.snowfall .snow:nth-child(187) {
  opacity: 0.3005;
  transform: translate(46.3382vw, -10px) scale(0.0686);
  animation: fall-187 23s -5s linear infinite;
}
@keyframes fall-187 {
  66.012% {
    transform: translate(54.2272vw, 66.012vh) scale(0.0686);
  }
  to {
    transform: translate(50.2827vw, 100vh) scale(0.0686);
  }
}
.snowfall .snow:nth-child(188) {
  opacity: 0.8961;
  transform: translate(5.4927vw, -10px) scale(0.4254);
  animation: fall-188 13s -26s linear infinite;
}
@keyframes fall-188 {
  35.089% {
    transform: translate(12.5239vw, 35.089vh) scale(0.4254);
  }
  to {
    transform: translate(9.0083vw, 100vh) scale(0.4254);
  }
}
.snowfall .snow:nth-child(189) {
  opacity: 0.6822;
  transform: translate(27.5159vw, -10px) scale(0.8968);
  animation: fall-189 27s -7s linear infinite;
}
@keyframes fall-189 {
  32.479% {
    transform: translate(21.152vw, 32.479vh) scale(0.8968);
  }
  to {
    transform: translate(24.33395vw, 100vh) scale(0.8968);
  }
}
.snowfall .snow:nth-child(190) {
  opacity: 0.2973;
  transform: translate(79.4759vw, -10px) scale(0.1034);
  animation: fall-190 25s -29s linear infinite;
}
@keyframes fall-190 {
  33.801% {
    transform: translate(84.3527vw, 33.801vh) scale(0.1034);
  }
  to {
    transform: translate(81.9143vw, 100vh) scale(0.1034);
  }
}
.snowfall .snow:nth-child(191) {
  opacity: 0.2947;
  transform: translate(69.761vw, -10px) scale(0.933);
  animation: fall-191 15s -7s linear infinite;
}
@keyframes fall-191 {
  36.007% {
    transform: translate(62.2384vw, 36.007vh) scale(0.933);
  }
  to {
    transform: translate(65.9997vw, 100vh) scale(0.933);
  }
}
.snowfall .snow:nth-child(192) {
  opacity: 0.8745;
  transform: translate(30.6633vw, -10px) scale(0.2463);
  animation: fall-192 10s -1s linear infinite;
}
@keyframes fall-192 {
  58.407% {
    transform: translate(21.5727vw, 58.407vh) scale(0.2463);
  }
  to {
    transform: translate(26.118vw, 100vh) scale(0.2463);
  }
}
.snowfall .snow:nth-child(193) {
  opacity: 0.9153;
  transform: translate(93.0737vw, -10px) scale(0.4281);
  animation: fall-193 14s -11s linear infinite;
}
@keyframes fall-193 {
  34.726% {
    transform: translate(88.2724vw, 34.726vh) scale(0.4281);
  }
  to {
    transform: translate(90.67305vw, 100vh) scale(0.4281);
  }
}
.snowfall .snow:nth-child(194) {
  opacity: 0.0283;
  transform: translate(10.573vw, -10px) scale(0.7737);
  animation: fall-194 16s -1s linear infinite;
}
@keyframes fall-194 {
  50.746% {
    transform: translate(20.0957vw, 50.746vh) scale(0.7737);
  }
  to {
    transform: translate(15.33435vw, 100vh) scale(0.7737);
  }
}
.snowfall .snow:nth-child(195) {
  opacity: 0.9205;
  transform: translate(87.9832vw, -10px) scale(0.8091);
  animation: fall-195 22s -21s linear infinite;
}
@keyframes fall-195 {
  36.417% {
    transform: translate(90.9502vw, 36.417vh) scale(0.8091);
  }
  to {
    transform: translate(89.4667vw, 100vh) scale(0.8091);
  }
}
.snowfall .snow:nth-child(196) {
  opacity: 0.6933;
  transform: translate(94.1287vw, -10px) scale(0.6175);
  animation: fall-196 30s -25s linear infinite;
}
@keyframes fall-196 {
  73.787% {
    transform: translate(95.6398vw, 73.787vh) scale(0.6175);
  }
  to {
    transform: translate(94.88425vw, 100vh) scale(0.6175);
  }
}
.snowfall .snow:nth-child(197) {
  opacity: 0.0466;
  transform: translate(11.0628vw, -10px) scale(0.6737);
  animation: fall-197 26s -7s linear infinite;
}
@keyframes fall-197 {
  35.913% {
    transform: translate(16.9427vw, 35.913vh) scale(0.6737);
  }
  to {
    transform: translate(14.00275vw, 100vh) scale(0.6737);
  }
}
.snowfall .snow:nth-child(198) {
  opacity: 0.5643;
  transform: translate(62.7587vw, -10px) scale(0.8973);
  animation: fall-198 12s -1s linear infinite;
}
@keyframes fall-198 {
  42.239% {
    transform: translate(55.6351vw, 42.239vh) scale(0.8973);
  }
  to {
    transform: translate(59.1969vw, 100vh) scale(0.8973);
  }
}
.snowfall .snow:nth-child(199) {
  opacity: 0.8372;
  transform: translate(42.4147vw, -10px) scale(0.9748);
  animation: fall-199 28s -27s linear infinite;
}
@keyframes fall-199 {
  61.616% {
    transform: translate(41.5598vw, 61.616vh) scale(0.9748);
  }
  to {
    transform: translate(41.98725vw, 100vh) scale(0.9748);
  }
}
.snowfall .snow:nth-child(200) {
  opacity: 0.5202;
  transform: translate(3.5734vw, -10px) scale(0.5317);
  animation: fall-200 25s -11s linear infinite;
}
@keyframes fall-200 {
  68.399% {
    transform: translate(-3.8046vw, 68.399vh) scale(0.5317);
  }
  to {
    transform: translate(-0.1156vw, 100vh) scale(0.5317);
  }
}

body {
  overflow-x: hidden;
}

.video_deer {
  position: absolute;
  z-index: 99999;
  top: 20%;
  left: 0px;
  height: 600px;
  width: 100%;
  animation: linear;
  -webkit-animation: linear;
  -webkit-animation-name: run;
  -webkit-animation-duration: 14s;
  animation-fill-mode: forwards;
}
.video_deer img {
  height: 100%;
  max-width: 100%;
  object-fit: contain;
}

@keyframes run {
  0% {
    left: -100%;
  }
  50% {
    left: 100%;
  }
  100% {
    left: 100%;
    visibility: hidden;
    display: none;
  }
}
@-webkit-keyframes run {
  0% {
    left: 0;
  }
  50% {
    left: 100%;
  }
  100% {
    left: 100%;
    display: none;
  }
}
/******************* Christmas ***********/
.animation_player {
  height: auto;
  background-color: #fff;
  margin-top: 30px;
  position: relative;
  transition: 0.2s all ease;
}
.animation_player .anim_video {
  min-height: 500px;
}
.animation_player .ctrl_click {
  position: absolute;
  width: 100%;
  height: 100%;
  background: transparent;
  z-index: 9;
}
.animation_player:hover::after, .animation_player:active::after {
  opacity: 1;
  display: block;
  visibility: visible;
}
.animation_player:hover .text_exp {
  bottom: 70px;
}
.animation_player:hover .controller_sec, .animation_player:active .controller_sec {
  display: flex;
  visibility: visible;
  opacity: 1;
}
.animation_player::after {
  content: "";
  width: 100%;
  height: 50%;
  pointer-events: none;
  background: linear-gradient(to top, rgba(56, 56, 56, 0.82), rgba(78, 78, 78, 0.22), rgba(255, 255, 255, 0));
  position: absolute;
  z-index: 0;
  bottom: 0;
  opacity: 0;
  display: block;
  transition: 0.2s all ease;
}

.a-enter-ar-button,
.a-enter-vr-button {
  z-index: 9 !important;
}

.a-enter-ar,
.a-enter-vr {
  bottom: unset !important;
  top: 40px;
}

.controller_sec {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: none;
  z-index: 99;
  padding: 20px;
  align-items: center;
  justify-content: space-between;
}
.controller_sec button:hover i {
  opacity: 1;
}
.controller_sec .time_slider {
  position: absolute;
  width: 100%;
  bottom: 70px;
  left: 0;
  right: 0;
}
.controller_sec .time_slider input {
  border-radius: 0;
}
.controller_sec .full_screen {
  width: 190px;
  text-align: right;
}
.controller_sec .left_ctrls {
  display: flex;
  width: 190px;
}
.controller_sec .left_ctrls .dropdown-menu.show {
  display: block;
  border: none;
  box-shadow: 2px 3px 10px rgba(0, 0, 0, 0.16);
}
.controller_sec .left_ctrls .dropdown-menu.show li {
  margin: 3px 0;
}
.controller_sec .left_ctrls .dropdown-menu.show button {
  font-size: 14px;
}
.controller_sec .left_ctrls .text_shw {
  position: relative;
}
.controller_sec .left_ctrls .text_shw::after {
  content: "";
  border: 2px solid #fff;
  position: absolute;
  width: 23px;
  background: #fff;
  left: 0;
  right: 0;
  margin: auto;
  top: 31px;
  border-radius: 10px;
}
.controller_sec .left_ctrls .volume_btn {
  position: relative;
  padding: 0 5px;
}
.controller_sec .left_ctrls .vol-neg {
  position: relative;
}
.controller_sec .left_ctrls .vol-neg::after {
  content: "";
  border: 2px solid #fff;
  position: absolute;
  width: 30px;
  border-radius: 10px;
  background: #fff;
  transform: rotate(45deg);
  left: 6px;
  top: 10px;
}
.controller_sec .mid_ctrls button {
  margin: 0 10px;
}
.controller_sec .mid_ctrls .play_pause {
  font-size: 1.5em;
  min-width: 45px;
  transition: 0.5s all ease;
}
.controller_sec .mid_ctrls .play_pause i {
  transition: 0.5s all ease;
}
.controller_sec .volm {
  margin-left: 20px;
}
.controller_sec .next_play {
  transform: scaleX(-1);
}
.controller_sec .dropdown-toggle::after {
  display: none;
}
.controller_sec .dropdown-toggle span {
  font-size: 16px;
  font-weight: 600;
  position: relative;
  bottom: 3px;
  margin-left: 5px;
  color: #fff;
}
.controller_sec i {
  font-size: 1.6em;
  color: #fff;
  opacity: 0.75;
  transition: 0.25s all ease;
}

.text_exp {
  color: #202020;
  line-height: 28px;
  font-size: 18px;
  font-weight: 600;
  width: auto;
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0px;
  transition: 0.3s all ease;
  padding: 20px 30px;
  background: rgba(241, 241, 241, 0.7);
  z-index: 9;
  pointer-events: none;
  margin: 30px;
}
.text_exp p {
  margin-bottom: 0;
}

/******* Lessons Video Animation ***********/
.error_blk {
  position: relative;
  background: #f1c72e;
  height: 40px;
  margin-top: 12px;
  display: flex;
  align-items: center;
  padding: 0 14px;
}
.error_blk h4 {
  font-size: 14px;
  text-align: center;
  margin-bottom: 0;
  font-weight: 500;
}

.medium-zoom-overlay {
  z-index: 9999;
}

.medium-zoom-image--opened {
  z-index: 99999;
}

/****** Responsive Section *****/
@media (min-width: 1400px) and (max-width: 1520px) {
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
}
@media (max-width: 767px) {
  .christmas_wish {
    right: 20px;
  }
  .christmas_wish img {
    height: 135px;
  }
  .logo_wrap img {
    height: 40px;
  }
  .signup_form_area {
    padding: 30px 15px;
  }
  .diamond_2 {
    left: 117px;
  }
  .diamond_1 {
    left: 80px;
  }
  .one {
    right: -97px;
  }
  .two {
    left: 113px;
  }
  .p_block {
    margin-bottom: 20px;
  }
  .p_block .p_block_contents ul li p {
    max-width: 205px;
  }
  .subsc_sec .subs_btm {
    flex-direction: column;
  }
  .subsc_sec .subs_btm img {
    transform: rotate(90deg);
    margin-top: 50px;
    width: 45px;
  }
  .subsc_sec .price_val {
    padding-top: 40px;
  }
  .quiz_modal .modal-header .mcq_progress {
    width: 86%;
  }
  .offer_popup .offer_block .offer_sec h1 {
    font-size: 50px;
  }
  .offer_popup .offer_block .off_btn_blk {
    margin-top: 25px;
  }
  .offer_popup .offer_block .offer_details {
    flex-direction: column;
    margin-top: 25px;
  }
  .offer_popup .offer_block .offer_details .off_opt {
    width: 100% !important;
    padding: 20px 0;
  }
  .offer_popup .offer_block .offer_details .off_opt:nth-child(2) {
    border-left: none !important;
    padding-left: 0 !important;
    border-top: 1px solid #161616;
  }
  .top_header h2 {
    font-size: 18px;
    max-width: 200px;
    margin-top: 5px;
    margin-left: 15px;
  }
  .right-head .btn_common,
  .right-head .btn_grey {
    width: 100px;
  }
  .prm_content_popup .prm_wrap {
    flex-direction: column;
  }
  .prm_content_popup .prm_wrap .prm_left {
    width: 100%;
  }
  .prm_content_popup .prm_wrap .prm_right {
    position: relative;
    width: 100%;
    flex-direction: row;
    justify-content: space-around;
    padding: 0 15px;
  }
  .prm_content_popup .prm_wrap .prm_right .play_store {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .prm_content_popup .prm_wrap .prm_right .play_store a {
    margin-bottom: 10px;
  }
  .prm_content_popup .prm_wrap .exp_vid {
    display: none;
  }
  .prm_content_popup .prm_wrap .exp_video_sec .exp_txt {
    max-width: 100%;
  }
  .prm_content_popup .prm_wrap .qr_code {
    display: none;
  }
  .prm_content_popup .prm_wrap .prm_right h2 {
    margin: 0;
  }
  .activity_header h2 {
    font-size: 18px;
    padding-right: 7px;
  }
  .suggest_block {
    padding-left: 15px;
    border-left: none;
    border-top: 1px solid #ebebeb;
  }
  .animation_player .text_exp {
    margin: 10px;
    z-index: 99;
    font-size: 12px;
    padding: 10px 12px;
    line-height: 16px;
  }
  .animation_player:hover .text_exp {
    bottom: 0px;
  }
  .animation_player:hover::after {
    display: none;
  }
  .animation_player:hover .controller_sec {
    display: none;
  }
  .show_ctrl .text_exp {
    bottom: 85px !important;
  }
  .show_ctrl::after {
    display: block !important;
    height: 100% !important;
    background: rgba(0, 0, 0, 0.3) !important;
    opacity: 1;
  }
  .show_ctrl .controller_sec {
    z-index: 999 !important;
    display: flex !important;
    opacity: 1;
  }
  .show_ctrl .controller_sec .volm {
    margin-left: 12px;
  }
  .show_ctrl .controller_sec .mid_ctrls {
    bottom: 250px;
    margin: auto;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 999;
    position: absolute;
  }
  .lesson_block {
    padding: 30px 15px 30px 15px;
  }
  .top_header .search_n_profile {
    display: flex;
    justify-content: space-between;
  }
  .main_area .top_header {
    padding-left: 50px;
  }
  .menu_collapse {
    left: 15px;
    top: 35px;
  }
  .top_header .account_area a,
  .top_header .account_area button {
    padding-right: 15px;
  }
  .top_header .account_area .dropdown-toggle .usr_name {
    display: none;
  }
  .search_box {
    width: 100%;
    padding: 0 10px;
  }
  .search_box input {
    display: block;
  }
  .search_box input::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #979797;
    opacity: 1; /* Firefox */
    font-size: 14px;
    width: 48px !important;
    font-weight: 500;
    overflow: hidden;
    white-space: nowrap;
  }
  .search_box input::-moz-placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #979797;
    opacity: 1; /* Firefox */
    font-size: 14px;
    width: 48px !important;
    font-weight: 500;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .search_box input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #979797;
    font-size: 14px;
    width: 48px;
    font-weight: 500;
    width: 50px;
    overflow: hidden;
    white-space: nowrap;
  }
  .search_box input::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #979797;
    font-size: 14px;
    width: 48px;
    font-weight: 500;
    width: 50px;
    overflow: hidden;
    white-space: nowrap;
  }
  .short_cut_links {
    bottom: 7px;
    left: 0;
    width: 100%;
    overflow: scroll;
  }
  .short_cut_links ul {
    display: flex;
  }
  .short_cut_links ul li button {
    min-width: 75px;
  }
}
@media (max-width: 991px) {
  .prm_content_popup .exp_video_sec .exp_vid {
    width: 171px;
    height: 196px;
    border-radius: 12px;
  }
  .prm_content_popup .modal-dialog {
    max-width: 620px;
  }
  .login_check label {
    max-width: 140px;
  }
  .left_block .shape_block .shape_1 {
    margin-right: 25px;
  }
  .left_block .shape_block .shape_1 img {
    height: 13em;
  }
  .left_block .shape_block .shape_2 {
    right: 192px;
    margin-top: 182px;
  }
  .login_text_block {
    bottom: 40px;
    left: 40px;
  }
  .login_text_block h1 {
    font-size: 2rem;
  }
}
@media (max-width: 1024px) {
  .shape_block .shape_2 {
    right: 272px;
    margin-top: 215px;
  }
  .shape_block .shape_1 {
    margin-right: 50px;
  }
}
@media (max-width: 1441px) {
  .animation_player {
    min-height: 365px;
  }
}
/********* Responsive Menu *********/
@media (max-width: 1024px) {
  .left_menu {
    left: -250px;
    z-index: 99999;
  }
  .main_area {
    padding-left: 0;
  }
  .top_header {
    left: 0;
    padding-left: 70px;
  }
  .menu_collapse {
    z-index: 999999;
  }
  .menu_shrink .top_header {
    left: 0;
  }
  .menu_shrink .left_menu {
    left: 0px;
    width: 250px;
    box-shadow: 4px 0px 10px rgba(0, 0, 0, 0.08);
    border-right: none;
  }
  .menu_shrink .menu_sec ul li a,
  .menu_shrink .logo_sec img,
  .menu_shrink .logo_sec span {
    opacity: 1;
  }
}
/********* Responsive Menu *********/
/******* Standard Lessons ***********/
.standard_player {
  min-height: 500px;
  background-color: #fff;
  margin-top: 30px;
  position: relative;
}
.standard_player:hover .controller_sec {
  display: flex;
}
.standard_player::after {
  content: "";
  width: 100%;
  height: 50%;
  pointer-events: none;
  background: linear-gradient(to top, rgba(56, 56, 56, 0.82), rgba(78, 78, 78, 0.22), rgba(255, 255, 255, 0));
  position: absolute;
  z-index: 9;
  bottom: 0;
  opacity: 0;
  transition: 0.2s all ease;
}

